import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import styled, { withTheme } from 'styled-components';
import { Flex, Box, Text } from 'rebass';
import { hasOwnProperty } from '@lib/help-fns';
import { Image, FilledClose } from '@oca/icons';

import { Avatar } from './avatar';
import { Label } from '../atoms';

const MultiValueRemove = props => {
  return (
    <components.MultiValueRemove {...props}>
      <FilledClose color="#DADADA" fontSize={18} />
    </components.MultiValueRemove>
  );
};

const MultiValueLabel = props => {
  return (
    <components.MultiValueLabel {...props}>
      <Wrapper>
        {hasOwnProperty(props.data, 'avatar') && (
          <Avatar size={25} mr={8} src={props.data.avatar}>
            <Image />
          </Avatar>
        )}
        {props.children}
      </Wrapper>
    </components.MultiValueLabel>
  );
};

MultiValueLabel.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    avatar: PropTypes.string,
  }).isRequired,
  children: PropTypes.string.isRequired,
};

const Wrapper = styled(Flex)`
  align-items: center;
`;

const Option = props => {
  return (
    <components.Option {...props}>
      <Wrapper>
        {hasOwnProperty(props.data, 'avatar') && (
          <Avatar size={25} mr={8} src={props.data.avatar}>
            <Image />
          </Avatar>
        )}
        {props.children}
      </Wrapper>
    </components.Option>
  );
};

Option.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    avatar: PropTypes.string,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

function SelectBoxComponent({
  disabled,
  error,
  helperText,
  isMulti,
  label,
  options,
  theme,
  spaceAfter,
  ...other
}) {
  return (
    <Box marginBottom={spaceAfter ? 2 : 0}>
      {label && (
        <Label color={(disabled && 'disabled') || (error && 'error') || 'text'}>
          {label}
        </Label>
      )}
      <Select
        {...other}
        isDisabled={disabled}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        components={{ MultiValueLabel, MultiValueRemove, Option }}
        styles={{
          input: base => ({
            ...base,
            color: theme.colors.text,
            fontSize: 14,
          }),
          control: (base, { isFocused }) => ({
            ...base,
            paddingLeft: 8,
            minHeight: 40,
            ...(error
              ? {
                  borderColor: theme.colors.error,
                  ':hover': {
                    borderColor: theme.colors.error,
                  },
                }
              : {}),
            ...(isFocused && error
              ? { boxShadow: `0 0 0 1px ${theme.colors.error}` }
              : {}),
          }),
          multiValue: base => ({
            ...base,
            backgroundColor: theme.colors.primary,
            borderRadius: 4,
            height: 32,
            alignItems: 'center',
          }),
          multiValueLabel: base => ({
            ...base,
            fontSize: 14,
            color: theme.colors.white,
          }),
          multiValueRemove: base => {
            return {
              ...base,
              ':hover': { backgroundColor: theme.colors.primary },
            };
          },
          singleValue: base => ({
            ...base,
            fontSize: 14,
            color: theme.colors.text,
          }),
          option: (base, { isSelected }) => ({
            ...base,
            color: isSelected ? 'white' : theme.colors.text,
            fontSize: theme.fontSizes[1],
          }),
        }}
        options={options}
      />
      {helperText && (
        <HelperText
          as="p"
          lineHeight={1}
          margin={0}
          marginTop={1}
          fontSize={0}
          color={error ? 'error' : 'textSecondary'}
        >
          {helperText}
        </HelperText>
      )}
    </Box>
  );
}

SelectBoxComponent.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isMulti: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  spaceAfter: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.oneOfType([PropTypes.object]).isRequired,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
};

SelectBoxComponent.defaultProps = {
  disabled: false,
  error: false,
  helperText: '',
  isMulti: false,
  label: '',
  options: [],
  spaceAfter: false,
  getOptionValue: option => option.id || option.value || '',
  getOptionLabel: option => option.label || option.name || '',
};

export const SelectBox = withTheme(SelectBoxComponent);

const HelperText = styled(Text)`
  min-height: 1em;
`;
