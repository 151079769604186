/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import styled from 'styled-components';

/**
 * @typedef {import('rebass').FlexProps} FlexProps
 */

/**
 * @param {FlexProps & {children: ReactNode}}
 */
export const BlockHead = ({ children, ...rest }) => (
  <Head {...rest}>{children}</Head>
);

BlockHead.propTypes = {
  ...Flex.propTypes,
  children: PropTypes.node.isRequired,
};

BlockHead.defaultProps = {
  bg: 'white',
  py: 3,
  px: 4,
  alignItems: 'center',
  justifyContent: 'space-between',
};

const Head = styled(Flex)`
  border-radius: 6px 6px 0px 0px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  min-height: 74px;
  position: relative;
  z-index: 1;
`;
