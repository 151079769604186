import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Trans } from '@lingui/macro';

import { Row, Col } from '@lib/grid';
import { DayPickerInputWrapper } from '@lib/final-form-oca-ui';
import { FileUploaderField } from '@features/common';
import { format } from 'date-fns';

export function FieldsetDocument({ name }) {
  return (
    <Row spacing="10px" flexDirection="column" key={name}>
      <Col width={[1 / 3, 1 / 4, 1 / 5]}>
        <Field
          name={`${name}.expDate`}
          label={<Trans>Expire date</Trans>}
          parse={val => (val ? format(val, 'yyyy-MM-dd') : '')}
          format={val => (val ? new Date(val) : null)}
          component={DayPickerInputWrapper}
        />
      </Col>
      <Col width={[1 / 2, 1 / 3, 1 / 4]} marginBottom={4}>
        <FileUploaderField
          label={<Trans>Picture/Scan</Trans>}
          name={`${name}.files`}
        />
      </Col>
    </Row>
  );
}

FieldsetDocument.propTypes = {
  name: PropTypes.string.isRequired,
};
