import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Gif = props => (
  <SvgIcon {...props} width="24" height="30" viewBox="0 0 24 30">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#A066AA"
    />
    <path
      d="M9.36749 17.5388C8.94149 17.892 8.41949 18.0428 7.89224 18.0428C6.63224 18.0428 5.73899 17.3228 5.73899 15.8955C5.73899 14.6843 6.68099 13.743 7.94549 13.743C8.41949 13.743 8.94074 13.905 9.31274 14.271C9.67874 14.6303 9.15074 15.1643 8.79149 14.847C8.56949 14.6303 8.25749 14.469 7.94549 14.469C7.22024 14.469 6.51899 15.0503 6.51899 15.8955C6.51899 16.7835 7.10624 17.3228 7.89224 17.3228C8.25749 17.3228 8.56949 17.2148 8.79149 17.0528V16.3163H7.89224C7.35824 16.3163 7.41224 15.5835 7.89224 15.5835H9.09149C9.31349 15.5835 9.51674 15.7523 9.51674 15.9383V17.2155C9.51674 17.3228 9.46949 17.424 9.36749 17.5388Z"
      fill="white"
    />
    <path
      d="M10.5443 14.2103C10.5443 13.7183 11.3363 13.7003 11.3363 14.2103V17.6333C11.3363 18.1313 10.5443 18.1433 10.5443 17.6333V14.2103Z"
      fill="white"
    />
    <path
      d="M13.1775 14.6295V15.5827H14.706C14.922 15.5827 15.138 15.7987 15.138 16.0087C15.138 16.2067 14.922 16.368 14.706 16.368H13.1775V17.6272C13.1775 17.8372 13.0275 17.9992 12.8175 17.9992C12.5535 17.9992 12.3922 17.8372 12.3922 17.6272V14.2215C12.3922 14.0055 12.5542 13.8495 12.8175 13.8495H14.9227C15.1867 13.8495 15.3427 14.0055 15.3427 14.2215C15.3427 14.4135 15.1867 14.6287 14.9227 14.6287H13.1775V14.6295Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
