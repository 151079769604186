/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';

import { Typography } from '../atoms';

export const RadialBar = ({ total, amount, content, bgColor }) => {
  return (
    <Chart bg={bgColor}>
      <svg width="120" height="120" viewBox="0 0 120 120">
        <circle
          cx="60"
          cy="60"
          r="54"
          fill="none"
          stroke="#e6e6e6"
          strokeWidth="8"
        />
        <circle
          cx="60"
          cy="60"
          r="54"
          fill="none"
          stroke="#424B68"
          strokeWidth="8"
          strokeDasharray="339.292"
          strokeDashoffset={
            amount && total ? 339.292 * (1 - amount / total) : 339.92
          }
        />
      </svg>
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        p={20}
      >
        <Typography
          mb="5px"
          fontSize={24}
          fontWeight={700}
          lineHeight={1}
          textAlign="center"
        >
          {amount && total ? Math.round((amount / total) * 100) : 0}%
        </Typography>
        <Typography fontSize={10} textAlign="center">
          {content ? `${content} ${amount || 0} (days)` : '0 (days)'}
        </Typography>
      </Flex>
    </Chart>
  );
};

RadialBar.defaultProps = {
  total: 0,
  amount: 0,
  content: '',
  bgColor: 'rgba(255, 255, 255, 0)',
};

RadialBar.propTypes = {
  total: PropTypes.number,
  amount: PropTypes.number,
  content: PropTypes.string,
  bgColor: PropTypes.string,
};

const Chart = styled(Box)`
  position: relative;
  border-radius: 50%;
  svg {
    transform: rotate(-90deg);
    position: relative;
    display: block;
  }
  > div {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
`;
