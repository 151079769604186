import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from 'rebass';

export function CenterContentTemplate({ children }) {
  return (
    <Flex alignItems="center" justifyContent="center" flex="1 0 auto">
      {children}
    </Flex>
  );
}

CenterContentTemplate.propTypes = {
  children: PropTypes.node,
};

CenterContentTemplate.defaultProps = {
  children: null,
};
