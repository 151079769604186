import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import styled from 'styled-components';

export const ContentBlock = ({ children, ...props }) => (
  <Block bg="white" {...props}>
    {children}
  </Block>
);

ContentBlock.propTypes = {
  children: PropTypes.node.isRequired,
};

const Block = styled(Box)`
  border-radius: 6px;
`;
