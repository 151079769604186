import { routes as commonRoutes, NotFoundPage } from './features/common';
import { routes as dashboardRoutes } from './features/dashboard';
import {
  routes as projectRoutes,
  routes as projectsRoutes,
} from './features/projects';
import { routes as documentsRoutes } from './features/documents';
import { routes as meetingRoutes } from './features/meetings';
import { routes as taskRoutes } from './features/tasks';
import { routes as hrRoutes } from './features/hr';

export const routes = [
  ...dashboardRoutes,
  ...commonRoutes,
  ...projectRoutes,
  ...taskRoutes,
  ...meetingRoutes,
  ...hrRoutes,
  ...documentsRoutes,
  { component: NotFoundPage },
];
