import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Documents = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2 5.09998V16.1V18.2C18.2 19.3 17.3 20.2 16.2 20.2H5.2C4.1 20.2 3.2 19.3 3.2 18.2V3.09998C3.2 1.99998 4.1 1.09998 5.2 1.09998H16.2C17.3 1.09998 18.2 1.99998 18.2 3.09998V5.09998ZM5.4 16.5H15.9V15.3H5.4V16.5ZM5.4 13.7H15.9V12.5H5.4V13.7ZM5.4 11.1H15.9V9.99998H5.4V11.1ZM5.4 8.59998H15.9V7.39998H5.4V8.59998ZM5.4 5.99998H15.9V4.79998H5.4V5.99998ZM19.4 3.90002V5.30002H19.5V16.2V18.2C19.5 20 18.1 21.4 16.3 21.4H5.9C6.2 22.3 7 22.9 7.9 22.9H18.9C20 22.9 20.9 22 20.9 20.9V18.9V7.90002V5.90002C20.9 4.90002 20.2 4.20002 19.4 3.90002Z"
    />
  </SvgIcon>
);
