import { createStore, createStoreObject } from 'effector';

export const fetchStatuses = {
  loading: 'loading',
  fail: 'fail',
  initial: 'initial',
  done: 'done',
};

/**
 * @typedef {import('effector').Effect} Effect
 * @typedef {import('effector').Store} Store
 */

/**
 * Creates fetching effect handling store
 * @param {Effect} effect Fetching effect
 * @param {{ status: 'initial' | 'fail' | 'done', error: Any }} option Fetching initial options
 * @returns {Store<{status: string, error: Any, isFail: Boolean, isLoading: Boolean, isDone: Boolean}>} Store with fetching meta information
 */
export function createFetching(
  effect,
  {
    status: initialStatus = fetchStatuses.initial,
    error: initialError = null,
  } = {},
) {
  const status = createStore(initialStatus)
    .on(effect, () => fetchStatuses.loading)
    .on(effect.done, () => fetchStatuses.done)
    .on(effect.fail, () => fetchStatuses.fail);

  const error = createStore(initialError)
    .reset(effect)
    .reset(effect.done)
    .on(effect.fail, (_, { error: errorValue }) => getError(errorValue));

  return createStoreObject({
    status,
    error,
    isFail: status.map(state => state === fetchStatuses.fail),
    isLoading: status.map(state => state === fetchStatuses.loading),
    isDone: status.map(state => state === fetchStatuses.done),
  });
}

function getError(error) {
  if (error) {
    const { data, status, statusText } = error.response || {};

    return data ? { data, status, statusText } : error;
  }

  return 'Network error';
}
