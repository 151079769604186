import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useStore } from 'effector-react';
import { Box } from 'rebass';
import styled from 'styled-components';

import { BlockHead, Wizard, Progress, Typography } from '@oca/ui';

import { i18n } from '@lib/i18n';
import { t, Trans } from '@lingui/macro';

import { $employeeList } from '@features/common';
import { resolveRejectedPromise } from '@lib/help-fns';
import {
  fetchEmployeeProfileRequesting,
  $profile,
  pageUnmounted,
  startEmployeeUpdateProcess,
} from '../model/profile';

import {
  FormStepper,
  EmployeeEditForm,
  FormBasic,
  FormContacts,
  FormDocuments,
  FormAllowances,
} from '../organisms';

import {
  validateBasic,
  validateContacts,
  validateDocuments,
  validateProperties,
} from '../model/validation';
import { CommonEmployeePageTemplate } from '../templates';

const $employeeForUsername = $employeeList.map(list => username =>
  list.find(employee => employee.username === username) || {},
);

export function EmployeeEditPage({ match }) {
  /**
   * @type {import('../model/profile').Employee}
   */
  const profile = useStore($profile);
  const { id } = useStore($employeeForUsername)(match.params.username);

  useEffect(() => {
    if (id) fetchEmployeeProfileRequesting(id);

    return () => pageUnmounted();
  }, [id]);
  const fullName = profile ? profile.fullName : '';

  return (
    <CommonEmployeePageTemplate title={i18n._(t`HR: ${fullName}`)}>
      <Wizard>
        {({ activeStepIndex, moveToStep }) => {
          if (!profile) {
            return (
              <Box style={{ height: '100vh' }}>
                <Progress />
              </Box>
            );
          }

          return (
            <React.Fragment>
              <BlockHead justifyContent="flex-start" marginBottom={4}>
                <Box width={[1 / 4, 1 / 6, 1 / 8]}>
                  <Typography variant="h1">
                    <Trans>Edit employee</Trans>
                  </Typography>
                  <Typography variant="caption" noWrap>
                    {fullName}
                  </Typography>
                </Box>
                <FormStepper
                  profile={profile}
                  currentValue={activeStepIndex}
                  onChange={moveToStep}
                  compact
                />
              </BlockHead>
              <Box px={4} width={1}>
                <EmployeeEditForm
                  editable
                  initialValues={profile}
                  steps={formSteps}
                  validate={validateAll}
                  onSubmit={(values, form) =>
                    handleSubmit(values, profile, form)
                  }
                />
              </Box>
            </React.Fragment>
          );
        }}
      </Wizard>
    </CommonEmployeePageTemplate>
  );
}

EmployeeEditPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

const formSteps = [
  {
    component: FormBasic,
    validate: validateBasic,
  },
  {
    component: FormContacts,
    validate: validateContacts,
  },
  {
    component: FormDocuments,
    validate: validateDocuments,
  },
  {
    component: FormAllowances,
    validate: validateProperties,
  },
];

async function handleSubmit(values, prevValues) {
  const error = await resolveRejectedPromise(
    startEmployeeUpdateProcess({ prevValues, values }),
  );
  console.log(error);
}

function validateAll(values) {
  return [
    validateBasic,
    validateContacts,
    validateDocuments,
    validateProperties,
  ].reduce(
    (acc, validate) => ({
      ...acc,
      ...validate(values),
    }),
    {},
  );
}
