import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Tiff = props => (
  <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#0076F4"
    />
    <path
      d="M8.49995 14.2103C8.49995 13.7183 9.29195 13.7003 9.29195 14.2103V17.6333C9.29195 18.1313 8.49995 18.1433 8.49995 17.6333V14.2103Z"
      fill="white"
    />
    <path
      d="M11.2852 14.6295V15.5827H12.8137C13.0297 15.5827 13.2457 15.7987 13.2457 16.0087C13.2457 16.2067 13.0297 16.368 12.8137 16.368H11.2852V17.6272C11.2852 17.8372 11.1352 17.9992 10.9252 17.9992C10.6612 17.9992 10.5 17.8372 10.5 17.6272V14.2215C10.5 14.0055 10.662 13.8495 10.9252 13.8495H13.0305C13.2945 13.8495 13.4505 14.0055 13.4505 14.2215C13.4505 14.4135 13.2945 14.6287 13.0305 14.6287H11.2852V14.6295Z"
      fill="white"
    />
    <path
      d="M14.7852 14.6295V15.5827H16.3137C16.5297 15.5827 16.7457 15.7987 16.7457 16.0087C16.7457 16.2067 16.5297 16.368 16.3137 16.368H14.7852V17.6272C14.7852 17.8372 14.6352 17.9992 14.4252 17.9992C14.1612 17.9992 14 17.8372 14 17.6272V14.2215C14 14.0055 14.162 13.8495 14.4252 13.8495H16.5305C16.7945 13.8495 16.9505 14.0055 16.9505 14.2215C16.9505 14.4135 16.7945 14.6287 16.5305 14.6287H14.7852V14.6295Z"
      fill="white"
    />
    <path
      d="M5.43995 14.6002H4.39145C3.86945 14.6002 3.86945 13.8329 4.39145 13.8329H7.22721C7.76121 13.8329 7.76121 14.6002 7.22721 14.6002H6.23196V17.6279C6.23196 18.1492 5.43995 18.1619 5.43995 17.6279V14.6002Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
