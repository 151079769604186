import React from 'react';
import { PencilRuler } from '@oca/icons';

const ProjectCreatePage = React.lazy(() =>
  import('./pages/create').then(module => ({
    default: module.ProjectCreatePage,
  })),
);

const ProjectListPage = React.lazy(() =>
  import('./pages/list').then(module => ({
    default: module.ProjectListPage,
  })),
);

const ProjectSinglePage = React.lazy(() =>
  import('./pages/single').then(module => ({
    default: module.ProjectSinglePage,
  })),
);

export const routes = [
  {
    path: '/projects/create',
    component: ProjectCreatePage,
    name: 'Create project',
    exact: true,
  },
  {
    path: '/projects',
    component: ProjectListPage,
    navigation: {
      icon: <PencilRuler />,
      name: 'Projects',
      onlyTopLevelLink: true,
    },
    exact: true,
  },
  {
    path: '/projects/:id',
    component: ProjectSinglePage,
    name: 'Project Single',
    exact: true,
  },
];
