import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { boxShadow, position } from 'styled-system';
import { Box } from 'rebass';
import { withRouter } from 'react-router-dom';

import { IconButton } from '@oca/ui';
import { Hamburger } from '@oca/icons';

import { NavList } from './nav-list';

export function NavigationView({ location, routes }) {
  // Consider to track window resize and close menu on small screen automatically
  const [open, setOpen] = useState(window.innerWidth >= 1280);
  return (
    <Wrapper open={open} width={[80, open ? 240 : 80]}>
      <NavigationWrapper
        position={['fixed', 'static']}
        width={[open ? 240 : 80]}
        py={3}
        boxShadow={4}
      >
        <Box px={3} marginBottom={4}>
          <MenuButton onClick={() => setOpen(!open)} />
        </Box>
        <NavList
          collapsed={!open}
          depth={0}
          location={location}
          pages={routes}
        />
      </NavigationWrapper>
    </Wrapper>
  );
}

export const Navigation = withRouter(NavigationView);

NavigationView.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      name: PropTypes.string,
      routes: PropTypes.array,
    }),
  ).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const Wrapper = styled(Box)`
  flex: 1 0 auto;
  min-width: 80px;
  height: 100%;
  z-index: 3;
`;

const NavigationWrapper = styled(Box)`
  height: inherit;
  background: linear-gradient(
    0deg,
    rgba(0, 117, 244, 1) 0%,
    rgba(0, 179, 255, 1) 100%
  );
  ${boxShadow}
  ${position}
`;

// eslint-disable-next-line react/prop-types
const MenuButton = ({ onClick }) => (
  <IconButton color="white" onClick={onClick}>
    <Hamburger />
  </IconButton>
);
