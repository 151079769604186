import React from 'react';

import { Documents } from '@oca/icons';
import { DocumentsPages } from './documentsPages';

export const routes = [
  {
    path: '/documents',
    component: DocumentsPages,
    navigation: { name: 'Documents', icon: <Documents /> },
    routes: [
      {
        path: '/documents/files',
        navigation: { name: 'My Files' },
        exact: true,
      },
      {
        path: '/documents/starred',
        navigation: { name: 'Starred' },
        exact: true,
      },
      {
        path: '/documents/shared-with-me',
        navigation: { name: 'Shared with me' },
        exact: true,
      },
      {
        path: '/documents/shared-by-me',
        navigation: { name: 'Shared by me' },
        exact: true,
      },
      {
        path: '/documents/company-files',
        navigation: { name: 'Company Files' },
        exact: true,
      },
    ],
  },
];
