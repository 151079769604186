import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import { useWizard } from '@lib/use-wizard';

export const WizardContext = React.createContext(null);

export function Wizard({ children }) {
  const state = useWizard();

  return (
    <WizardContext.Provider value={{ ...state }}>
      {typeof children === 'function' ? children(state) : children}
    </WizardContext.Provider>
  );
}

Wizard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

Wizard.defaultProps = {
  children: null,
};

export function WizardStep({ children }) {
  const wizardContext = useContext(WizardContext);

  if (wizardContext === null) {
    throw new Error(
      'Wizard Step must be used as a child within a Wizard Component',
    );
  }

  const contextRef = useRef(null);
  const stepRef = useRef(null);

  if (contextRef.current !== wizardContext) {
    contextRef.current = wizardContext;
    stepRef.current = wizardContext.getStep();
  }

  return children(stepRef.current);
}
