import React from 'react';
import { Trans } from '@lingui/macro';
import { useStore } from 'effector-react';
import { combine } from 'effector';
import { Link } from 'react-router-dom';

import { BlockHead, Typography, SelectBox, Button, TextField } from '@oca/ui';
import {
  $departmentList,
  departmentListFetchRequesting,
  AccessControl,
} from '@features/common';

import { Row, Col } from '@lib/grid';
import { AddPerson } from '@oca/icons';
import {
  changeDepartmentFilter,
  $departmentFilter,
  $search,
  changeEmployeeSearch,
} from '../model/list';

const $filters = combine($departmentFilter, $search, (department, search) => ({
  department,
  search,
}));

const $departmentOptions = $departmentList.map(list => [
  { label: <Trans>All</Trans>, id: '' },
  ...list,
]);

export function ListHeader() {
  const departments = useStore($departmentOptions);
  const filters = useStore($filters);

  React.useEffect(() => {
    departmentListFetchRequesting();
  }, []);

  return (
    <BlockHead justifyContent="space-between">
      <Row flex="1 1 auto">
        <Typography variant="h2">
          <Trans>Employees</Trans>
        </Typography>
      </Row>
      <Row flex="1 0 auto" alignItems="center" spacing={2}>
        <Col width={1 / 3}>
          <SelectBox
            options={departments}
            onChange={val => changeDepartmentFilter(val.id)}
            value={
              departments.find(({ id }) => filters.department === id) || null
            }
          />
        </Col>
        <Col width={1 / 3}>
          <TextField
            placeholder="Type name"
            onChange={event => changeEmployeeSearch(event.target.value)}
            value={filters.search}
          />
        </Col>
        <Col width={1 / 3} alignSelf="flex-end">
          <AccessControl allowed={['Admin & HR:manager']}>
            <Button variant="primary" as={Link} to="/hr/employees/create">
              <AddPerson fontSize={16} />
              <Trans>Add employee</Trans>
            </Button>
          </AccessControl>
        </Col>
      </Row>
    </BlockHead>
  );
}
