import React from 'react';
import PropTypes from 'prop-types';

import { Account } from './account';

const checkPermissions = (userPermissions, allowedPermissions) => {
  if (allowedPermissions.length === 0) {
    return true;
  }

  return userPermissions.some(permission =>
    allowedPermissions.includes(permission),
  );
};

/**
 * Controls children rendering based on user permission
 */
export function AccessControl({
  allowed,
  accessCheck,
  children,
  extraAccessData,
  userPermissionsGetter,
  renderNoAccess,
}) {
  return (
    <Account
      render={({ user = {} }) => {
        const userPermissions = userPermissionsGetter(user) || [];
        const hasBaseAccess = checkPermissions(userPermissions, allowed);

        let permitted;

        if (accessCheck) {
          permitted = accessCheck(extraAccessData, user) && hasBaseAccess;
        } else {
          permitted = hasBaseAccess;
        }

        if (permitted) {
          return children;
        }

        return renderNoAccess({ user });
      }}
    />
  );
}

AccessControl.propTypes = {
  allowed: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/require-default-props
  accessCheck: PropTypes.func,
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  extraAccessData: PropTypes.any,
  renderNoAccess: PropTypes.func,
  userPermissionsGetter: PropTypes.func,
};

AccessControl.defaultProps = {
  children: null,
  renderNoAccess: () => null,
  userPermissionsGetter: user => user.permissions || [],
};
