import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Download as DownloadIcon,
  Delete as DeleteIcon,
  LightClose as LightCloseIcon,
} from '@oca/icons';

import { IconButton } from '../atoms/icon-button';
import { ListItem } from './list-item';
import { Progress } from '../atoms/progress';

export const UploadItem = React.forwardRef(
  /**
   * @param {Object} props
   * @param {number} [props.progress] Uploading progress value
   * @param {string | ReactNode} [props.statusText] Status text
   */
  function UploadItem(props, ref) {
    const {
      id,
      error,
      progress,
      statusText,
      title,
      onCancel,
      onDelete,
      onDownload,
    } = props;

    const actions = [
      onDownload && (
        <DownloadButton key="dwnld" onClick={event => onDownload(id, event)} />
      ),
      onDelete && (
        <DeleteButton key="dlt" onClick={event => onDelete(id, event)} />
      ),
      onCancel && (
        <CancelButton key="cncl" onClick={event => onCancel(id, event)} />
      ),
    ].filter(Boolean);

    return (
      <Wrapper>
        <ListItem divider textContainerOverflow actions={actions}>
          <ListItem.Text noWrap>{title}</ListItem.Text>
          <ListItem.Text
            variant="secondary"
            color={error ? 'error' : undefined}
          >
            {statusText}
          </ListItem.Text>
        </ListItem>
        {progress !== null && (
          <ProgressWrapper>
            <Progress variant="determinate" value={progress} />
          </ProgressWrapper>
        )}
      </Wrapper>
    );
  },
);

UploadItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  error: PropTypes.bool,
  progress: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  statusText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // eslint-disable-next-line react/require-default-props
  onCancel: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onDelete: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onDownload: PropTypes.func,
};

UploadItem.defaultProps = {
  error: false,
  progress: null,
  statusText: '',
  title: null,
};

const Wrapper = styled.div`
  position: relative;
`;

const ProgressWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 2px;
`;

const UploadItemAction = props => <IconButton {...props} size="small" />;
UploadItemAction.defaultProps = { color: 'text' };

const DeleteButton = props => (
  <UploadItemAction {...props}>
    <DeleteIcon />
  </UploadItemAction>
);
const DownloadButton = props => (
  <UploadItemAction {...props}>
    <DownloadIcon />
  </UploadItemAction>
);
const CancelButton = props => (
  <UploadItemAction {...props}>
    <LightCloseIcon />
  </UploadItemAction>
);
