import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Trans } from '@lingui/macro';

import { Row, Col } from '@lib/grid';
import { SelectWrapper, TextFieldWrapper } from '@lib/final-form-oca-ui';
import { FileUploaderField } from '@features/common';
import { Typography, IconButton } from '@oca/ui';
import { Delete } from '@oca/icons';

export function FieldsetEducation({ options, name, index, onDelete }) {
  return (
    <React.Fragment>
      <Typography variant="body1">#{index + 1}</Typography>
      <Row spacing="10px" alignItems="center">
        <Col width={[1 / 3, 1 / 4, 1 / 5]}>
          <Field
            name={`${name}.institution`}
            component={TextFieldWrapper}
            label={<Trans>College/Institution</Trans>}
            helperText=" "
          />
        </Col>
        <Col width={[1 / 3, 1 / 4, 1 / 5]}>
          <Field
            name={`${name}.degree`}
            component={SelectWrapper}
            label={<Trans>Degree</Trans>}
            parse={val => val && val.value}
            format={value => options.find(item => item.value === value)}
            options={options}
            helperText=" "
          />
        </Col>
        {onDelete && (
          <Col width={[1 / 4]}>
            <IconButton color="error" onClick={() => onDelete(index)}>
              <Delete />
            </IconButton>
          </Col>
        )}
      </Row>
      <Row spacing="10px">
        <Col width={[1 / 2, 1 / 3, 1 / 3]}>
          <FileUploaderField
            label={<Trans>Picture/Scan</Trans>}
            name={`${name}.files`}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
}

FieldsetEducation.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  index: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  onDelete: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
};

FieldsetEducation.defaultProps = {
  options: [],
};
