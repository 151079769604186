import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Mp4 = props => (
  <SvgIcon {...props} width="24" height="30" viewBox="0 0 24 30">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.3258 5.175 24 6 24H21C21.825 24 22.5 23.3258 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#F36452"
    />
    <path
      d="M5.49301 15.3675V17.5868C5.49301 17.8508 5.27777 18.0008 5.06102 18.0008C4.86902 18.0008 4.70177 17.8508 4.70177 17.5868V14.2103C4.70177 13.899 4.96577 13.7963 5.06102 13.7963C5.23502 13.7963 5.33701 13.899 5.43901 14.0123L6.75901 15.7935L8.12551 13.9463C8.32426 13.7003 8.80951 13.7963 8.80951 14.2103V17.5868C8.80951 17.8508 8.64076 18.0008 8.44951 18.0008C8.23426 18.0008 8.06551 17.8508 8.06551 17.5868V15.3675L7.07026 16.6275C6.85501 16.8915 6.58501 16.8915 6.38701 16.6275L5.49301 15.3675Z"
      fill="white"
    />
    <path
      d="M9.85723 14.2103C9.85723 14.0123 10.0132 13.7963 10.2652 13.7963H11.6505C12.4305 13.7963 13.1325 14.3183 13.1325 15.3195C13.1325 16.2675 12.4305 16.7948 11.6505 16.7948H10.6492V17.5868C10.6492 17.8508 10.4812 18.0008 10.2652 18.0008C10.0672 18.0008 9.85723 17.8508 9.85723 17.5868V14.2103ZM10.6485 14.5523V16.0455H11.6497C12.0517 16.0455 12.3697 15.6915 12.3697 15.3195C12.3697 14.8995 12.0517 14.5523 11.6497 14.5523H10.6485Z"
      fill="white"
    />
    <path
      d="M16.3017 13.75C16.4115 13.75 16.5008 13.8385 16.5017 13.9482L16.5231 16.3564C16.5241 16.4662 16.6133 16.5547 16.7231 16.5547H16.8C16.9104 16.5547 17 16.6442 17 16.7547V16.941C17 17.0515 16.9104 17.141 16.8 17.141H16.7248C16.6144 17.141 16.5249 17.2306 16.5249 17.341V17.8C16.5249 17.9105 16.4353 18 16.3249 18H15.9371C15.8266 18 15.7371 17.9105 15.7371 17.8V17.341C15.7371 17.2306 15.6476 17.141 15.5371 17.141H13.7C13.5895 17.141 13.5 17.0515 13.5 16.941V16.3511C13.5 16.3106 13.5123 16.271 13.5353 16.2376L15.1904 13.8365C15.2277 13.7823 15.2893 13.75 15.355 13.75H16.3017ZM15.7371 16.5547V14.5905C15.7371 14.5816 15.7299 14.5744 15.7209 14.5744C15.716 14.5744 15.7113 14.5766 15.7083 14.5805L14.4006 16.2313C14.2968 16.3624 14.3902 16.5555 14.5574 16.5555H15.7362C15.7367 16.5555 15.7371 16.5551 15.7371 16.5547Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
