import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Ppt = props => (
  <SvgIcon {...props} width="24" height="30" viewBox="0 0 24 30">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#F15642"
    />
    <path
      d="M4.94324 14.2103C4.94324 14.0123 5.09924 13.7963 5.3505 13.7963H6.73575C7.51575 13.7963 8.21775 14.3183 8.21775 15.3188C8.21775 16.2668 7.51575 16.7948 6.73575 16.7948H5.7345V17.5868C5.7345 17.8508 5.56725 18 5.35125 18C5.15325 18 4.94399 17.8508 4.94399 17.5868V14.2103H4.94324ZM5.7345 14.5515V16.0455H6.73575C7.13775 16.0455 7.45575 15.6908 7.45575 15.3188C7.45575 14.8995 7.13775 14.5515 6.73575 14.5515H5.7345Z"
      fill="white"
    />
    <path
      d="M8.982 14.2103C8.982 14.0123 9.138 13.7963 9.39 13.7963H10.7753C11.5553 13.7963 12.2573 14.3183 12.2573 15.3188C12.2573 16.2668 11.5553 16.7948 10.7753 16.7948H9.774V17.5868C9.774 17.8508 9.606 18 9.39 18C9.192 18 8.982 17.8508 8.982 17.5868V14.2103ZM9.77325 14.5515V16.0455H10.7745C11.1765 16.0455 11.4945 15.6908 11.4945 15.3188C11.4945 14.8995 11.1765 14.5515 10.7745 14.5515H9.77325Z"
      fill="white"
    />
    <path
      d="M14.1413 14.6003H13.0928C12.5708 14.6003 12.5708 13.833 13.0928 13.833H15.9285C16.4625 13.833 16.4625 14.6003 15.9285 14.6003H14.9333V17.6288C14.9333 18.15 14.1413 18.1628 14.1413 17.6288V14.6003Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
