import React from 'react';
import PropTypes from 'prop-types';

import { DayPickerInput } from '@oca/ui';

export function DayPickerInputWrapper({
  label,
  input,
  meta,
  helperText,
  required,
  ...rest
}) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;
  const { onChange, onBlur, onFocus, ...otherProps } = input;

  return (
    <DayPickerInput
      {...rest}
      inputProps={{
        autoComplete: 'off',
        required,
        onBlur,
        onFocus,
        helperText: showError ? meta.error || meta.submitError : helperText,
        label,
        error: showError,
        ...otherProps,
      }}
      onDayChange={onChange}
    />
  );
}

DayPickerInputWrapper.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
  }).isRequired,
  meta: PropTypes.shape({
    submitError: PropTypes.any,
    dirtySinceLastSubmit: PropTypes.bool,
  }).isRequired,
  // eslint-disable-next-line react/require-default-props
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // eslint-disable-next-line react/require-default-props
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // eslint-disable-next-line react/require-default-props
  required: PropTypes.bool,
};
