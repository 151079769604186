import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Meeting = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.79999H8C6.6 1.79999 5.5 2.89999 5.5 4.29999V7.29999C5.5 8.49999 6.4 9.49999 7.5 9.79999V11.4C7.5 11.9 8 12.1 8.4 11.8L10.3 9.89999H12C13.4 9.89999 14.5 8.79999 14.5 7.39999V4.39999C14.5 2.89999 13.4 1.79999 12 1.79999ZM11.9 4.69999L9.9 6.69999C9.7 6.89999 9.4 6.89999 9.2 6.69999L8.2 5.69999C7.9 5.49999 7.9 5.19999 8.1 4.99999C8.3 4.79999 8.6 4.79999 8.8 4.99999L9.5 5.69999L11.1 3.99999C11.3 3.79999 11.6 3.79999 11.8 3.99999C12 4.19999 12 4.49999 11.9 4.69999ZM2.60001 14.2C2.60001 13.1 3.50001 12.2 4.60001 12.2C5.70001 12.2 6.60001 13.1 6.60001 14.2C6.60001 15.3 5.70001 16.2 4.60001 16.2C3.50001 16.2 2.60001 15.3 2.60001 14.2ZM4.60001 16.2H5.90001C7.40001 16.2 8.60001 17.4 8.70001 18.9V21.6C8.70001 22 8.40001 22.2999 8.00001 22.2999H1.30001C0.900006 22.2999 0.600006 22 0.600006 21.6V18.9C0.600006 17.4 1.80001 16.2 3.30001 16.2H4.60001ZM19.4 12.2C18.3 12.2 17.4 13.1 17.4 14.2C17.4 15.3 18.3 16.2 19.4 16.2H18.1C16.6 16.2 15.4 17.4 15.4 18.9V21.6C15.4 22 15.7 22.2999 16.1 22.2999H22.8C23.2 22.2999 23.5 22 23.5 21.6V18.9C23.4 17.4 22.2 16.2 20.7 16.2H19.4C20.5 16.2 21.4 15.3 21.4 14.2C21.4 13.1 20.5 12.2 19.4 12.2ZM15.1 2.79999H16C17.4 2.79999 18.6 3.99999 18.6 5.39999V7.39999C18.6 8.59999 17.8 9.69999 16.6 9.89999V11.3C16.6 11.8 16 12 15.7 11.7L14.1 10.1C14.9 9.49999 15.5 8.49999 15.5 7.29999V4.29999C15.5 3.79999 15.3 3.29999 15.1 2.79999Z"
    />
  </SvgIcon>
);
