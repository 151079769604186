import React from 'react';
import { Flex, Box, Text } from 'rebass';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { format, addHours, addMinutes } from 'date-fns';
import { Link } from 'react-router-dom';

import { Typography } from '../atoms';
import { StatusIcon } from './status-icon';

export const MeetingPreview = ({ data, ...props }) => {
  return (
    <MeetingWrap {...props}>
      <Typography variant="h3" fontWeight={400}>
        <BtnLink to={`/meetings/view/${data.id}`}>{data.title}</BtnLink>
      </Typography>
      <Flex my={10} alignItems="center">
        <Flex alignItems="center" mr={20}>
          <Flex mr="5px">{StatusIcon(data.status, 16)}</Flex>
          <Typography as="span" fontSize={14}>
            {data.status}
          </Typography>
        </Flex>
        <Typography as="span" fontSize={14}>
          {timeRange(data.startTime, data.durationTime)}
        </Typography>
      </Flex>
      <Text fontSize={14} lineHeight={1.5} color="#88909D">
        {data.description}
      </Text>
    </MeetingWrap>
  );
};
MeetingPreview.propTypes = {
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string,
    startTime: PropTypes.string,
  }),
};
MeetingPreview.defaultProps = {
  data: {},
};

const timeRange = (startTime, duration) => {
  const startAt = format(new Date(startTime), 'HH:mm');
  if (duration && typeof duration !== 'string') {
    const durationArr = duration.split(':');
    const addedHours = addHours(
      new Date(startTime),
      Number(durationArr[0] || 0),
    );
    const addedMinutes = addMinutes(addedHours, Number(durationArr[1] || 0));
    const finishAt = format(addedMinutes, 'HH:mm');
    return `${startAt} - ${finishAt}`;
  }
  return startAt;
};

const MeetingWrap = styled(Box)({
  marginTop: 40,
  marginBottom: 40,
  '&:first-child': {
    marginTop: 0,
  },
  '&:last-child': {
    marginBottom: 0,
  },
});

const BtnLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
