import React from 'react';
import { FileCheck } from '@oca/icons';

const TasksList = React.lazy(() =>
  import('./pages').then(module => ({
    default: module.TasksList,
  })),
);

export const routes = [
  {
    path: '/tasks',
    component: TasksList,
    exact: true,
    navigation: {
      name: 'Tasks',
      icon: <FileCheck />,
      onlyTopLevelLink: true,
    },
  },
  {
    path: '/tasks/:id',
    component: TasksList,
    exact: true,
    name: 'Tasks',
  },
];
