import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Clock = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.59998C8.48001 5.59998 5.60001 8.47998 5.60001 12C5.60001 15.52 8.40001 18.4 12 18.4C15.52 18.4 18.4 15.52 18.4 12C18.4 8.39998 15.44 5.59998 12 5.59998ZM12 17.68C8.88001 17.68 6.40001 15.12 6.40001 12C6.40001 8.87998 8.96001 6.39998 12 6.39998C15.12 6.39998 17.68 8.95998 17.68 12C17.68 15.12 15.12 17.68 12 17.68ZM12.56 12L13.76 10.8C13.92 10.64 13.92 10.4 13.76 10.24C13.6 10.08 13.36 10.08 13.2 10.24L11.68 11.76C11.52 11.92 11.52 12.16 11.68 12.32L13.2 13.84C13.36 14 13.6 14 13.76 13.84C13.92 13.68 13.92 13.44 13.76 13.28L12.56 12ZM7.12 12C7.12 9.28 9.28 7.12 12 7.12C14.64 7.12 16.88 9.28 16.96 12C16.96 14.72 14.72 16.96 12 16.96C9.28 16.96 7.12 14.72 7.12 12ZM14.96 14.4L15.2 14.64C15.68 14 16.08 13.2 16.08 12.4H15.76C15.52 12.4 15.36 12.24 15.36 12C15.36 11.76 15.52 11.6 15.76 11.6H16.08C16 10.8 15.68 10 15.12 9.36L14.88 9.6C14.8 9.68 14.72 9.68 14.64 9.68C14.32 9.68 14.16 9.28 14.4 9.04L14.72 8.8C14.08 8.24 13.28 7.92 12.4 7.84V8.16C12.4 8.4 12.24 8.56 12 8.56C11.76 8.56 11.6 8.4 11.6 8.16V7.84C10.8 7.92 10 8.24 9.36 8.8L9.6 8.96C9.76 9.12 9.76 9.36 9.6 9.52C9.44 9.68 9.2 9.68 9.04 9.52L8.8 9.28C8.24 9.92 7.92 10.64 7.84 11.52H8.16C8.4 11.52 8.56 11.68 8.56 11.92C8.56 12.16 8.4 12.32 8.16 12.32H7.84C7.92 13.2 8.24 14 8.8 14.64L8.96 14.4C9.12 14.24 9.36 14.24 9.52 14.4C9.68 14.56 9.68 14.8 9.52 14.96L9.28 15.2C9.92 15.76 10.64 16.08 11.52 16.16V15.84C11.52 15.6 11.68 15.44 11.92 15.44C12.16 15.44 12.32 15.6 12.32 15.84V16.16C13.2 16.08 14 15.76 14.64 15.2L14.4 14.96C14.24 14.8 14.24 14.56 14.4 14.4C14.56 14.24 14.8 14.24 14.96 14.4Z"
    />
  </SvgIcon>
);
