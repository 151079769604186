import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Wav = props => (
  <SvgIcon {...props} width="24" height="30" viewBox="0 0 24 30">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.4286 19.5C19.4286 19.9125 19.0911 20.25 18.6786 20.25H2.17859C1.76609 20.25 1.42859 19.9125 1.42859 19.5V12C1.42859 11.5875 1.76609 11.25 2.17859 11.25H18.6786C19.0911 11.25 19.4286 11.5875 19.4286 12V19.5Z"
      fill="#84BD5A"
    />
    <path
      d="M8.16665 16.5675L9.0599 14.0963C9.23915 13.6118 9.96515 13.8383 9.78515 14.3603L8.5979 17.5815C8.4899 17.9228 8.38865 18.0255 8.1839 18.0255C7.96865 18.0255 7.9019 17.8928 7.78265 17.6768L6.7394 15.3075L5.74415 17.6408C5.6354 17.892 5.5349 18.036 5.31815 18.036C5.1089 18.036 5.00015 17.892 4.9049 17.628L3.74165 14.3595C3.56165 13.8203 4.3229 13.623 4.47815 14.0955L5.36615 16.5668L6.42815 14.0955C6.5714 13.7303 6.9494 13.7115 7.10465 14.0783L8.16665 16.5675Z"
      fill="white"
    />
    <path
      d="M9.66862 18.0009C9.47662 17.8921 9.35662 17.6769 9.47662 17.4249L11.1979 14.0611C11.3599 13.7431 11.7911 13.7311 11.9411 14.0611L13.6324 17.4249C13.8784 17.8921 13.1524 18.2641 12.9364 17.7961L12.6724 17.2689H10.4599L10.2026 17.7969C10.1066 18.0009 9.88462 18.0429 9.66862 18.0009ZM12.2471 16.4776L11.5691 14.9964L10.8319 16.4776H12.2471Z"
      fill="white"
    />
    <path
      d="M15.0585 17.7308L13.4753 14.4143C13.2413 13.941 13.9493 13.5518 14.2125 14.049L14.7465 15.2535L15.4358 16.8367L16.113 15.2535L16.647 14.049C16.887 13.593 17.5823 13.881 17.379 14.379L15.8498 17.7308C15.7118 18.1087 15.2865 18.198 15.0585 17.7308Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
