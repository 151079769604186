import React from 'react';
import { Link } from 'react-router-dom';
import { Heading } from 'rebass';

export function NotFoundPage() {
  return (
    <Heading>
      Page not found, <Link to="/">Take me back</Link>
    </Heading>
  );
}
