/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import TooltipTrigger from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ArrowKeyboardRight, Folder, UnwrapFolder } from '@oca/icons';
import { history } from '@lib/routing';
import { uniqArray } from '@lib/help-fns';
import { IconButton } from '../atoms';

// eslint-disable-next-line prefer-const
let dirs = [];

const getSteps = (mainPath, location) => {
  if (location.state) {
    dirs.push(location.state.directory);
  }

  dirs = uniqArray(dirs);
  const steps = [{ name: mainPath.name, link: mainPath.link }];

  const urlParams = location.pathname.split('/').slice(3);

  let link = `${mainPath.link}`;
  let url = `${mainPath.link}`;

  const dymanicPath = urlParams.map((pathName, index) => {
    link += `/${pathName.replace(' ', '%20')}`;
    url += `/${pathName}`;
    return {
      link,
      url,
      fullPath: location.pathname,
      state: dirs[index] ? { directory: dirs[index] } : { directory: '' },
      name: pathName.replace(/%20+/g, ' '),
    };
  });

  steps.push(...dymanicPath);

  return steps;
};
export const MenuTooltip = ({
  children,
  tooltip,
  download,
  edit,
  remove,
  share,
  steps,
  selected,
  location,
  onClick,
  ...props
}) => (
  <TooltipTrigger
    {...props}
    tooltip={({
      arrowRef,
      tooltipRef,
      getArrowProps,
      getTooltipProps,
      placement,
    }) => (
      <MenuWrap {...getTooltipProps({ ref: tooltipRef })}>
        {steps.map(item => {
          return (
            <MenuItem
              onClick={() =>
                history.push({
                  pathname: item.link,
                  state: item.state,
                })
              }
            >
              <IconWrap>
                <Folder color="#FEA201" />
              </IconWrap>
              <StyledSpan active={location.pathname === item.name} size={20}>
                {item.name}
              </StyledSpan>
            </MenuItem>
          );
        })}
      </MenuWrap>
    )}
  >
    {({ getTriggerProps, triggerRef }) => (
      <span
        {...getTriggerProps({
          ref: triggerRef,
          className: 'trigger',
        })}
      >
        <IconButton size="small">
          <UnwrapFolder color="#FEA201" />
        </IconButton>
      </span>
    )}
  </TooltipTrigger>
);

const MenuWrap = styled(Box)`
  position: relative;
  z-index: 1;
  border: 1px solid #dadada;
  background-color: ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
  border-radius: 4px;
`;

const MenuItem = styled(Flex)`
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 158, 255, 0.05);
  }
`;

const IconWrap = styled(Flex)`
  margin-right: 15px;
  color: ${({ theme }) => theme.colors.text};
`;

const Breadcrumbs = ({ defaultRouteName, location }) => {
  const steps = getSteps(defaultRouteName, location);
  const getInitialIndex = () => {
    const index = steps.findIndex(step => {
      return step.url && step.url.includes(location.pathname);
    });
    return index < 0 || index === undefined ? 0 : index;
  };

  const [selected, setSelected] = useState(getInitialIndex || 0);
  const [totalWidth, setTotalWidth] = useState(0);

  useEffect(() => {
    let currentWidth = 0;
    const elements = document
      // eslint-disable-next-line prefer-template
      .querySelectorAll(`[data-value]`);
    if (elements) {
      elements.forEach(element => {
        currentWidth += element.getBoundingClientRect().width;
      });
    }
    setTotalWidth(currentWidth + 20);
  }, [steps.length]);

  const handleClick = ind => {
    setSelected(ind);
    const step = steps.find((_, index) => index === ind);
    if (step) {
      history.push({
        pathname: step.link,
        state: step.state,
      });
    }
  };
  return (
    <Slider
      selected={selected}
      totalWidth={totalWidth}
      steps={steps}
      location={location}
      handleClick={handleClick}
    />
  );
};

export default withRouter(Breadcrumbs);

Breadcrumbs.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  defaultRouteName: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.any.isRequired,
  // routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Breadcrumbs.defaultProps = {
  defaultRouteName: '',
};

const Slider = ({ steps, selected, handleClick, location, totalWidth }) => {
  const tooltipSteps =
    steps.length > 3 || totalWidth > 500
      ? steps.slice(1, steps.length - 1)
      : [];

  const crumbSteps = [steps[0], steps[steps.length - 1]];

  return (
    <Content>
      {(steps.length > 3 || totalWidth > 500) && (
        <MenuTooltip
          placement="bottom-start"
          trigger="click"
          steps={tooltipSteps}
          selected={selected}
          location={location}
          onClick={handleClick}
        />
      )}
      <SliderWrap length={steps.length} selected={selected}>
        <ul>
          {(steps.length > 3 || totalWidth > 500) &&
            crumbSteps.map((item, index) => {
              return (
                <Item
                  key={index}
                  data-value={index}
                  onClick={() => handleClick(index)}
                >
                  <StyledSpan
                    active={index > 0 && item.url === item.fullPath}
                    isOver={steps.length > 3 || totalWidth > 500}
                  >
                    {item.name}
                  </StyledSpan>

                  {index < steps.length - 1 && <StyledIconRight />}
                </Item>
              );
            })}
          {steps.length <= 3 &&
            totalWidth < 500 &&
            steps.map((item, index) => {
              return (
                <Item
                  key={index}
                  data-value={index}
                  onClick={() => handleClick(index)}
                >
                  <StyledSpan
                    active={index > 0 && item.url === item.fullPath}
                    isOver={steps.length > 3 || totalWidth > 500}
                  >
                    {item.name}
                  </StyledSpan>

                  {index < steps.length - 1 && <StyledIconRight />}
                </Item>
              );
            })}
        </ul>
      </SliderWrap>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  width: max-content;

  > svg {
    cursor: pointer;

    :last-child {
      margin-left: 10px;
    }
  }
`;

const SliderWrap = styled.div`
  max-width: 500px;
  overflow: hidden;

  > ul {
    display: grid;
    grid-template-columns: ${({ length }) => `repeat(${length}, max-content)`};
    width: max-content;
    transition: all 300ms;
    padding: 0;
    margin: 0 10px;
  }
`;

const StyledIconRight = styled(ArrowKeyboardRight)`
  width: 7px;
  height: 12px;
`;

const Item = styled.li`
  display: grid;
  grid-gap: 17px;
  grid-auto-flow: column;
  align-items: center;
  width: max-content;
  margin-right: 17px;

  :last-child {
    margin-right: 0;
  }
`;

const StyledSpan = styled.span`
  font-weight: 500;
  font-size: ${({ size }) => (size ? `${size}px` : '24px')};
  line-height: 28px;
  color: ${({ active, theme }) =>
    active ? theme.colors.primary : theme.colors.text};
  text-decoration: none;
  cursor: pointer;
`;
