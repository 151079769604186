import { createEffect, createStore, createEvent, sample } from 'effector';
import camelcaseKeys from 'camelcase-keys';

import { notify } from '@lib/notifier';
import { i18n } from '@lib/i18n';
import { t } from '@lingui/macro';

import { isEmpty, sleep, getAxiosErrorResponse } from '@lib/help-fns';
import {
  fetchSingleEmployee,
  createEmployeeContact,
  createEmployeeDocument,
  createEmployeeProperty,
  createEmployeeEducation,
  updateEmployee,
  updateEmployeeContact,
  updateEmployeeDocument,
  updateEmployeeEducation,
  updateEmployeeProperty,
} from '../api';

import {
  transformEmployee,
  getEntityFiles,
  getEmployeeBasicDiff,
  getEmployeeEntitiesDiff,
} from '../lib/helpers';

const createApiMap = {
  contacts: createEmployeeContact,
  documents: createEmployeeDocument,
  educations: createEmployeeEducation,
  properties: createEmployeeProperty,
};

const updateApiMap = {
  contacts: updateEmployeeContact,
  documents: updateEmployeeDocument,
  educations: updateEmployeeEducation,
  properties: updateEmployeeProperty,
};

// Events
export const fetchEmployeeProfileRequesting = createEffect(
  'employee profile fetch requested',
);
export const startEmployeeUpdateProcess = createEffect(
  'employee update process started',
);
export const updateEmployeeProfileRequesting = createEffect(
  'employee profile update requesting',
);
export const updateEmployeeProfileEntitiesRequesting = createEffect(
  'employee profile entities update requesting',
);
export const pageUnmounted = createEvent('profile page unmounted');
export const formSubmitted = createEvent('form submitted');

// Stores
export const $profile = createStore(null);

$profile
  .on(fetchEmployeeProfileRequesting.done, (_, { result }) =>
    transformEmployee(result.data),
  )
  .reset(pageUnmounted);

// Side effects
fetchEmployeeProfileRequesting.use(fetchSingleEmployee);
startEmployeeUpdateProcess.use(handleStartUpdate);
updateEmployeeProfileRequesting.use(handleEmployeeUpdate);
updateEmployeeProfileEntitiesRequesting.use(handleEmployeeEntityUpdate);

sample($profile, startEmployeeUpdateProcess.done).watch(
  ({ id, fullName: name }) => {
    fetchEmployeeProfileRequesting(id);
    notify.success(i18n._(t`User ${name} is successfully updated!`));
  },
);
sample(
  $profile,
  startEmployeeUpdateProcess.fail,
  ({ fullName }, { error }) => ({ name: fullName, error }),
).watch(({ name, error }) =>
  notify.success(i18n._(t`Unable to update ${name}. Error: ${error}!`)),
);

// Helpers
async function handleStartUpdate({ prevValues, values }) {
  const { id } = values;
  const base = getEmployeeBasicDiff(prevValues, values);
  const { added, updated, deleted } = getEmployeeEntitiesDiff(
    prevValues,
    values,
  );
  const hasBaseValues = [base, added, deleted].some(item => !isEmpty(item));
  const hasEntitiesValues = !isEmpty(updated);

  if (hasEntitiesValues) {
    try {
      await updateEmployeeProfileEntitiesRequesting(updated);
      await sleep(300);
      if (hasBaseValues) {
        await updateEmployeeProfileRequesting({ base, added, deleted, id });
      }
      return values;
    } catch (error) {
      return getAxiosErrorResponse(error);
    }
  }
  if (hasBaseValues) {
    try {
      return await updateEmployeeProfileRequesting({
        base,
        added,
        deleted,
        id,
      });
    } catch (error) {
      return getAxiosErrorResponse(error);
    }
  }
  return values;
}

async function handleEmployeeUpdate({ base, added, deleted, id }) {
  const deletedEntities = handleDeleted(deleted);
  const addedEntities = await handleAdded(added);

  const payload = {
    id,
    ...base,
    ...addedEntities,
    ...deletedEntities,
  };

  try {
    return await updateEmployee(payload);
  } catch (error) {
    return getAxiosErrorResponse(error);
  }
}

async function handleEmployeeEntityUpdate(updated) {
  // eslint-disable-next-line guard-for-in
  for (const key in updated) {
    const updateFn = updateApiMap[key];
    const list = updated[key] || [];

    if (updateFn && list.length > 0) {
      const promises = list.map(val => {
        const payload = { ...val };
        const deleteFiles = getEntityFiles(val, file => file.deleted === true);
        const files = getEntityFiles(val, file => file.temp === true);

        if (deleteFiles) {
          payload.deleteFiles = deleteFiles.map(({ id }) => id);
        }

        if (files) {
          payload.files = files;
        }

        return updateFn(payload);
      });

      // eslint-disable-next-line no-await-in-loop
      await Promise.all(promises);
    }
  }
}

async function handleAdded(added) {
  const payload = {};

  // eslint-disable-next-line guard-for-in
  for (const key in added) {
    const createFn = createApiMap[key];

    if (createFn) {
      const list = added[key] || [];
      // eslint-disable-next-line no-await-in-loop
      const items = await Promise.all(list.map(item => createFn(item)));
      payload[key] = items.map(({ data }) => data.id);
    }
  }

  return payload;
}

function handleDeleted(deleted) {
  const payload = Object.entries(deleted).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [`delete_${key}`]: value.map(item => item.id),
    }),
    {},
  );

  return camelcaseKeys(payload, { deep: true });
}

/**
 * @typedef {Object} Employee
 * @property {string} city
 * @property {string} country
 * @property {Array<{relationship: string}>} contacts
 * @property {Array<{file: string}>} documents
 * @property {{ name: string, id: number }} department
 * @property {string} fullName
 * @property {string} jobTitle
 * @property {string} email
 * @property {string} mobilePhone
 */
