/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { createComponent } from 'effector-react';

import { $session } from '../model/auth';

export const Account = createComponent(
  $session,
  ({ render, renderEmpty }, session) => (
    <AccountView render={render} renderEmpty={renderEmpty} user={session} />
  ),
);

export function AccountView({ render, renderEmpty, user }) {
  if (user && render) {
    return render({ user });
  }

  if (!user && renderEmpty) {
    return renderEmpty();
  }

  return <Nothing />;
}

const commonPropTypes = {
  /**
   * If passed and user exist, calls this function with user and fetching props
   */
  render: PropTypes.func,
  /**
   * If passed and user not exist, calls this function with fetching prop
   */
  renderEmpty: PropTypes.func,
};

Account.propTypes = commonPropTypes;

AccountView.propTypes = {
  ...commonPropTypes,
  /**
   * User information
   */
  user: PropTypes.objectOf(PropTypes.any),
};

const Nothing = () => null;
