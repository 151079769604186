import React from 'react';

const AssetsList = React.lazy(() =>
  import('./pages/assets-list').then(module => ({
    default: module.AssetsList,
  })),
);

export const routes = [
  {
    access: ['Admin & HR:manager'],
    path: '/hr/assets',
    component: AssetsList,
    exact: true,
    navigation: {
      name: 'Allowances',
      shortName: 'AL',
    },
  },
];
