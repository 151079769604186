import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Aiff = props => (
  <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#0076F4"
    />
    <path
      d="M9.49995 14.2103C9.49995 13.7183 10.292 13.7003 10.292 14.2103V17.6333C10.292 18.1313 9.49995 18.1433 9.49995 17.6333V14.2103Z"
      fill="white"
    />
    <path
      d="M12.2852 14.6295V15.5827H13.8137C14.0297 15.5827 14.2457 15.7987 14.2457 16.0087C14.2457 16.2067 14.0297 16.368 13.8137 16.368H12.2852V17.6272C12.2852 17.8372 12.1352 17.9992 11.9252 17.9992C11.6612 17.9992 11.5 17.8372 11.5 17.6272V14.2215C11.5 14.0055 11.662 13.8495 11.9252 13.8495H14.0305C14.2945 13.8495 14.4505 14.0055 14.4505 14.2215C14.4505 14.4135 14.2945 14.6287 14.0305 14.6287H12.2852V14.6295Z"
      fill="white"
    />
    <path
      d="M15.7852 14.6295V15.5827H17.3137C17.5297 15.5827 17.7457 15.7987 17.7457 16.0087C17.7457 16.2067 17.5297 16.368 17.3137 16.368H15.7852V17.6272C15.7852 17.8372 15.6352 17.9992 15.4252 17.9992C15.1612 17.9992 15 17.8372 15 17.6272V14.2215C15 14.0055 15.162 13.8495 15.4252 13.8495H17.5305C17.7945 13.8495 17.9505 14.0055 17.9505 14.2215C17.9505 14.4135 17.7945 14.6287 17.5305 14.6287H15.7852V14.6295Z"
      fill="white"
    />
    <path
      d="M4.25388 18.0023C4.05078 17.8936 3.92384 17.6784 4.05078 17.4264L5.87155 14.0626C6.04292 13.7446 6.4991 13.7326 6.65777 14.0626L8.44681 17.4264C8.70703 17.8936 7.93905 18.2656 7.71057 17.7976L7.4313 17.2704H5.09088L4.81875 17.7984C4.7172 18.0024 4.48237 18.0444 4.25388 18.0023ZM6.98146 16.4791L6.26426 14.9979L5.48439 16.4791H6.98146Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
