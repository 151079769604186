import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { alignItems, justifyContent, height, overflow } from 'styled-system';
import { Card as BaseBox } from 'rebass';

import { SecuredImage } from '@features/common';
import { Typography } from '../atoms';

export const Avatar = React.forwardRef(
  ({ background, children, size, src, ...props }, ref) => {
    const child = React.isValidElement(children) ? (
      children
    ) : (
      <Typography as="span" fontSize="inherit">
        {children}
      </Typography>
    );
    return (
      <Box
        alignItems="center"
        bg={background || (children && 'disabled')}
        borderRadius="50%"
        fontSize={size / 2}
        height={size}
        justifyContent="center"
        overflow="hidden"
        ref={ref}
        width={size}
        {...props}
      >
        {src ? <SecuredImage src={src} /> : child}
      </Box>
    );
  },
);

Avatar.propTypes = {
  // eslint-disable-next-line react/require-default-props
  background: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  src: PropTypes.string,
};

Avatar.defaultProps = {
  children: null,
  size: 48,
};

const Box = styled(BaseBox)`
  display: flex;
  ${alignItems}
  ${justifyContent}
  ${height}
  ${overflow}
  & > svg {
    font-size: ${({ width }) => `${width / 2}px`};
    color: ${({ theme }) => theme.colors.text};
  }
`;
