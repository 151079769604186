import React from 'react';
import PropTypes from 'prop-types';

import { CommonPageTemplate } from '@features/common';
import { ContentBlock } from '@oca/ui';

export function CommonEmployeePageTemplate({ children, title }) {
  return (
    <CommonPageTemplate title={title}>
      <ContentBlock paddingBottom={4} width={1}>
        {children}
      </ContentBlock>
    </CommonPageTemplate>
  );
}

CommonEmployeePageTemplate.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

CommonEmployeePageTemplate.defaultProps = {
  children: null,
  title: null,
};
