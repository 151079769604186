import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import styled from 'styled-components';

export const BasicTemplate = ({ header, children }) => (
  <Container>
    {header && <Header>{header}</Header>}
    <Main>
      <MainContent alignItems="flex-start">{children}</MainContent>
    </Main>
  </Container>
);

BasicTemplate.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node.isRequired,
};

BasicTemplate.defaultProps = {
  header: null,
};

const Container = styled.div`
  display: grid;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto;
  grid-template-areas:
    'header'
    'main';
`;

const Header = styled.header`
  grid-area: header;
  z-index: 2;
  box-shadow: 0 1px 8px #bbb;
  background-color: white;
`;

const Main = styled.main`
  grid-area: main;
  background-color: #f2f2f2;
  overflow-y: auto;
`;

const MainContent = styled(Flex)`
  max-width: 1372px;
  width: calc(100% - 64px);
  margin: 30px auto 0;
`;
