import axios from 'axios';
import camelCase from 'camelcase-keys';
import snakeCase from 'snakecase-keys';

import { createAuthRefreshInterceptor } from '@lib/axios-auth-refresh';
import { isEmpty, hasOwnProperty, isNil } from '@lib/help-fns';
import {
  $accessToken,
  eraseTokens,
  $refreshToken,
  changeTokens,
} from '../../model/token';

export const NO_SNAKE_CASE_TRANSFORM = 'NO_SNAKE_CASE_TRANSFORM';

export const fetcher = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_PORT}`,
});

createAuthRefreshInterceptor(fetcher, () =>
  refreshToken($refreshToken.getState()),
);

// Convert backend snake_case responses into camelCase
fetcher.interceptors.response.use(
  response => camelizeResponse(response),
  error =>
    Promise.reject(error.response ? camelizeResponse(error.response) : error),
);

fetcher.interceptors.request.use(req => {
  // Set token to each request if exists
  const token = $accessToken.getState();

  if (token && req.headers.Authorization === undefined) {
    req.headers.Authorization = `Token ${token}`;
  }

  if (!isEmpty(req.data) && !isNil(req.data)) {
    // Convert request body data back to snake case
    if (
      !(req.data instanceof Blob || req.data instanceof FormData) &&
      req.data[NO_SNAKE_CASE_TRANSFORM] !== true
    ) {
      req.data = snakeCase(req.data, { deep: true });
    }
    if (hasOwnProperty(req.data, NO_SNAKE_CASE_TRANSFORM)) {
      delete req.data[NO_SNAKE_CASE_TRANSFORM];
    }
  }

  return req;
});

function camelizeResponse(response) {
  if (
    !isEmpty(response.data) &&
    !isNil(response.data) &&
    !(response.data instanceof Blob)
  ) {
    response.data = camelCase(response.data, { deep: true });
  }

  return response;
}

function refreshToken(token) {
  return new Promise((resolve, reject) =>
    fetcher
      .post('/api/v1/refresh-token/', {
        refresh_token: token,
      })
      .then(({ data }) => {
        changeTokens(data);
        resolve(data.accessToken);
      })
      .catch(err => {
        eraseTokens();
        reject(err);
      }),
  );
}
