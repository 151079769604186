import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Dbf = props => (
  <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#F15642"
    />
    <path
      d="M4.91395 18.0001C4.71595 18.0001 4.49995 17.8921 4.49995 17.6288V14.2223C4.49995 14.0071 4.71595 13.8503 4.91395 13.8503H6.2872C9.0277 13.8503 8.9677 18.0001 6.3412 18.0001H4.91395ZM5.29195 14.5823V17.2688H6.2872C7.90645 17.2688 7.97845 14.5823 6.2872 14.5823H5.29195Z"
      fill="white"
    />
    <path
      d="M9.36595 18.0001C9.14995 18.0001 8.99995 17.8381 8.99995 17.6281V14.2223C8.99995 14.0063 9.14995 13.8503 9.36595 13.8503H11.0512C11.8372 13.8503 12.2625 14.3843 12.2625 14.9956C12.2625 15.2648 12.1845 15.5296 11.9332 15.7516C12.4065 15.9376 12.6165 16.3028 12.6165 16.7468C12.6157 17.4361 12.1477 18.0001 11.2485 18.0001H9.36595ZM11.0505 14.5816H9.7852V15.4748H11.0505C11.3085 15.4748 11.4705 15.3128 11.4705 14.9948C11.4705 14.7976 11.3085 14.5816 11.0505 14.5816ZM9.7852 17.2681H11.2485C11.9445 17.2681 11.9445 16.2068 11.2485 16.2068C10.7932 16.2068 10.2592 16.2068 9.7852 16.2068V17.2681Z"
      fill="white"
    />
    <path
      d="M14.244 14.6302V15.5835H15.7732C15.9892 15.5835 16.2052 15.7995 16.2052 16.0087C16.2052 16.2067 15.9892 16.3687 15.7732 16.3687H14.244V17.628C14.244 17.838 14.0947 17.9992 13.8847 17.9992C13.6207 17.9992 13.4595 17.838 13.4595 17.628V14.2215C13.4595 14.0062 13.6215 13.8495 13.8847 13.8495H15.99C16.254 13.8495 16.41 14.0062 16.41 14.2215C16.41 14.4135 16.254 14.6295 15.99 14.6295H14.244V14.6302Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
