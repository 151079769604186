import React from 'react';

import { Email } from '@oca/icons';
import { LoginPage } from './pages/login';

export const routes = [
  { path: '/login', component: LoginPage, exact: true },
  {
    path: '/email',
    navigation: { name: 'Email', icon: <Email /> },
    component: () => window.location.replace('https://mail.ocasia.online'),
    target: '_blank',
  },
];
