import originalAxios from 'axios';

/** @type {Object} */
const defaults = {
  /** @type {Number[]} */
  statusCodes: [
    401, // Unauthorized
  ],
};

let isRefreshing = false;

let subscribers = [];
/**
 * @typedef {import('axios').AxiosInstance} AxiosInstance
 */

/**
 * Creates an authentication refresh interceptor that binds to any error response.
 * If the response code is 401, interceptor tries to call the refreshTokenCall which must return a Promise.
 * While refreshTokenCall is running, all new requests are intercepted and waiting for it to resolve.
 * After Promise is resolved/rejected the authentication refresh interceptor is revoked.
 * @param {AxiosInstance|Function} axios - axios instance
 * @param {Function} refreshTokenCall - refresh token call which must return a Promise
 * @param {Object} options - options for the interceptor @see defaultOptions
 * @return {AxiosInstance}
 */
export function createAuthRefreshInterceptor(
  axios,
  refreshTokenCall,
  options = {},
) {
  const statusCodes =
    Object.prototype.hasOwnProperty.call(options, 'statusCodes') &&
    options.statusCodes.length
      ? options.statusCodes
      : defaults.statusCodes;

  axios.interceptors.response.use(undefined, err => {
    const originalRequest = err.config;
    const status = err.response ? err.response.status : null;

    if (statusCodes.includes(status)) {
      if (!isRefreshing) {
        isRefreshing = true;
        refreshTokenCall().then(token => {
          isRefreshing = false;
          onRefreshed(token);
          subscribers = [];
        });
      }
      const requestSubscribers = new Promise(resolve => {
        subscribeTokenRefresh(token => {
          originalRequest.headers.Authorization = `Token ${token}`;
          resolve(originalAxios(originalRequest));
        });
      });
      return requestSubscribers;
    }
    return Promise.reject(err);
  });

  return axios;
}

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onRefreshed(token) {
  subscribers.map(cb => cb(token));
}
