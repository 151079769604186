import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text } from 'rebass';
import styled from 'styled-components';
import { formatRelative } from 'date-fns';

import { history } from '@lib/routing';
import { Avatar, Typography } from '@oca/ui';
import { Person, CloseX, Meeting, FileCheck, File } from '@oca/icons';

import { notificationMsg } from '../models/notification-msg';
import {
  readNotification,
  deleteNotification,
} from '../models/notifications-flow';

const notificationIcon = {
  meetings: Meeting,
  meeting: Meeting,
  tasks: FileCheck,
  task: FileCheck,
  documents: File,
};

export const NotificationView = React.memo(
  ({ data: { initiator, ...data }, closeNotificationsPanel }) => {
    const { notificationTitle, notificationRoute } = notificationMsg(
      data.title,
      data.action,
      data.domain,
      data.domainObjId,
    );

    return (
      <Wrapper
        py={15}
        px={20}
        alignItems="flex-start"
        bg={!data.isRead && 'rgba(0, 118, 244, 0.05)'}
      >
        <Flex
          flex={1}
          alignItems="flex-start"
          mr={15}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (!data.isRead) {
              readNotification([data.id]);
            }
            history.replace(notificationRoute);
            closeNotificationsPanel();
          }}
        >
          <AvatarWarp>
            <Avatar
              src={initiator && initiator.avatar}
              size={48}
              title={
                initiator && `${initiator.firstName} ${initiator.lastName}`
              }
            >
              <Person color="#aaa" />
            </Avatar>
            <TypeWrap>
              <Avatar size={24} background="#0076F4" title={data.domain}>
                {React.createElement(
                  notificationIcon[data.domain] || notificationIcon.documents,
                  {
                    color: '#fff',
                  },
                )}
              </Avatar>
            </TypeWrap>
          </AvatarWarp>
          <Box flex={1} ml={15}>
            <Typography fontSize={16} color="primary" lineHeight={1}>
              {notificationTitle}
            </Typography>
            {initiator && (
              <Text fontSize={14} color="#424B68" mt="4px">
                by {`${initiator.firstName} ${initiator.lastName}`}
              </Text>
            )}
            <Text fontSize={12} mt={10} color="rgba(68, 75, 104, 0.7)">
              {formatRelative(new Date(data.createdAt), new Date())}
            </Text>
          </Box>
        </Flex>
        <Box style={{ cursor: 'pointer' }}>
          <CloseX
            fontSize={16}
            color="#424B68"
            onClick={() => {
              deleteNotification([data.id]);
            }}
          />
        </Box>
      </Wrapper>
    );
  },
);
NotificationView.propTypes = {
  data: PropTypes.shape({
    isRead: PropTypes.bool,
  }).isRequired,
  closeNotificationsPanel: PropTypes.func.isRequired,
};

const Wrapper = styled(Flex)`
  border-bottom: 1px solid rgba(68, 75, 104, 0.1);

  &:last-child {
    border-bottom: none;
  }

  & a {
    text-decoration: none;
  }
`;

const AvatarWarp = styled(Box)`
  position: relative;
`;

const TypeWrap = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(10%, 15%);
`;
