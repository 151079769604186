import React from 'react';
import { useStore } from 'effector-react';
import styled from 'styled-components';
import { Flex, Box, Text } from 'rebass';
import { Link } from 'react-router-dom';

import { $notificationList } from '@features/notifications/models/notifications-flow';

import {
  OCALogoColored,
  DefaultAvatar,
  Bell,
  Person,
  Logout,
} from '@oca/icons';
import { Avatar, Typography, IconButton, Menu } from '@oca/ui';

import { Trans } from '@lingui/macro';
import { history } from '@lib/routing';
import { createTransition } from '@oca/ui/theme';
import { $session, logoutRequesting } from '../model/auth';

import { openNotificationsPanel } from '../model/panel-toggle-flow';

export function Header() {
  const notificationList = useStore($notificationList);
  const notificationCount = (notificationList.results || []).filter(
    item => !item.isRead,
  ).length;

  return (
    <Flex alignItems="center" justifyContent="space-between" px={4} py={3}>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <CenteredFlex>
          <OCALogoColored fontSize={48} />
          <Typography fontWeight={300} className="logoText">
            OCA
          </Typography>
        </CenteredFlex>
      </Link>
      <CenteredFlex>
        <NotificationWrap onClick={() => openNotificationsPanel()}>
          <IconButton size="big" padding={8}>
            <Bell color="#009EFF" />
          </IconButton>
          {notificationCount ? (
            <NotificationCount>
              <Text color="#fff" fontSize={12}>
                {notificationCount < 20 ? notificationCount : '20+'}
              </Text>
            </NotificationCount>
          ) : (
            ''
          )}
        </NotificationWrap>
        <User />
      </CenteredFlex>
    </Flex>
  );
}

function User() {
  const user = useStore($session) || {};

  return (
    <React.Fragment>
      <Menu
        placement="bottom-end"
        anchor={
          <AvatarWrapper tabIndex={0}>
            <Avatar src={user.avatar}>
              <DefaultAvatar />
            </Avatar>
          </AvatarWrapper>
        }
      >
        <Menu.Item
          icon={<Person />}
          onClick={() => history.push(`/hr/employees/${user.username}`)}
        >
          <Trans>Profile</Trans>
        </Menu.Item>
        <Menu.Item icon={<Logout />} onClick={logoutRequesting}>
          <Trans>Logout</Trans>
        </Menu.Item>
      </Menu>
    </React.Fragment>
  );
}

const Wrapper = styled(Flex)`
  box-shadow: 0 1px 8px #bbb;
`;

const CenteredFlex = styled(Flex)`
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const NotificationWrap = styled(Box)`
  position: relative;
`;

const NotificationCount = styled(Flex)`
  position: absolute;
  right: 0;
  top: 3px;
  width: 27px;
  height: 18px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  background-color: #fe2400;
`;

const AvatarWrapper = styled.div`
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: ${createTransition(['border-color'])};
  outline: none;
  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;
