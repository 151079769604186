import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { List, UploadItem } from '@oca/ui';

export const UploadedFileList = React.memo(function UploadedFileList({
  items,
  statusText,
  onDownload,
  onDelete,
  ...rest
}) {
  return (
    <List
      {...rest}
      items={items}
      renderItem={({ file, originalName, fileName, temp, deleted }) =>
        deleted ? null : (
          <UploadItem
            key={file}
            id={file}
            title={originalName || fileName}
            statusText={statusText}
            onDownload={
              temp
                ? undefined
                : () => onDownload({ url: file, filename: fileName })
            }
            onDelete={onDelete}
          />
        )
      }
    />
  );
});

UploadedFileList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string.isRequired,
      filename: PropTypes.string,
      temp: PropTypes.bool,
    }),
  ).isRequired,
  statusText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  // eslint-disable-next-line react/require-default-props
  onDownload: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onDelete: PropTypes.func,
};

UploadedFileList.defaultProps = {
  statusText: <Trans>Successfully uploaded</Trans>,
};
