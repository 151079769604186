import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { employeeListFetchRequesting } from '@features/common';

import { DataTable, Link, Avatar } from '@oca/ui';
import { useStore } from 'effector-react';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lib/i18n';
import { Person } from '@oca/icons';
import { Tooltip } from '@oca/ui/atoms/tooltip';
import { $employeeList } from '../model/list';
import { ListHeader } from '../organisms';
import { CommonEmployeePageTemplate } from '../templates';

export function EmployeeListPage() {
  const employees = useStore($employeeList);

  React.useEffect(() => {
    employeeListFetchRequesting();
  }, []);

  return (
    <CommonEmployeePageTemplate title={i18n._(t`HR: Employees`)}>
      <ListHeader />
      <DataTable
        data={employees}
        columns={columns}
        showPagination={false}
        loadingText={<Trans>Loading</Trans>}
        pageSize={employees.length || 20}
        defaultSorted={[
          {
            id: 'fullName',
            desc: false,
          },
        ]}
      />
    </CommonEmployeePageTemplate>
  );
}

const columns = [
  {
    Header: null,
    id: 'avatar',
    accessor: employee => (
      <Tooltip title={employee.fullName}>
        <Avatar src={employee.avatar}>
          <Person />
        </Avatar>
      </Tooltip>
    ),
    maxWidth: 100,
  },
  {
    Header: <Trans>Full name</Trans>,
    id: 'fullName',
    // eslint-disable-next-line react/prop-types
    Cell: row => {
      return (
        <Link as={RouterLink} to={`/hr/employees/${row.original.username}`}>
          {row.value}
        </Link>
      );
    },
    accessor: employee => employee.fullName || employee.username,
  },
  {
    Header: <Trans>Department & Position </Trans>,
    id: 'departmentAndPostion',
    accessor: employee =>
      `${employee.department.name} / ${employee.jobTitle || employee.position}`,
  },
  { Header: <Trans>Email</Trans>, accessor: 'email' },
  {
    Header: <Trans>Work number</Trans>,
    id: 'workPhone',
    accessor: employee => employee.workPhone || 'N/A',
  },
  {
    Header: <Trans>Address</Trans>,
    id: 'address',
    accessor: employee => `${employee.city}, ${employee.country}`,
  },
];
