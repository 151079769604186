import React from 'react';
import PropTypes from 'prop-types';

import topbar from 'topbar';

import { lighten } from 'polished';
import { theme } from '../theme';

export function TopBarProgress({
  autoRun,
  barThickness,
  barColors,
  shadowBlur,
  shadowColor,
}) {
  React.useEffect(() => {
    topbar.config({
      autoRun,
      barColors,
      barThickness,
      shadowColor,
      shadowBlur,
    });

    topbar.show();

    return () => topbar.hide();
  }, [autoRun, barColors, barThickness, shadowBlur, shadowColor]);

  return null;
}

TopBarProgress.propTypes = {
  autoRun: PropTypes.bool,
  barColors: PropTypes.arrayOf(PropTypes.string),
  barThickness: PropTypes.number,
  shadowBlur: PropTypes.number,
  shadowColor: PropTypes.string,
};

TopBarProgress.defaultProps = {
  autoRun: true,
  barColors: [theme.colors.secondary, lighten(0.4, theme.colors.secondary)],
  barThickness: 3,
  shadowBlur: 10,
  shadowColor: 'rgba(0, 0, 0, .6)',
};
