import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Text } from 'rebass';
import { SvgIcon } from '../atoms/svg-icon';

const CheckIcon = () => (
  <SvgIcon fontSize={16} viewBox="5 5 14 14">
    <path d="M10.5714 16L7 12.1612L8.00714 11.0787L10.5714 13.8273L15.9929 8L17 9.09021L10.5714 16Z" />
  </SvgIcon>
);

const IconContainer = styled(Flex)`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  & > svg {
    visibility: hidden;
  }
`;

const Input = styled.input`
  opacity: 0;
  z-index: -1;
  position: absolute;
  &:checked + ${IconContainer} > svg {
    visibility: visible;
  }
  &:focus + ${IconContainer} {
    border: ${({ theme }) => `2px solid ${theme.colors.primary}`};
  }
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fontFamily};
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: self-start;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  ${IconContainer} {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.textSecondary : theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.textSecondary};
  }
  &:hover ${IconContainer} {
    border-color: ${({ disabled, theme }) => !disabled && theme.colors.text};
  }
`;

export const Checkbox = React.forwardRef(
  ({ checked, disabled, label, name, onChange, tabIndex, readOnly }, ref) => {
    return (
      <Label disabled={disabled}>
        <Input
          type="checkbox"
          disabled={disabled}
          name={name}
          tabIndex={tabIndex}
          checked={checked}
          onChange={onChange}
          ref={ref}
          readOnly={readOnly}
        />
        <IconContainer alignItems="center" justifyContent="center">
          <CheckIcon />
        </IconContainer>
        {label && (
          <Text color="text" fontSize={1} lineHeight="20px" marginLeft={2}>
            {label}
          </Text>
        )}
      </Label>
    );
  },
);

Checkbox.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  label: null,
  name: '',
  readOnly: false,
  tabIndex: 0,
};
