import React from 'react';
import PropTypes from 'prop-types';

import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import usePrevious from 'react-use/lib/usePrevious';

import { Row, Col } from '@lib/grid';
import { Typography, IconButton, Avatar, Chip } from '@oca/ui';
import { Add, Documents } from '@oca/icons';
import { FieldsetAllowance } from '../molecules';
import { createPropertyModel } from '../model/static';
import { fetchStateProperties } from '../api';

/**
 *
 * @param {{ form: import('react-final-form').FormRenderProps }}
 */
export function FormAllowances({ form }) {
  return (
    <React.Fragment>
      <Row spacing={1} alignItems="center">
        <Col>
          <Typography variant="h1">Allowances</Typography>
        </Col>
        <Col>
          <IconButton
            color="primary"
            onClick={() =>
              form.mutators.push('properties', createPropertyModel())
            }
          >
            <Add />
          </IconButton>
        </Col>
      </Row>
      <FieldArray name="properties" component={AllowanceForm} />
    </React.Fragment>
  );
}

FormAllowances.propTypes = {
  form: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

function AllowanceForm({ fields }) {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [state, setState] = React.useState({ categories: [], items: [] });

  const currentFieldsLength = fields.value.length;
  const prevFieldsLength = usePrevious(currentFieldsLength);

  React.useEffect(() => {
    fetchStateProperties().then(data => setState(data));
  }, []);

  React.useEffect(() => {
    // Always set last element as active when adding new entry
    if (prevFieldsLength < currentFieldsLength) {
      setActiveIndex(currentFieldsLength - 1);
    }
  }, [currentFieldsLength, prevFieldsLength]);

  if (currentFieldsLength > 0) {
    return (
      <React.Fragment>
        <AllowanceChipList
          list={fields.map(i => i)}
          activeIndex={activeIndex}
          onItemClick={setActiveIndex}
          onDelete={index => {
            fields.remove(index);
            if (index <= activeIndex) {
              setActiveIndex(activeIndex - 1);
            }
          }}
        />
        {activeIndex >= 0 && (
          <FieldsetAllowance name={`properties[${activeIndex}]`} {...state} />
        )}
      </React.Fragment>
    );
  }

  return (
    <Typography variant="body1" color="textSecondary">
      No allowances yet
    </Typography>
  );
}

AllowanceForm.propTypes = {
  fields: PropTypes.shape({
    value: PropTypes.array,
  }).isRequired,
};

function AllowanceChipList({ activeIndex, list, onItemClick, onDelete }) {
  return (
    <ChipWrapper>
      {list.map((fieldName, index) => (
        <Chip
          avatar={
            <Avatar>
              <Documents />
            </Avatar>
          }
          key={fieldName}
          color={activeIndex === index ? 'primary' : 'default'}
          label={`Allowance #${index + 1}`}
          onClick={() => onItemClick(index)}
          onDelete={() => onDelete(index)}
        />
      ))}
    </ChipWrapper>
  );
}

AllowanceChipList.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  onItemClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const ChipWrapper = styled.div`
  margin-bottom: 32px;
  & > * {
    margin: 4px;
  }
`;
