import React from 'react';
import ReactDayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import styled from 'styled-components';

const DayPickerWrap = styled.div`
  & > .DayPickerInput {
    display: block;
  }
`;

export function DayPickerCalendar(props) {
  return (
    <DayPickerWrap>
      <ReactDayPicker {...props} />
    </DayPickerWrap>
  );
}

DayPickerCalendar.propTypes = ReactDayPicker.propTypes;
