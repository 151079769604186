import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { Typography, IconButton } from '@oca/ui';
import { Row } from '@lib/grid';
import { Add } from '@oca/icons';
import { Trans } from '@lingui/macro';

import {
  DOCUMENTS,
  EDUCATION_LEVELS,
  createDocumentModel,
  createEducationModel,
} from '../model/static';
import {
  FieldsetDocument,
  FieldsetContract,
  FieldsetEducation,
  SectionTitle,
} from '../molecules';

const titles = {
  [DOCUMENTS.PASSPORT]: <Trans>Passport</Trans>,
  [DOCUMENTS.CIVIL_ID]: <Trans>Civil ID</Trans>,
  [DOCUMENTS.VISA]: <Trans>Visa</Trans>,
  [DOCUMENTS.WORK_PERM]: <Trans>Work permission</Trans>,
  [DOCUMENTS.CONTRACT]: <Trans>Employment Contract</Trans>,
  [DOCUMENTS.NDA]: <Trans>NDA</Trans>,
  [EDUCATION_LEVELS.MASTER]: <Trans>Master</Trans>,
  [EDUCATION_LEVELS.BACHELOR]: <Trans>Bachelor</Trans>,
  [EDUCATION_LEVELS.CERTIFICATE]: <Trans>Certificate</Trans>,
};

const contractOptions = [
  {
    id: DOCUMENTS.CONTRACT,
    label: titles[DOCUMENTS.CONTRACT],
  },
  {
    id: DOCUMENTS.NDA,
    label: titles[DOCUMENTS.NDA],
  },
];

/**
 *
 * @param {{ form: import('react-final-form').FormRenderProps }}
 */
export function FormDocuments({ educationOptions, form }) {
  return (
    <React.Fragment>
      <FieldArray
        name="documents"
        render={({ fields }) =>
          fields.map(fieldName => (
            <React.Fragment key={fieldName}>
              <Field
                name={`${fieldName}.docType`}
                render={({ input: { value } }) => (
                  <Typography variant="h2" gutterBottom>
                    {titles[value]}
                  </Typography>
                )}
              />
              <FieldsetDocument key={fieldName} name={fieldName} />
            </React.Fragment>
          ))
        }
      />
      <FieldArray
        name="contracts"
        render={({ fields }) => (
          <React.Fragment>
            <SectionTitle
              title={<Trans>Signed contracts</Trans>}
              action={
                <AddButton
                  onClick={() =>
                    fields.push(createDocumentModel(DOCUMENTS.NDA))
                  }
                  // Only two contracts now
                  disabled={fields.value.length === 2}
                />
              }
            />
            {fields.map((fieldName, index) => (
              <FieldsetContract
                key={fieldName}
                index={index + 1}
                name={fieldName}
                onDelete={index > 0 ? fields.pop : undefined}
                options={contractOptions}
              />
            ))}
          </React.Fragment>
        )}
      />
      <FieldArray name="educations">
        {({ fields }) => (
          <React.Fragment>
            <SectionTitle
              title={<Trans>Education</Trans>}
              action={
                <AddButton
                  onClick={() =>
                    fields.push(createEducationModel(EDUCATION_LEVELS.MASTER))
                  }
                />
              }
            />
            {fields.value.length === 0 && (
              <Row mb={4}>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                  <Trans>
                    No educations, click on <strong>+</strong> icon to add one
                  </Trans>
                </Typography>
              </Row>
            )}
            {fields.map((field, index) => (
              <FieldsetEducation
                key={field}
                name={field}
                index={index}
                options={educationOptions}
                onDelete={index > 0 ? () => fields.remove(index) : undefined}
              />
            ))}
          </React.Fragment>
        )}
      </FieldArray>
    </React.Fragment>
  );
}

FormDocuments.propTypes = {
  educationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  form: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

FormDocuments.defaultProps = {
  educationOptions: Object.values(EDUCATION_LEVELS).map(value => ({
    value,
    name: titles[value],
  })),
};

function AddButton(props) {
  return (
    <IconButton color="primary" {...props}>
      <Add />
    </IconButton>
  );
}
