import React from 'react';
import { SvgIcon } from '../svg-icon';

export const AddCircleOutlined = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM11.2969 12.4906L8.68211 12.4906V11.3537H11.2969L11.2969 8.62527H12.5474V11.3537L15.1622 11.3537V12.4906H12.5474V15.219L11.2969 15.219L11.2969 12.4906Z"
    />
  </SvgIcon>
);
