import { fetcher } from '@features/common';

const endpoint = '/api/v1/notifications/';

export const getNotificationsList = () => {
  return fetcher.get(endpoint);
};

export const controlNotificationsList = data => {
  return fetcher.post(`${endpoint}updating/`, data);
};
