import React from 'react';
import PropTypes from 'prop-types';
import { List, UploadItem } from '@oca/ui';
import { Trans } from '@lingui/macro';
import { bytesToSize } from '@lib/filesize';

export function ProcessingFileList({
  items,
  processing,
  onCancel,
  onDelete,
  renderStatusText,
}) {
  return (
    <List
      items={items}
      renderItem={file => {
        const { progress, error, status } = processing[file.name] || {};
        if (status === 'done') {
          return null;
        }
        return (
          <UploadItem
            key={file.name}
            id={file.name}
            title={file.name}
            statusText={renderStatusText({
              status,
              progress,
              error,
              fileSize: file.size,
            })}
            error={status === 'fail'}
            progress={progress || null}
            onCancel={onCancel}
            onDelete={onDelete}
          />
        );
      }}
    />
  );
}

ProcessingFileList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(File)).isRequired,
  processing: PropTypes.objectOf(
    PropTypes.shape({
      status: PropTypes.oneOf(['loading', 'fail', 'done', 'error']),
      progress: PropTypes.number,
      error: PropTypes.string,
    }),
  ).isRequired,
  renderStatusText: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onCancel: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onDelete: PropTypes.func,
};
ProcessingFileList.defaultProps = {
  renderStatusText: ({ status, progress, error, fileSize }) => {
    let statusText = (
      <Trans>
        Ready to upload <small>({bytesToSize(fileSize)})</small>
      </Trans>
    );

    if (status === 'loading') {
      const current = bytesToSize((progress * fileSize) / 100);
      const total = bytesToSize(fileSize);

      statusText = (
        <Trans>
          Uploading: {current} of {total}
        </Trans>
      );
    }

    if (status === 'fail') {
      statusText = <Trans>Failed to upload: {error}</Trans>;
    }
    return statusText;
  },
};
