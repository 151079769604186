import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Dashboard = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.79999 4.79999H20.2C21 4.79999 21.7 5.39999 21.6 6.19999V7.79999H2.29999V6.19999C2.29999 5.39999 2.99999 4.79999 3.79999 4.79999ZM2.29999 17.8V8.70001H21.7V17.8C21.7 18.6 21 19.3 20.2 19.3H3.79999C2.99999 19.3 2.29999 18.6 2.29999 17.8ZM4.09999 15.7C4.09999 16.2 4.49999 16.6 4.99999 16.6C5.49999 16.6 5.89999 16.2 5.89999 15.7C5.89999 15.2 5.49999 14.8 4.99999 14.8C4.49999 14.8 4.09999 15.2 4.09999 15.7ZM4.09999 12.2C4.09999 12.7 4.49999 13.1 4.99999 13.1C5.49999 13.1 5.89999 12.7 5.89999 12.2C5.89999 11.7 5.49999 11.3 4.99999 11.3C4.49999 11.3 4.09999 11.7 4.09999 12.2ZM7.59999 16.6H12.9C13.4 16.6 13.8 16.2 13.8 15.7C13.8 15.2 13.4 14.8 12.9 14.8H7.59999C7.09999 14.8 6.69999 15.2 6.69999 15.7C6.69999 16.2 7.09999 16.6 7.59999 16.6ZM7.59999 13.1H12.9C13.4 13.1 13.8 12.7 13.8 12.2C13.8 11.7 13.4 11.3 12.9 11.3H7.59999C7.09999 11.3 6.69999 11.7 6.69999 12.2C6.69999 12.7 7.09999 13.1 7.59999 13.1Z"
    />
  </SvgIcon>
);
