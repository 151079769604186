import React from 'react';
import { Dashboard } from '@oca/icons';

const DashboardPage = React.lazy(() =>
  import('./pages/Dashboard').then(module => ({
    default: module.Dashboard,
  })),
);

export const routes = [
  {
    path: '/',
    component: DashboardPage,
    exact: true,
    navigation: {
      name: 'Dashboard',
      icon: <Dashboard />,
    },
  },
];
