import { lazy } from 'react';

const LeavesList = lazy(() =>
  import('./pages').then(module => ({
    default: module.LeavesList,
  })),
);

export const routes = [
  {
    path: '/hr/leaves',
    component: LeavesList,
    exact: true,
    navigation: {
      name: 'Leaves',
    },
  },
  {
    path: '/hr/leaves/:id',
    component: LeavesList,
    exact: true,
    name: 'Tasks',
  },
];
