import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Dng = props => (
  <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#576D7E"
    />
    <path
      d="M4.18204 18C3.98404 18 3.76804 17.8912 3.76804 17.628V14.2222C3.76804 14.0062 3.98404 13.8502 4.18204 13.8502H5.55529C8.29654 13.8502 8.23579 18 5.60929 18H4.18204ZM4.56004 14.5815V17.268H5.55529C7.17454 17.268 7.24654 14.5815 5.55529 14.5815H4.56004Z"
      fill="white"
    />
    <path
      d="M8.38939 14.2702C8.38939 14.0535 8.43739 13.8442 8.74939 13.8442C8.96464 13.8442 9.01339 13.8982 9.17464 14.0535L11.1591 16.5367V14.2102C11.1591 14.0122 11.3324 13.7955 11.5371 13.7955C11.7531 13.7955 11.9624 14.0122 11.9624 14.2102V17.586C11.9624 17.8507 11.8004 17.9512 11.6444 18C11.4351 18 11.3324 17.952 11.1591 17.7907L9.17464 15.2715V17.5867C9.17464 17.8515 9.01264 18.0007 8.79664 18.0007C8.58064 18.0007 8.38864 17.8515 8.38864 17.5867V14.2702H8.38939Z"
      fill="white"
    />
    <path
      d="M16.6285 17.5387C16.2025 17.892 15.6805 18.0427 15.1532 18.0427C13.8932 18.0427 13 17.3227 13 15.8955C13 14.6842 13.942 13.743 15.2065 13.743C15.6805 13.743 16.2017 13.905 16.5737 14.271C16.9397 14.6302 16.4117 15.1642 16.0525 14.847C15.8305 14.6302 15.5185 14.469 15.2065 14.469C14.4812 14.469 13.78 15.0502 13.78 15.8955C13.78 16.7835 14.3672 17.3227 15.1532 17.3227C15.5185 17.3227 15.8305 17.2147 16.0525 17.0527V16.3162H15.1532C14.6192 16.3162 14.6732 15.5835 15.1532 15.5835H16.3525C16.5745 15.5835 16.7777 15.7522 16.7777 15.9382V17.2155C16.7785 17.3227 16.7305 17.424 16.6285 17.5387Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
