import React from 'react';
import ReactDayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import styled from 'styled-components';

import { TextField } from './text-field';

const DayPickerWrap = styled.div`
  & > .DayPickerInput {
    display: block;
  }
`;

export function DayPickerInput(props) {
  return (
    <DayPickerWrap>
      <ReactDayPickerInput
        {...props.inputProps}
        {...props}
        component={TextField}
      />
    </DayPickerWrap>
  );
}

DayPickerInput.propTypes = ReactDayPickerInput.propTypes;
