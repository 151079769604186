import { combine, createEvent, createEffect, sample } from 'effector';

import {
  $employeeList,
  $departmentList,
  employeeListFetchRequesting,
  departmentListFetchRequesting,
} from '@features/common';
import { createFetching } from '@lib/effector-utils';

export const pageMounted = createEvent('page mounted');
export const fetchDataRequesting = createEffect('data fetch requested');

fetchDataRequesting.use(() =>
  Promise.all([
    departmentListFetchRequesting().catch(console.warn),
    employeeListFetchRequesting(),
  ]),
);

export const $fetching = createFetching(fetchDataRequesting);

export const $hasData = combine(
  $employeeList,
  $departmentList,
  (employeeList, departmentList) => [...employeeList, ...departmentList],
).map(list => list.length > 0);

sample($hasData, pageMounted).watch(hasData => {
  if (!hasData) {
    fetchDataRequesting();
  }
});
