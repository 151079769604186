import React from 'react';
import { Trans, t } from '@lingui/macro';
import { Form, Field } from 'react-final-form';
import { Box } from 'rebass';

import { createComponent } from 'effector-react';

import { TextField, Button, Checkbox, Typography } from '@oca/ui';
import { i18n } from '@lib/i18n';

import { FORM_ERROR } from 'final-form';
import { getDeviceId } from '@lib/device-id';
import { resolveRejectedPromise } from '@lib/help-fns';
import { TextFieldWrapper } from '@lib/final-form-oca-ui';
import { $loginFetching, loginRequesting } from '../model/auth';
import { $isTokenPersistent, setTokenPersistence } from '../model/token';

export const LoginForm = createComponent(
  { fetching: $loginFetching, isTokenPersistent: $isTokenPersistent },
  (_, { fetching, isTokenPersistent }) => {
    return (
      <Form
        validate={validate}
        onSubmit={handleLoginSubmit}
        render={({ handleSubmit, submitError }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Field
                name="email"
                label={<Trans>Email</Trans>}
                component={TextFieldWrapper}
                placeholder={i18n._(t`Example: j.smith@oca.com`)}
                helperText=" "
                autoComplete="nope"
                required
                fullWidth
                spaceAfter
              />
              <Field
                name="password"
                type="password"
                component={TextFieldWrapper}
                helperText=" "
                label={<Trans>Password</Trans>}
                required
                spaceAfter
                fullWidth
              />
              <Checkbox
                checked={isTokenPersistent}
                onChange={e => {
                  setTokenPersistence(!isTokenPersistent);
                }}
                label={<Trans>Remember me</Trans>}
              />
              <Box my={3}>
                {submitError && (
                  <Box p={2}>
                    <Typography variant="caption" color="error">
                      {submitError}
                    </Typography>
                  </Box>
                )}
                <Button
                  variant="primary"
                  type="submit"
                  disabled={fetching.isLoading}
                >
                  <Trans>Login</Trans>
                </Button>
              </Box>
            </form>
          );
        }}
      />
    );
  },
);

async function handleLoginSubmit({ email, password }) {
  const error = await resolveRejectedPromise(
    loginRequesting({ email, password, device_id: getDeviceId() }),
  );

  if (!error) {
    return {
      [FORM_ERROR]: (
        <Trans>
          Something went wrong with a server, please try again later
        </Trans>
      ),
    };
  }

  if (error.data.nonFieldErrors) {
    return {
      [FORM_ERROR]: <Trans>Email not found or password is wrong</Trans>,
    };
  }
  return Object.entries(error.data).reduce(
    (errors, [field, errorMessages]) => ({
      ...errors,
      [field]: errorMessages.join(', '),
    }),
    {},
  );
}

const emailRegexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function validate(values) {
  const errors = {};
  if (!values.email) {
    errors.email = <Trans>This is required field</Trans>;
  }
  if (values.email && !emailRegexp.test(values.email)) {
    errors.email = <Trans>Please enter valid email address</Trans>;
  }

  if (!values.password) {
    errors.password = <Trans>This field is required</Trans>;
  }
  return errors;
}
