import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';
import usePrevious from 'react-use/lib/usePrevious';

function OnChangeState({ children, value }) {
  const prevValue = usePrevious(value);

  React.useEffect(() => {
    if (prevValue !== value) {
      children(value, prevValue);
    }
  }, [children, prevValue, value]);

  return null;
}

/**
 * Listening for particular field value change and call passed callback function
 * @param {{ name: string, children: Function }} props
 */
export function OnChange({ name, children }) {
  return (
    <Field
      allowNull
      name={name}
      subscription={{ value: true }}
      render={({ input: { value } }) => (
        <OnChangeState value={value}>{children}</OnChangeState>
      )}
    />
  );
}

OnChange.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};
