import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import styled, { css } from 'styled-components';
import AriaModal from 'react-aria-modal';
import { LightClose } from '../atoms/icons/light-close';
import { IconButton } from '../atoms/icon-button';
import { Typography } from '../atoms';

const DialogWrapper = styled.div`
  position: relative;
  border-radius: 6px;
  background: white;
  margin: 20px;
`;
const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #009eff;
  min-height: 60px;
  color: #fff;
  padding-left: 30px;
  padding-right: 18px;
  justify-content: space-between;
`;
const DialogBody = styled.div`
  padding: 30px;
  min-height: 150px;

  ${({ scrollBody }) =>
    scrollBody
      ? css`
          max-height: 80vh;
          overflow-y: auto;
        `
      : ''}
`;

const ActionBtns = ({ onClickOnClose }) => {
  return (
    <Box ml={15}>
      <IconButton
        color="white"
        tabIndex="0"
        onClick={onClickOnClose}
        size="small"
      >
        <LightClose />
      </IconButton>
    </Box>
  );
};

ActionBtns.propTypes = {
  onClickOnClose: PropTypes.func.isRequired,
};

export const Dialog = ({
  children,
  title,
  onClickOnClose,
  underlayClickExits,
  scrollBody,
  ...other
}) => {
  return (
    <AriaModal
      titleText={title}
      onExit={onClickOnClose}
      underlayClickExits={underlayClickExits}
      verticallyCenter
      {...other}
    >
      <DialogWrapper>
        <DialogHeader>
          <Typography
            noWrap
            width="100px"
            flex="1"
            color="white"
            fontSize="24px"
            fontWeight="500"
            lineHeight="28px"
          >
            {title}
          </Typography>
          <ActionBtns onClickOnClose={onClickOnClose} />
        </DialogHeader>
        <DialogBody scrollBody={scrollBody}>{children}</DialogBody>
      </DialogWrapper>
    </AriaModal>
  );
};

Dialog.propTypes = {
  ...AriaModal.propTypes,
  scrollBody: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onClickOnClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  underlayClickExits: PropTypes.bool,
};

Dialog.defaultProps = {
  underlayClickExits: true,
  scrollBody: false,
};
