import { createStore, createEvent } from 'effector';

const PERSISTENT_KEY = `${process.env.REACT_APP_PREFIX}:persistent`;
const TOKENS_KEY = `${process.env.REACT_APP_PREFIX}:tokens`;

// Events
export const setTokenPersistence = createEvent('token persistence set');
export const changeTokens = createEvent('tokens changed');
export const eraseTokens = createEvent('tokens erased');

// Stores
const initialTokens = { accessToken: null, refreshToken: null };
export const $isTokenPersistent = createStore(
  localStorage.getItem(PERSISTENT_KEY) === 'true',
);
export const $tokens = createStore(
  rehydrate(TOKENS_KEY, { persistent: $isTokenPersistent.getState() }) ||
    initialTokens,
);
export const $accessToken = $tokens.map(data => data.accessToken);
export const $refreshToken = $tokens.map(data => data.refreshToken);
export const $mediaToken = $tokens.map(data => data.mediaToken);

$tokens
  .on(changeTokens, (_, tokens) => tokens)
  .on(eraseTokens, () => initialTokens);

$isTokenPersistent.on(
  setTokenPersistence,
  (_, shouldRemember) => shouldRemember,
);

// Side effects

changeTokens.watch(tokens =>
  saveTokensToStorage(tokens, {
    persistent: $isTokenPersistent.getState(),
  }),
);
eraseTokens.watch(() =>
  eraseTokensFromStorage({ persistent: $isTokenPersistent.getState() }),
);
setTokenPersistence.watch(shouldRemember =>
  localStorage.setItem(PERSISTENT_KEY, shouldRemember),
);

function rehydrate(key, { persistent } = {}) {
  let data;
  try {
    data = JSON.parse(getStorage(persistent).getItem(key));
  } catch (e) {
    data = null;
  }
  return data;
}

function eraseTokensFromStorage({ persistent } = {}) {
  getStorage(persistent).removeItem(TOKENS_KEY);
}

function saveTokensToStorage(tokens, { persistent } = {}) {
  getStorage(persistent).setItem(TOKENS_KEY, JSON.stringify(tokens));
}

function getStorage(persistent) {
  return persistent ? localStorage : sessionStorage;
}
