import { createEffect } from 'effector';

import { history } from '@lib/routing';
import { notify } from '@lib/notifier';
import { i18n } from '@lib/i18n';
import { t } from '@lingui/macro';
import { getFullName } from '@lib/help-fns';

import {
  createEmployeeDocument,
  createEmployee,
  createEmployeeProperty,
  createEmployeeEducation,
  createEmployeeContact,
} from '../api';

export const createEmployeeRequesting = createEffect(
  'employee create requested',
);

createEmployeeRequesting.done.watch(({ params }) => {
  const name = getFullName(params);

  notify.success(i18n._(t`User ${name} successfully created!`));
  history.replace('/hr/employees');
});
createEmployeeRequesting.fail.watch(() => {
  notify.error(i18n._(t`Unable to create a user`));
});

createEmployeeRequesting.use(handleEmployeeCreate);

async function handleEmployeeCreate(employee) {
  try {
    const contacts = await Promise.all(
      employee.contacts.map(item => createEmployeeContact(item)),
    );
    const documents = await Promise.all(
      [...employee.documents, ...employee.contracts].map(item =>
        createEmployeeDocument(filterDeletedFiles(item)),
      ),
    );
    const educations = await Promise.all(
      employee.educations.map(item =>
        createEmployeeEducation(filterDeletedFiles(item)),
      ),
    );
    const properties = await Promise.all(
      employee.properties.map(item =>
        createEmployeeProperty(filterDeletedFiles(item)),
      ),
    );

    return createEmployee({
      ...employee,
      contacts: contacts.map(mapId),
      documents: documents.map(mapId),
      educations: educations.map(mapId),
      properties: properties.map(mapId),
    });
  } catch (error) {
    return Promise.reject(error);
  }
}

function mapId({ data }) {
  return data ? data.id : null;
}

function filterDeletedFiles(item) {
  return {
    ...item,
    files: item.files ? item.files.filter(file => file.deleted !== true) : [],
  };
}
