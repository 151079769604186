import React from 'react';
import PropTypes from 'prop-types';

import { Tabs } from '@oca/ui';
import { AddPerson, Email, Documents, FileCheck } from '@oca/icons';
import { Trans } from '@lingui/macro';
import { $session } from '@features/common';
import { useStore } from 'effector-react';

export function FormStepper({
  compact,
  currentValue,
  profile,
  tabs,
  stretched,
  onChange,
}) {
  const user = useStore($session);
  let filteredTabs = [];

  if (!user) {
    return null;
  }

  if (user.topLevelPermission) {
    filteredTabs = tabs;
  }

  if (profile && profile.id && !user.topLevelPermission) {
    filteredTabs = tabs.filter(tab => {
      return (
        tab.visibleToAll ||
        profile.id === user.id ||
        (profile.department.id === user.department.id &&
          user.position === 'manager')
      );
    });
  }

  return (
    <Tabs
      tabs={filteredTabs}
      currentValue={currentValue}
      onChange={onChange}
      compact={compact}
      stretched={stretched}
    />
  );
}

const formSteps = [
  {
    label: <Trans>Basic information</Trans>,
    icon: AddPerson,
    visibleToAll: true,
  },
  {
    label: <Trans>Contacts</Trans>,
    icon: Email,
    visibleToAll: true,
  },
  {
    label: <Trans>Documents</Trans>,
    icon: Documents,
    visibleToAll: false,
  },
  {
    label: <Trans>Allowances</Trans>,
    icon: FileCheck,
    visibleToAll: false,
  },
];

FormStepper.propTypes = {
  ...Tabs.propTypes,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  profile: PropTypes.shape({
    id: PropTypes.number,
  }),
};

FormStepper.defaultProps = {
  tabs: formSteps,
  profile: null,
};

function filterTabs(tabs = []) {
  return tabs.filter();
}
