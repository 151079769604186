import { createEffect, createStore, createEvent, forward } from 'effector';
import { createFetching } from '@lib/effector-utils';
import { loginUser, logoutUser, fetchUserInfo } from '../api';
import { changeTokens, eraseTokens } from './token';

// Events
export const dropSession = createEvent('session dropped');
export const loginRequesting = createEffect('login requested');
export const logoutRequesting = createEffect('logout requested');
export const sessionFetchRequesting = createEffect('session fetch requested');

// Stores
export const $session = createStore(null);
export const $isAuthenticated = $session.map(user => user !== null);
export const $loginFetching = createFetching(loginRequesting);
export const $sessionFetching = createFetching(sessionFetchRequesting);

// Thunks
loginRequesting.use(loginUser);
logoutRequesting.use(logoutUser);
sessionFetchRequesting.use(fetchUserInfo);

// Reducers

$session
  .on(sessionFetchRequesting.done, (_, { result }) => ({
    ...result.data,
    permissions: [`${result.data.department.name}:${result.data.position}`],
    topLevelPermission:
      result.data.position === 'director' ||
      (result.data.position === 'manager' &&
        result.data.department.name === 'Admin & HR'),
  }))
  .on(logoutRequesting.done, () => null);

// Side Effects
loginRequesting.done.watch(({ result }) => {
  changeTokens(result.data);
  sessionFetchRequesting(result.data.accessToken);
});

[logoutRequesting.done, dropSession].forEach(event =>
  forward({
    from: event,
    to: eraseTokens,
  }),
);
