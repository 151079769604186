import React from 'react';
import styled from 'styled-components';
import { Search } from '@oca/icons';
import PropTypes from 'prop-types';

const SearchContainer = styled.div`
  position: relative;
  width: 237px;
  height: 40px;
  border-bottom: 1px solid #009eff;
  display: flex;
  align-items: center;
`;

const SearchInputBase = styled.input`
  width: 200px;
  height: 38px;
  border-width: 0px;
  outline: none;
  margin-left: 8px;
  font-size: 34px;
  &::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 34px;
    color: #009eff;
    vertical-align: middle;
  }
`;

export const SearchInput = React.forwardRef(({ value, onChange }, ref) => {
  return (
    <SearchContainer>
      <Search />
      <SearchInputBase
        value={value}
        onChange={onChange}
        ref={ref}
        placeholder="Search"
      />
    </SearchContainer>
  );
});

SearchInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

SearchInput.defaultProps = {
  value: '',
};
