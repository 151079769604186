import { Box, Flex } from 'rebass';
import { getPx } from 'styled-system';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const Col = styled(Box)``;
Col.propTypes = Box.propTypes;

export const Row = styled(Flex)`
  margin-right: -${getSpacing};
  margin-left: -${getSpacing};
  ${Col} {
    padding: ${getSpacing};
  }
`;
Row.propTypes = {
  ...Flex.propTypes,
  /**
   * Set the underlying Col components 'spacing' by changing it's padding property.
   * May represent spacing index or a value.
   * Note that any passed value will be converted to px, e.g. spacing="2rem" -> 2px.
   * @example
   * <Row spacing={2}>
   *   <Col>1</Col>
   * </Row>
   */
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
Row.defaultProps = {
  spacing: 0,
};

function getSpacing({ spacing, theme }) {
  return getPx(theme.space[spacing] || spacing);
}
