/**
 * Converts bytes into readable size string
 * @param {number} bytes
 * @param {string} seperator
 * @returns {string} Human readable string
 */
export function bytesToSize(bytes, seperator = '') {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes}${seperator}${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)}${seperator}${sizes[i]}`;
}
