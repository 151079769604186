import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Pencil = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7726 7.04691C19.0758 7.35015 19.0758 7.83999 18.7726 8.14323L17.3497 9.56611L14.434 6.65037L15.8568 5.22749C16.1601 4.92425 16.6499 4.92425 16.9532 5.22749L18.7726 7.04691ZM5.0025 18.9975V16.0817L13.602 7.48224L16.5177 10.398L7.91824 18.9975H5.0025Z"
    />
  </SvgIcon>
);
