import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Dropzone, Button } from '@oca/ui';

import usePrevious from 'react-use/lib/usePrevious';
import saveFile from 'file-saver';
import { useFileUploading } from '../hooks';
import { UploadedFileList } from '../molecules/uploaded-file-list';
import { ProcessingFileList, RejectedFileList } from '../molecules';
import { fetcher } from '../lib/fetcher';

/**
 * @typedef {Object} FileUploaderProps
 * @property {string} accept
 * @property {number} maxSize
 * @property {Boolean} disabled
 * @property {Boolean} error
 * @property {Function} onChange
 * @property {Array<{file: string, filename: string, temp: Boolean}>} value
 */

/**
 *
 * @param {FileUploaderProps} props
 */
export const FileUploader = React.memo(function FileUploader({
  accept,
  error,
  maxSize,
  disabled,
  onChange,
  value: uploaded = [],
}) {
  const [processingFiles, setProcessingFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const {
    result,
    processing,
    isUploading,
    onUpload,
    onCancelUploads,
  } = useFileUploading();
  const prevResultLength = usePrevious(result.length);

  useEffect(() => {
    if (prevResultLength !== result.length && result.length > 0) {
      const newFile = result[result.length - 1];
      setProcessingFiles(files =>
        files.filter(file => file.name !== newFile.originalName),
      );
      onChange(uploaded.concat([newFile]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, prevResultLength]);

  const handleProcessingFilesDelete = ids =>
    setProcessingFiles(files =>
      files.filter(({ name }) => !ids.includes(name)),
    );

  const handleUploadedFilesDelete = ids =>
    onChange(
      uploaded.map(item =>
        ids.includes(item.file) ? { ...item, deleted: true } : item,
      ),
    );

  const handleUploadCancel = name => {
    handleProcessingFilesDelete([name]);
    onCancelUploads([name]);
  };

  const isAlreadyInDropZone = (processFiles, file) => {
    const inDroppedFiles = processFiles.filter(processingFile => {
      return processingFile.name === file[0].name;
    });
    const inUploadedFiles = uploaded.filter(uploaded_file => {
      return uploaded_file.originalName === file[0].name;
    });
    return inDroppedFiles.length || inUploadedFiles.length;
  };

  const hasUploadedFiles = uploaded.length > 0;
  const hasProcessingFiles = processingFiles.length > 0;
  const hasRejectedFiles = rejectedFiles.length > 0;
  return (
    <div>
      <Dropzone
        error={error}
        maxSize={maxSize}
        accept={accept}
        disabled={isUploading || disabled}
        onDrop={(accepted, rejected) => {
          if (!isAlreadyInDropZone(processingFiles, accepted)) {
            setProcessingFiles(files => files.concat(accepted));
          }
          setRejectedFiles(rejectedFiles.concat(rejected));
        }}
      />
      {hasRejectedFiles && (
        <RejectedFileList
          items={rejectedFiles}
          validation={{ maxSize, accept }}
          onDelete={id =>
            setRejectedFiles(files => files.filter(({ name }) => id !== name))
          }
        />
      )}
      {hasUploadedFiles && (
        <UploadedFileList
          items={uploaded}
          onDelete={id => handleUploadedFilesDelete([id])}
          onDownload={onDownload}
        />
      )}
      {hasProcessingFiles && (
        <ProcessingFileList
          items={processingFiles}
          processing={processing}
          onDelete={
            !isUploading ? id => handleProcessingFilesDelete([id]) : undefined
          }
          onCancel={isUploading ? handleUploadCancel : undefined}
        />
      )}
      {processingFiles.length > 0 && (
        <Button type="button" onClick={() => onUpload(processingFiles)}>
          Upload
        </Button>
      )}
    </div>
  );
});

FileUploader.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
      filename: PropTypes.string,
      temp: PropTypes.bool,
    }),
  ).isRequired,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  maxSize: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  accept: PropTypes.string,
};

FileUploader.defaultProps = {
  error: false,
};

function onDownload({ url, filename }) {
  fetcher
    .get(url, { responseType: 'blob' })
    .then(res => saveFile(res.data, filename));
}
