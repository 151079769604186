import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { BasicTemplate } from '@oca/ui';
import { i18n } from '@lib/i18n';
import { t } from '@lingui/macro';
import { Header, Navigation } from '../organisms';

export function CommonPageTemplate({ children, title }) {
  const defaultTitle = i18n._(t`OCA - Olympic Council of Asia`);

  return (
    <BasicTemplate sideNav={<Navigation />} header={<Header />}>
      <Helmet defaultTitle={defaultTitle} titleTemplate="%s | OCA">
        {title && <title>{title}</title>}
      </Helmet>
      {children}
    </BasicTemplate>
  );
}

CommonPageTemplate.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

CommonPageTemplate.defaultProps = {
  children: null,
  title: null,
};
