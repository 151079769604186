import React from 'react';
import styled from 'styled-components';

export const ProgressBar = props => <Content {...props} />;

ProgressBar.defaultProps = {
  height: 4,
  progress: 0,
  stopped: false,
};

const Content = styled.div`
  width: 100%;
  height: ${({ height }) => `${height}px`};
  background-color: rgba(254, 162, 1, 0.24);
  border-radius: 6px;

  &::after {
    content: '';
    display: block;
    width: ${({ progress }) =>
      // eslint-disable-next-line no-nested-ternary
      progress >= 100
        ? '100%'
        : progress < 100 && progress >= 0
        ? `${progress}%`
        : '0%'};
    height: ${({ height }) => `${height}px`};
    background-color: ${({ progress, stopped, theme }) =>
      // eslint-disable-next-line no-nested-ternary
      progress === 100 && !stopped
        ? theme.colors.primary
        : progress && stopped
        ? theme.colors.error
        : theme.colors.danger};
    opacity: 1;
    border-radius: 6px;
  }
`;
