import React from 'react';
import PropTypes from 'prop-types';
import { Button as Base } from 'rebass';
import styled from 'styled-components';

import { createTransition } from '../theme';

const fontSize = {
  small: 2,
  medium: 2,
  large: 2,
};
const py = {
  small: 8,
  medium: 10,
  large: 20,
};

const ButtonBase = styled(Base)`
  box-sizing: 'border-box';
  min-height: 40px;
  min-width: 96px;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  white-space: nowrap;
  outline: none;
  svg {
    margin-right: 8px;
  }
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transition: ${({ theme }) =>
    createTransition(['background-color', 'box-shadow', 'border'], {
      duration: theme.duration.normal,
    })};
`;

export const Button = React.forwardRef(
  ({ children, variant, size, fullWidth, tabIndex, ...other }, ref) => {
    return (
      <ButtonBase
        {...other}
        width={fullWidth ? '100%' : 'auto'}
        ref={ref}
        variant={variant}
        fontSize={fontSize[size]}
        fontWeight="500"
        px={20}
        py={py[size]}
        tabIndex={tabIndex}
      >
        {children}
      </ButtonBase>
    );
  },
);

Button.propTypes = {
  variant: PropTypes.oneOf([
    'primary',
    'default',
    'primaryOutlined',
    'errorOutlined',
    'dangerOutlined',
  ]),
  size: PropTypes.oneOf(Object.keys(fontSize)),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  fullWidth: PropTypes.bool,
  tabIndex: PropTypes.number,
};

Button.defaultProps = {
  variant: 'default',
  size: 'medium',
  fullWidth: false,
  tabIndex: 0,
};
