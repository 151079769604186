import { createEffect, createStore } from 'effector';

import { getNotificationsList, controlNotificationsList } from '../api';

// Effects
export const getNotifications = createEffect('get notifications list');
export const deleteNotification = createEffect('remove notification');
export const deleteAlllNotifications = createEffect('remove all notifications');
export const readNotification = createEffect('read notification');
export const readAlllNotifications = createEffect('read all notification');

// Set thunks
getNotifications.use(() => getNotificationsList());

deleteNotification.use((notifications = []) =>
  controlNotificationsList({
    notifications,
    action_type: 'delete',
    for_all: false,
  }),
);

deleteAlllNotifications.use(() =>
  controlNotificationsList({
    action_type: 'delete',
    for_all: true,
  }),
);

readNotification.use((notifications = []) =>
  controlNotificationsList({
    notifications,
    action_type: 'read',
    for_all: false,
  }),
);

readAlllNotifications.use(() =>
  controlNotificationsList({
    action_type: 'read',
    for_all: true,
  }),
);

// Stores
export const $notificationList = createStore({});

// Side Effects
$notificationList
  .on(getNotifications.done, (_, { result }) => {
    return result.data;
  })
  .reset(deleteAlllNotifications.done)
  .reset(getNotifications.fail);

readNotification.done.watch(() => getNotifications());
readAlllNotifications.done.watch(() => getNotifications());
deleteNotification.done.watch(() => getNotifications());
