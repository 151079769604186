import React from 'react';
import { useStore } from 'effector-react';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';

import { IconButton } from '../atoms';
import { Send } from '../atoms/icons';

const TextInput = styled.input`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 10px 30px;
  width: 100%;
  outline: none;
`;

// eslint-disable-next-line react/prop-types
export const CommentForm = ({ id, request, requestStatus, ...props }) => {
  const [commentary, setCommentary] = React.useState('');
  const { isLoading } = useStore(requestStatus);

  const handleCommentSubmit = async e => {
    e.preventDefault();

    const data = { meeting_id: id, text: commentary };
    const result = await request({ id, data });

    if (result && result.data) {
      setCommentary('');
    }
  };

  return (
    <FormWrap onSubmit={handleCommentSubmit} {...props}>
      <Flex alignItems="center">
        <Box flex={1} mr={10}>
          <TextInput
            name="text"
            value={commentary}
            placeholder="Type your comment..."
            required
            onChange={e => setCommentary(e.target.value)}
          />
        </Box>
        <Box>
          <IconButton
            type="submit"
            disabled={commentary === '' || isLoading}
            color="#424B68"
          >
            <Send />
          </IconButton>
        </Box>
      </Flex>
    </FormWrap>
  );
};

const FormWrap = styled.form`
  width: 100%;
  flex: 1;
`;
