import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { Trans } from '@lingui/macro';

import { Button, WizardStep, WizardContext } from '@oca/ui';
import { Row, Col } from '@lib/grid';

import { OnChange } from '@lib/react-final-form-on-change';
import { createDocumentModel, DOCUMENTS } from '../model/static';

export function EmployeeCreateForm({ initialValues, steps, onSubmit }) {
  const [values, setValues] = useState(initialValues);
  const { activeStepIndex: currentStep, goForward } = useContext(WizardContext);

  const isLastStep = currentStep === steps.length - 1;
  const handleFormSubmit = val => {
    if (isLastStep) {
      onSubmit(val);
    } else {
      setValues(val);
      goForward();
    }
  };

  return (
    <Form
      initialValues={values}
      validate={steps[currentStep] ? steps[currentStep].validate : undefined}
      mutators={{ ...arrayMutators }}
      onSubmit={handleFormSubmit}
      subscription={{ pristine: true, submitting: true }}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} noValidate>
          <OnTypeChange form={form} />
          {steps.map(({ component: StepForm }, index) => (
            <WizardStep key={index}>
              {({ isActive, previousStep }) =>
                isActive && (
                  <React.Fragment>
                    <StepForm form={form} initialValues={initialValues} />
                    <StepFooter
                      onPrev={previousStep}
                      onNext={form.submit}
                      currentStep={currentStep}
                      totalSteps={steps.length - 1}
                    />
                  </React.Fragment>
                )
              }
            </WizardStep>
          ))}
        </form>
      )}
    />
  );
}

EmployeeCreateForm.propTypes = {
  initialValues: PropTypes.objectOf(PropTypes.any),
  onSubmit: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.func,
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }),
  ).isRequired,
};

EmployeeCreateForm.defaultProps = {
  initialValues: {},
};

// eslint-disable-next-line react/prop-types
function StepFooter({ onPrev, onNext, currentStep, totalSteps }) {
  const firstStep = currentStep === 0;
  const lastStep = currentStep === totalSteps;

  return (
    <Row spacing={3}>
      <Col>
        <Button type="button" onClick={onPrev} disabled={firstStep}>
          <Trans>Previous</Trans>
        </Button>
      </Col>
      <Col>
        <Button type="button" variant="primary" onClick={onNext}>
          {lastStep ? <Trans>Finish</Trans> : <Trans>Next</Trans>}
        </Button>
      </Col>
    </Row>
  );
}

// eslint-disable-next-line react/prop-types
function OnTypeChange({ form }) {
  return (
    <OnChange name="isResident">
      {(value, prevValue) => {
        if (value === false && prevValue === true) {
          form.mutators.push('documents', createDocumentModel(DOCUMENTS.VISA));
          form.mutators.push(
            'documents',
            createDocumentModel(DOCUMENTS.WORK_PERM),
          );
        } else if (value === true && prevValue === false) {
          form.mutators.pop('documents');
          form.mutators.pop('documents');
        }
      }}
    </OnChange>
  );
}
