import React from 'react';
import { Trans } from '@lingui/macro';
import { emailRegex } from '@lib/regex';

const requiredMessage = <Trans>This field is required</Trans>;

const required = (fields, values, message = requiredMessage) => {
  return fields.reduce((acc, field) => {
    if (
      values[field] === undefined ||
      values[field] === null ||
      values[field] === ''
    ) {
      acc[field] = message;
    }
    return acc;
  }, {});
};

function email(value, message = <Trans>Please enter a valid email</Trans>) {
  return emailRegex().test(value) ? undefined : message;
}

export function validateBasic(values) {
  const requiredFields = required(
    ['username', 'firstName', 'lastName', 'birthday', 'department', 'jobTitle'],
    values,
  );

  return requiredFields;
}

export function validateContacts(values) {
  let errors = {};
  const basicContacts = required(
    ['street', 'country', 'city', 'mobilePhone', 'email'],
    values,
  );
  if (!basicContacts.email && email(values.email)) {
    basicContacts.email = email(values.email);
  }

  const contacts = values.contacts.reduce(
    (acc, contact) =>
      acc.concat([required(['relationship', 'mobilePhone'], contact)]),
    [],
  );

  if (Object.keys(basicContacts).length > 0) {
    errors = { ...errors, ...basicContacts };
  }
  if (contacts.length > 0) {
    errors.contacts = contacts;
  }

  return errors;
}

export function validateDocuments(values) {
  const errors = {};
  const validations = {
    documents: validateDocument(values.documents, ['expDate']),
    contracts: validateDocument(values.contracts, ['expDate']),
    educations: validateDocument(values.educations, ['institution']),
  };

  Object.entries(validations).forEach(([key, value]) => {
    if (value && value.length > 0) {
      errors[key] = value;
    }
  });

  return errors;
}

export function validateProperties(values) {
  const errors = {};
  const propertiesErrors = validateDocument(values.properties, [
    'stateProperty',
  ]);
  if (propertiesErrors.length > 0) {
    errors.properties = propertiesErrors;
  }
  return errors;
}

function validateDocument(list, requiredFields) {
  return list.reduce((acc, document) => {
    const err = required(requiredFields, document);

    if (
      document.files &&
      (document.files.length === 0 ||
        document.files.every(file => file.deleted === true))
    ) {
      err.files = requiredMessage;
    }
    return acc.concat([err]);
  }, []);
}
