import React from 'react';

import { CommonPageTemplate } from '@features/common';

import { ContentBlock, Wizard } from '@oca/ui';
import { AddPerson, Email, Documents, FileCheck } from '@oca/icons';

import { Trans } from '@lingui/macro';
import { Header } from '../molecules';
import {
  EmployeeCreateForm,
  FormBasic,
  FormContacts,
  FormDocuments,
  FormAllowances,
} from '../organisms';
import {
  validateBasic,
  validateContacts,
  validateDocuments,
  validateProperties,
} from '../model/validation';
import {
  createContactModel,
  DOCUMENTS,
  createDocumentModel,
  createEducationModel,
} from '../model/static';
import { createEmployeeRequesting } from '../model/create';

const formSteps = [
  {
    component: FormBasic,
    label: <Trans>Basic information</Trans>,
    icon: AddPerson,
    validate: validateBasic,
  },
  {
    component: FormContacts,
    label: <Trans>Contacts</Trans>,
    icon: Email,
    validate: validateContacts,
  },
  {
    component: FormDocuments,
    label: <Trans>Documents</Trans>,
    icon: Documents,
    validate: validateDocuments,
  },
  {
    component: FormAllowances,
    label: <Trans>Allowances</Trans>,
    icon: FileCheck,
    validate: validateProperties,
  },
];

const documents = [
  createDocumentModel(DOCUMENTS.PASSPORT),
  createDocumentModel(DOCUMENTS.CIVIL_ID),
];

const initialValues = {
  isResident: true,
  employmentStatus: 'full_time',
  position: 'expert',
  contacts: [createContactModel()],
  documents,
  contracts: [createDocumentModel(DOCUMENTS.CONTRACT)],
  educations: [createEducationModel('master')],
  properties: [],
};

export function EmployeeCreatePage() {
  const handleSubmit = async values => {
    createEmployeeRequesting(values);
  };
  return (
    <CommonPageTemplate>
      <ContentBlock px={4} paddingBottom={4} width={1}>
        <Wizard>
          <Header steps={formSteps} />
          <EmployeeCreateForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            steps={formSteps}
          />
        </Wizard>
      </ContentBlock>
    </CommonPageTemplate>
  );
}

EmployeeCreatePage.propTypes = {};
