import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *,
  *:after,
  *:before {
    box-sizing: border-box;
  } 

  body, #root {
    position: relative;
    height: 100vh;
    overflow: hidden;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
  }

  #root {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
  }
`;
