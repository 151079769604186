import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useStore } from 'effector-react';
import { Box } from 'rebass';

import { BlockHead, Wizard, Progress } from '@oca/ui';

import { i18n } from '@lib/i18n';
import { t } from '@lingui/macro';

import { $employeeList } from '@features/common';
import {
  fetchEmployeeProfileRequesting,
  $profile,
  pageUnmounted,
} from '../model/profile';

import {
  FormStepper,
  EmployeeEditForm,
  FormBasic,
  FormContacts,
  FormDocuments,
  FormAllowances,
} from '../organisms';

import {
  validateBasic,
  validateContacts,
  validateDocuments,
  validateProperties,
} from '../model/validation';
import { CommonEmployeePageTemplate } from '../templates';
import { ProfileInfo } from '../molecules/profile-info';

const $employeeForUsername = $employeeList.map(list => username =>
  list.find(employee => employee.username === username) || {},
);

export function EmployeeProfilePage({ match }) {
  /**
   * @type {import('../model/profile').Employee}
   */
  const profile = useStore($profile);
  const { username } = match.params;
  const { id } = useStore($employeeForUsername)(username);

  useEffect(() => {
    if (id) fetchEmployeeProfileRequesting(id);

    return () => pageUnmounted();
  }, [id]);
  const fullName = profile ? profile.fullName : '';

  return (
    <CommonEmployeePageTemplate title={i18n._(t`HR: ${fullName}`)}>
      <Wizard>
        {({ activeStepIndex, moveToStep }) => {
          if (!profile) {
            return (
              <Box style={{ height: '100vh' }}>
                <Progress />
              </Box>
            );
          }

          return (
            <React.Fragment>
              <BlockHead
                marginBottom={4}
                flexDirection="column"
                alignItems="stretch"
              >
                <ProfileInfo {...profile} profilePath={match.url} />
                <FormStepper
                  profile={profile}
                  currentValue={activeStepIndex}
                  onChange={moveToStep}
                  stretched
                />
              </BlockHead>
              <Box px={4} width={1}>
                <EmployeeEditForm
                  onSubmit={noop}
                  validate={noop}
                  editable={false}
                  initialValues={profile}
                  steps={formSteps}
                />
              </Box>
            </React.Fragment>
          );
        }}
      </Wizard>
    </CommonEmployeePageTemplate>
  );
}

EmployeeProfilePage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

const formSteps = [
  {
    component: FormBasic,
    validate: validateBasic,
  },
  {
    component: FormContacts,
    validate: validateContacts,
  },
  {
    component: FormDocuments,
    validate: validateDocuments,
  },
  {
    component: FormAllowances,
    validate: validateProperties,
  },
];

function noop() {}
