import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Csv = props => (
  <SvgIcon {...props} width="24" height="30" viewBox="0 0 24 30">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#F7B84E"
    />
    <path
      d="M9.06945 14.7495C9.20745 13.5862 10.9639 13.377 11.792 14.013C12.2 14.3422 11.768 14.8642 11.408 14.6002C10.9639 14.3182 9.9567 14.1862 9.8307 14.8095C9.6687 15.7935 12.2772 15.2302 12.236 16.8247C12.194 18.348 9.9867 18.384 9.1587 17.7C8.9607 17.5387 8.9667 17.2755 9.0747 17.1127C9.2307 16.9575 9.4047 16.9035 9.6087 17.0707C10.0999 17.4067 11.366 17.6587 11.4552 16.806C11.378 15.9195 8.88345 16.4535 9.06945 14.7495Z"
      fill="white"
    />
    <path
      d="M4.50006 15.8947C4.50006 14.7374 5.22606 13.7422 6.60531 13.7422C7.12731 13.7422 7.54131 13.8982 7.97856 14.2762C8.14056 14.4254 8.15856 14.6894 7.99656 14.8574C7.83456 15.0007 7.58856 14.9834 7.44456 14.8394C7.19931 14.5814 6.94731 14.5102 6.60531 14.5102C5.68206 14.5102 5.23881 15.1642 5.23881 15.8954C5.23881 16.6394 5.67606 17.3227 6.60531 17.3227C6.94731 17.3227 7.26531 17.1839 7.54131 16.9387C7.72656 16.7947 7.98456 16.8659 8.09256 17.0107C8.18856 17.1427 8.23581 17.3647 8.02656 17.5739C7.60656 17.9647 7.10331 18.0427 6.60456 18.0427C5.15406 18.0427 4.50006 17.0519 4.50006 15.8947Z"
      fill="white"
    />
    <path
      d="M14.3337 17.7307L12.7505 14.4142C12.5165 13.9402 13.2245 13.551 13.4877 14.0482L14.0217 15.2535L14.711 16.8367L15.3882 15.2535L15.9222 14.0482C16.1622 13.593 16.8575 13.8802 16.6542 14.3782L15.125 17.7307C14.987 18.1087 14.5617 18.198 14.3337 17.7307Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
