import MD5 from 'md5.js';
import { $mediaToken } from '../../model/token';
import { $session } from '../../model/auth';

/**
 * Signs given url with token
 * @param {string} url URL that should be signed
 * @param {string} paramKey Paramter key
 */
export function signUrl(url, paramKey = 'token') {
  const { id } = $session.getState() || {};
  const signature = new MD5()
    .update(`${$mediaToken.getState()}/${process.env.REACT_APP_MEDIA_SECRET}`)
    .digest('hex');
  const token = `${id}.${signature}`;

  return `${url}?${paramKey}=${btoa(token)}`;
}
