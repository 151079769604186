import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Button as ButtonBase } from 'rebass';
import { transparentize } from 'polished';

import { createTransition } from '../theme';

const sizes = {
  small: 20,
  normal: 24,
  medium: 26,
  big: 32,
};

export const IconButton = React.forwardRef(
  ({ children, size, padding, ...props }, ref) => {
    return (
      <Button
        px={padding}
        py={padding}
        size={sizes[size] || 16}
        onMouseDown={event => {
          // eslint-disable-next-line react/prop-types
          props.onMouseDown && props.onMouseDown(event);
          event.preventDefault();
        }}
        {...props}
        ref={ref}
      >
        {children}
      </Button>
    );
  },
);

IconButton.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.oneOf(['small', 'normal', 'medium', 'big']),
};

IconButton.defaultProps = {
  type: 'button',
  children: null,
  color: 'primary',
  size: 'normal',
  padding: 12,
};

const Button = styled(ButtonBase)`
  display: inline-flex;
  border-radius: 50%;
  background-color: transparent;
  font-size: ${({ size }) => size}px;
  cursor: pointer;
  outline: none;
  transform: scale(1);
  transition: ${({ theme }) =>
    createTransition(['background-color', 'transform'], {
      easing: theme.easing.easeInOut,
    })};
  :focus {
    background-color: ${getBackgroundColor(0.75)};
  }
  :hover:not(:disabled) {
    background-color: ${getBackgroundColor(0.9)};
  }
  :active {
    transform: scale(0.9);
  }
  :disabled {
    opacity: 0.5;
    cursor: default;
  }
  & > svg {
    font-size: ${({ size }) => size}px;
  }
`;

function getBackgroundColor(opacity = 0.1) {
  return ({ color, theme }) =>
    transparentize(opacity, theme.colors[color] || color);
}
