import React from 'react';
import PropTypes from 'prop-types';
import { Field, FormSpy } from 'react-final-form';
import { Trans } from '@lingui/macro';

import {
  SelectWrapper,
  TextFieldWrapper,
  DayPickerInputWrapper,
} from '@lib/final-form-oca-ui';
import { Row, Col } from '@lib/grid';
import { FileUploaderField } from '@features/common';
import { SelectBox } from '@oca/ui';
import { OnChange } from '@lib/react-final-form-on-change';
import { format } from 'date-fns';

export const FieldsetAllowance = React.memo(function Allowance({
  name,
  categories,
  items,
}) {
  const [category, setCategory] = React.useState(null);
  const filteredItems =
    category === null
      ? items
      : items.filter(item => item.category.id === category.id);
  const statePropertyField = `${name}.stateProperty`;

  return (
    <Row flexDirection="column">
      <Col>
        <Row spacing="10px">
          <Col width={[1 / 3, 1 / 4, 1 / 5]}>
            <FormSpy
              render={({ form }) => (
                <SelectBox
                  value={category}
                  onChange={val => {
                    setCategory(val);
                    if (category && val.id !== category.id) {
                      form.change(statePropertyField, null);
                    }
                  }}
                  label={<Trans>Category</Trans>}
                  options={categories}
                />
              )}
            />
            <OnChange name={statePropertyField}>
              {value => {
                if (value && items && items.length > 0) {
                  const item = items.find(i => i.id === value);
                  setCategory(item.category);
                }
              }}
            </OnChange>
          </Col>
          <Col width={[1 / 3, 1 / 4, 1 / 5]}>
            <Field
              name={statePropertyField}
              label={<Trans>Item</Trans>}
              component={SelectWrapper}
              getOptionLabel={option =>
                option
                  ? `${option.mark} ${option.model} ${option.serialNumber}`
                  : ''
              }
              parse={val => val && val.id}
              format={val => (val ? items.find(({ id }) => id === val) : '')}
              options={filteredItems}
            />
          </Col>
        </Row>
      </Col>
      <Col>
        <Row spacing="10px">
          <Col width={1 / 2}>
            <Field
              name={`${name}.description`}
              component={TextFieldWrapper}
              label={<Trans>Note</Trans>}
            />
          </Col>
        </Row>
      </Col>
      <Col>
        <Row spacing="10px">
          <Col width={[1 / 3, 1 / 4, 1 / 5]}>
            <Field
              name={`${name}.assignDate`}
              component={DayPickerInputWrapper}
              parse={val => (val ? format(val, 'yyyy-MM-dd') : '')}
              format={val => (val ? new Date(val) : null)}
              label={<Trans>Assign date</Trans>}
            />
          </Col>
          <Col width={[1 / 3, 1 / 4, 1 / 5]}>
            <Field
              name={`${name}.returnDate`}
              component={DayPickerInputWrapper}
              parse={val => (val ? format(val, 'yyyy-MM-dd') : '')}
              format={val => (val ? new Date(val) : null)}
              label={<Trans>Return date</Trans>}
            />
          </Col>
        </Row>
      </Col>
      <Col width={1 / 2}>
        <FileUploaderField
          name={`${name}.files`}
          label={<Trans>Signed allowances form from assignee</Trans>}
        />
      </Col>
    </Row>
  );
});

FieldsetAllowance.propTypes = {
  name: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
};

FieldsetAllowance.defaultProps = {
  categories: [],
  items: [],
};
