import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '../molecules';
import { Typography, Button } from '../atoms';

export function ConfirmationDialog({
  title,
  description,
  confirmText,
  cancelText,
  children,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [callback, setCallback] = useState(null);

  const onConfirmationOpen = onConfirm => event => {
    if (event.preventDefault) {
      event.preventDefault();
      // eslint-disable-next-line
      event = {
        ...event,
        target: { ...event.target, value: event.target.value },
      };
    }
    setCallback(() => () => onConfirm(event));
    setIsOpen(true);
  };
  const handleConfirm = () => {
    callback();
    setIsOpen(false);
  };
  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      {children(fn => onConfirmationOpen(fn))}
      {isOpen && (
        <Dialog title={title} onClickOnClose={closeDialog}>
          <Typography marginBottom={20}>{description}</Typography>
          <Button mr={20} variant="primary" onClick={handleConfirm}>
            {confirmText}
          </Button>
          <Button variant="primaryOutlined" onClick={closeDialog}>
            {cancelText}
          </Button>
        </Dialog>
      )}
    </React.Fragment>
  );
}

ConfirmationDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.func.isRequired,
};

ConfirmationDialog.defaultProps = {
  title: 'Warning!',
  description: 'Are you sure you want to continue?',
  confirmText: 'Continue',
  cancelText: 'Cancel',
};
