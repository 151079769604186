import React, { lazy } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

const DocumentsListPage = lazy(() =>
  import('./pages/documents-list').then(module => ({
    default: module.DocumentsListPage,
  })),
);
const FavoritesListPage = lazy(() =>
  import('./pages/favorites-list').then(module => ({
    default: module.FavoritesListPage,
  })),
);
const SharedWithMeListPage = lazy(() =>
  import('./pages/shared-with-me').then(module => ({
    default: module.SharedWithMeListPage,
  })),
);
const SharedByMeListPage = lazy(() =>
  import('./pages/shared-by-me').then(module => ({
    default: module.SharedByMeListPage,
  })),
);
const CompanyFilesListPage = lazy(() =>
  import('./pages/companies-list').then(module => ({
    default: module.CompanyListPage,
  })),
);

const DirectoryPage = lazy(() =>
  import('./pages/directory').then(module => ({
    default: module.DirectoryPage,
  })),
);

export const DocumentsPages = props => {
  // eslint-disable-next-line react/prop-types
  const { match } = props;
  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/files`}
        component={routeProps => (
          <DocumentsListPage {...props} {...routeProps} />
        )}
      />
      <Route
        // exact
        path={`${match.url}/files/:directoryName`}
        component={routeProps => <DirectoryPage {...props} {...routeProps} />}
      />
      <Route
        exact
        path={`${match.url}/starred`}
        component={routeProps => (
          <FavoritesListPage {...props} {...routeProps} />
        )}
      />
      <Route
        // exact
        path={`${match.url}/starred/:directoryName`}
        component={routeProps => <DirectoryPage {...props} {...routeProps} />}
      />
      <Route
        exact
        path={`${match.url}/shared-with-me`}
        component={routeProps => (
          <SharedWithMeListPage {...props} {...routeProps} />
        )}
      />
      <Route
        // exact
        path={`${match.url}/shared-with-me/:directoryName`}
        component={routeProps => <DirectoryPage {...props} {...routeProps} />}
      />
      <Route
        exact
        path={`${match.url}/shared-by-me`}
        component={routeProps => (
          <SharedByMeListPage {...props} {...routeProps} />
        )}
      />
      <Route
        // exact
        path={`${match.url}/shared-by-me/:directoryName`}
        component={routeProps => <DirectoryPage {...props} {...routeProps} />}
      />
      <Route
        exact
        path={`${match.url}/company-files`}
        component={routeProps => (
          <CompanyFilesListPage {...props} {...routeProps} />
        )}
      />
      <Route
        // exact
        path={`${match.url}/company-files/:directoryName`}
        component={routeProps => <DirectoryPage {...props} {...routeProps} />}
      />
      <Redirect to="/404" />
    </Switch>
  );
};

export default React.memo(DocumentsPages);
