import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Box, Text } from 'rebass';
import { format } from 'date-fns';

import EmailNotFound from '@lib/illustrations/email-not-found.svg';
import { Typography } from '../atoms';
import { Avatar } from './avatar';
import { Person } from '../atoms/icons';

// eslint-disable-next-line react/prop-types
export const CommentsList = ({ comments }) => {
  return (
    <React.Fragment>
      {comments.length ? (
        comments.map((comment, index) => (
          <Flex key={Math.random()} mb={20}>
            <Link to={`/hr/employees/${comment.author.username}`}>
              <Avatar src={comment.author.avatar} size={36} mr={10}>
                <Person color="#aaa" />
              </Avatar>
            </Link>
            <Box flex={1}>
              <Flex alignItems="center" width={1 / 1.5}>
                <Link
                  to={`/hr/employees/${comment.author.username}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Typography fontSize={16} fontWeight={500}>
                    {`${comment.author.firstName} ${comment.author.lastName}`}
                  </Typography>
                </Link>
                <Text color="#88909D" fontSize={12} ml={20}>
                  {format(new Date(comment.createdAt), 'dd/MM/yyyy, HH:mm')}
                </Text>
              </Flex>
              <Text color="#88909D" fontSize={14} mt={10}>
                {comment.text}
              </Text>
            </Box>
          </Flex>
        ))
      ) : (
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          px={20}
          pt={20}
          pb={60}
        >
          <Box mb={20}>
            <img src={EmailNotFound} alt="no email found" />
          </Box>
          <Text color="#88909D" fontSize={12}>
            Leave the first comment
          </Text>
        </Flex>
      )}
    </React.Fragment>
  );
};
