import React from 'react';
import { SvgIcon } from '../svg-icon';

export const StarDefault = props => (
  <SvgIcon {...props} viewBox="0 0 20 18">
    <path
      d="M19.4688 6.86292L12.6594 6.27811L10 0L7.34017 6.27816L0.53125 6.86292L5.6965 11.3406L4.14817 18L10 14.4697L15.8514 18L14.3035 11.3407L19.4688 6.86292ZM10.7749 13.1853L10 12.7178L9.22516 13.1852L6.41322 14.8817L7.15755 11.6803L7.36234 10.7995L6.67905 10.2072L4.19495 8.05378L7.46852 7.77262L8.3688 7.69528L8.72134 6.86325L9.99991 3.84539L11.2782 6.86316L11.6307 7.69523L12.5311 7.77258L15.8051 8.05373L13.321 10.2071L12.6378 10.7994L12.8425 11.6801L13.5866 14.8816L10.7749 13.1853Z"
      fill="#0076F4"
    />
  </SvgIcon>
);
