import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export function ReactTablePagination(props) {
  const {
    canNext,
    canPrevious,
    nextText,
    previousText,
    page,
    defaultPageSize,
    onPageChange,
    pages,
    noDataText,
  } = props;

  if (pages < 2) return '';
  const paginationItemsCount = Math.ceil(pages / defaultPageSize);
  let countOfShownItems = 5;
  if (paginationItemsCount > 30) countOfShownItems = 10;

  if (pages === 0) {
    return <div>{noDataText}</div>;
  }

  const paginateTo = pageNumber => {
    return () => {
      onPageChange(pageNumber);
    };
  };

  const renderFirstPage = () => {
    if (
      paginationItemsCount > countOfShownItems + 1 &&
      page + 1 > pages / 2 &&
      page - countOfShownItems > 1
    ) {
      return (
        <React.Fragment>
          <PageBtn onClick={paginateTo(0)}>{1}</PageBtn>
          ...
        </React.Fragment>
      );
    }
    return '';
  };

  const renderLastPage = () => {
    if (
      paginationItemsCount > countOfShownItems + 2 &&
      page < pages / 2 &&
      page + countOfShownItems < pages
    ) {
      return (
        <React.Fragment>
          ...
          <PageBtn onClick={paginateTo(pages - 1)}>{pages}</PageBtn>
        </React.Fragment>
      );
    }
    return '';
  };

  const renderPages = () => {
    let start = page - Math.floor(countOfShownItems / 2);
    let end = page + Math.floor(countOfShownItems / 2);
    const paginationItems = [];
    if (start < 0) {
      end -= start;
      start = 0;
    }

    if (end >= pages) {
      end = pages - 1;
      start = end - countOfShownItems + 1;
    }

    if (start < 0) {
      start = 0;
    }
    for (let i = start; i <= end; i += 1) {
      paginationItems.push(i);
    }

    return paginationItems.map(el => {
      return (
        <PageBtn
          key={Math.random()}
          isCurrent={el === page}
          onClick={paginateTo(el)}
        >
          {el + 1}
        </PageBtn>
      );
    });
  };

  return (
    <PaginationWrapper>
      <DirectionBtns canDirect={canPrevious} onClick={paginateTo(page - 1)}>
        {previousText}
      </DirectionBtns>
      {renderFirstPage()}
      {renderPages()}
      {renderLastPage()}
      <DirectionBtns canDirect={canNext} onClick={paginateTo(page + 1)}>
        {nextText}
      </DirectionBtns>
    </PaginationWrapper>
  );
}

ReactTablePagination.propTypes = {
  canNext: PropTypes.bool.isRequired,
  canPrevious: PropTypes.bool.isRequired,
  nextText: PropTypes.string.isRequired,
  previousText: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  defaultPageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired,
  noDataText: PropTypes.string.isRequired,
};

const DirectionBtns = styled.button.attrs(({ canDirect }) => ({
  disabled: !canDirect,
}))`
  border-radius: 17px;
  width: inherit;
  height: 34px;
  padding: 0 10px;
  outline: none;
  margin: 0 20px;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  color: ${({ canDirect }) => (canDirect ? '#fff' : '#88909d')};
  background: ${({ canDirect }) => (canDirect ? '#424b68' : 'none')};
  border: ${({ canDirect }) =>
    canDirect ? '1px solid #424b68' : '1px solid transparent'};
  cursor: ${({ canDirect }) => (canDirect ? 'pointer' : 'default')};
`;

const PageBtn = styled.button.attrs(({ isCurrent }) => ({
  disabled: isCurrent,
}))`
  width: 34px;
  height: 34px;
  outline: none;
  border-radius: 50%;
  color: ${({ isCurrent }) => (isCurrent ? '#fff' : '#88909d')};
  background: ${({ isCurrent }) => (isCurrent ? '#424b68' : 'none')};
  border: ${({ isCurrent }) =>
    isCurrent ? '1px solid #424b68' : '1px solid transparent'};
  cursor: ${({ isCurrent }) => (isCurrent ? 'default' : 'pointer')};
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
`;

const PaginationWrapper = styled.div`
  color: #88909d;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 20px;
`;
