import React from 'react';
import { useStore } from 'effector-react';
import styled from 'styled-components';
import { Flex, Text, Card } from 'rebass';

import {
  closeNotificationsPanel,
  $isNotificationsOpened,
} from '@features/common/model/panel-toggle-flow';

// import { notify } from '@lib/notifier';
import { ArrowRight2, Bell, Delete, DoubleCheck } from '@oca/icons';
import { IconButton } from '@oca/ui';

import NoNotifications from '@lib/illustrations/notifications-not-found.svg';

import { firebaseConfigs } from '../models/firebase-configs';

import { NotificationView } from './notification-view';
import {
  getNotifications,
  $notificationList,
  readAlllNotifications,
  deleteAlllNotifications,
} from '../models/notifications-flow';

export const NotificationsPanel = props => {
  React.useEffect(() => {
    firebaseConfigs();
    getNotifications();

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    closeNotificationsPanel();
  };

  const isNotificationsOpened = useStore($isNotificationsOpened);
  const notificationList = useStore($notificationList);

  const node = React.useRef();
  return (
    <Wrapper isOpened={isNotificationsOpened} boxShadow={8} ref={node}>
      <Flex
        color="#0076F4"
        px={20}
        py={23}
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex alignItems="center">
          <Bell />
          <Text fontSize={16} fontWeight={500} ml={10}>
            Notifications
          </Text>
        </Flex>
        <IconButton padding="5px" onClick={() => closeNotificationsPanel()}>
          <ArrowRight2 />
        </IconButton>
      </Flex>
      <ListWrap>
        {notificationList.results && notificationList.results.length ? (
          (notificationList.results || []).map(item => (
            <NotificationView
              key={item.id}
              data={item}
              closeNotificationsPanel={closeNotificationsPanel}
            />
          ))
        ) : (
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            flex={1}
          >
            <img src={NoNotifications} alt="no notifications" />
            <Text fontSize={18} color="#424B68" mt={20}>
              There is no notifications
            </Text>
          </Flex>
        )}
      </ListWrap>
      {notificationList.results && notificationList.results.length > 1 && (
        <Flex
          px={20}
          py={15}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex
            alignItems="center"
            color="#FE2400"
            style={{ cursor: 'pointer' }}
            onClick={deleteAlllNotifications}
          >
            <Delete fontSize={20} />
            <Text ml="5px" lineHeight={1} fontSize={14}>
              Delete all
            </Text>
          </Flex>
          <Flex
            alignItems="center"
            color="#009031"
            style={{ cursor: 'pointer' }}
            onClick={readAlllNotifications}
          >
            <DoubleCheck fontSize={20} />
            <Text ml="5px" lineHeight={1} fontSize={14}>
              Mark all as read
            </Text>
          </Flex>
        </Flex>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Card)`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
  max-width: 340px;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  transform: translateX(${props => (props.isOpened ? 0 : '150%')});
  transition: transform 0.25s ease-out;
  display: flex;
  flex-direction: column;
`;

const ListWrap = styled.div`
  overflow-y: scroll;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
