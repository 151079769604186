import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '@lib/grid';
import { Typography } from '@oca/ui';

export function SectionTitle({ title, action }) {
  return (
    <Row alignItems="center" spacing={1}>
      <Col>
        <Typography variant="h2">{title}</Typography>
      </Col>
      <Col>{action}</Col>
    </Row>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  action: PropTypes.node,
};

SectionTitle.defaultProps = {
  title: null,
  action: null,
};
