export const notificationMsg = (title, action, domain, domainObjId) => {
  let notificationTitle = 'Some Notification From OCA';
  let notificationRoute = '/';

  if (domain.includes('document')) {
    notificationTitle = `${title} will expire soon`;
  } else if (action === 'upcoming') {
    notificationTitle = `${title} is upcoming in ${domain}`;
  } else {
    notificationTitle = `${title} was ${action} in ${domain}`;
  }

  if (domain.includes('document')) {
    notificationRoute = '/employees/me';
  } else if (domain.includes('meeting')) {
    notificationRoute = `/meetings/view/${domainObjId || '/'}`;
  } else if (domain.includes('task')) {
    notificationRoute = `/tasks/${domainObjId || '/'}`;
  } else if (domain.includes('project')) {
    notificationRoute = `/projects/${domainObjId || '/'}`;
  }

  return { notificationTitle, notificationRoute };
};
