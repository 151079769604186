import { fetcher } from '@features/common';

const employeeEndpoint = '/api/v1/employees/';
const employeeDocumentsEndpoint = '/api/v1/employee-documents/';
const employeeEducationsEndpoint = '/api/v1/educations/';
const employeeContactsEndpoint = '/api/v1/contacts/';
const employeePropertiesEndpoint = '/api/v1/employee-properties/';

export function fetchEmployeeList() {
  return fetcher.get(employeeEndpoint);
}

export function fetchSingleEmployee(username) {
  return fetcher.get(`${employeeEndpoint + username}/`);
}

export function createEmployee(data) {
  return fetcher.post(employeeEndpoint, data);
}

export function updateEmployee(data) {
  return fetcher.patch(`${employeeEndpoint + data.id}/`, data);
}

export function removeEmployee(id) {
  return fetcher.delete(`${employeeEndpoint + id}/`);
}

// Contacts
export function createEmployeeContact(contact) {
  return fetcher.post(employeeContactsEndpoint, contact);
}

export function updateEmployeeContact(contact) {
  return fetcher.put(`${employeeContactsEndpoint + contact.id}/`, contact);
}

// Documents
export function getEmployeeDocuments(id) {
  return fetcher.get(employeeDocumentsEndpoint);
}

export function createEmployeeDocument(document) {
  return fetcher.post(employeeDocumentsEndpoint, document);
}

export function updateEmployeeDocument(document) {
  return fetcher.put(`${employeeDocumentsEndpoint + document.id}/`, document);
}

// Educations
export function createEmployeeEducation(education) {
  return fetcher.post(employeeEducationsEndpoint, education);
}

export function updateEmployeeEducation(education) {
  return fetcher.put(
    `${employeeEducationsEndpoint + education.id}/`,
    education,
  );
}

// Properties
export async function fetchStateProperties() {
  const { data: categories } = await fetcher.get(
    '/api/v1/property-categories/',
  );
  const { data: items } = await fetcher.get('/api/v1/state-properties/');

  return { categories, items };
}
export function createEmployeeProperty(property) {
  return fetcher.post(employeePropertiesEndpoint, property);
}

export function updateEmployeeProperty(property) {
  return fetcher.put(`${employeePropertiesEndpoint + property.id}/`, property);
}
