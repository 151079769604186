/**
 * Getting first letters from passed string
 * @param {string} string Remaining string
 * @param {string} [splitter = ' '] Split symbol
 * @returns {string[]} Array of first letters
 */
export function getFirstLetters(string, splitter = ' ') {
  return string.split(splitter).map(word => word.charAt(0));
}

/**
 * Returns full name from 'user' object
 * @param {Object} data Data object
 * @param {{ firstKey: string, middleKey: string, lastKey: string }}
 * @returns {string} Concatenated full name string
 */
export function getFullName(
  data,
  {
    firstKey = 'firstName',
    middleKey = 'middleName',
    lastKey = 'lastName',
  } = {},
) {
  const firstName = data[firstKey] || '';
  const middleName = data[middleKey] || '';
  const lastName = data[lastKey] || '';

  return [firstName, middleName, lastName].filter(Boolean).join(' ');
}

/**
 *
 * @param {{ value: string | number, list: Array, propGetter: Function, message: string | ReactNode }}
 */
export function unique({ value, list, propGetter, message }) {
  const hasMatch = list.some(item => propGetter(item) === value);

  return hasMatch ? message : undefined;
}

/**
 * Looks for a property in a given object. Returns boolean.
 * @param {Object} object
 * @param {string} key
 * @returns {string[]} Array of first letters
 */
export function hasOwnProperty(object, key) {
  return Object.prototype.hasOwnProperty.call(object, key);
}

/**
 * Leaves out uniq members of a given array
 * @param {Array} array
 * @returns {any[]} Array of any type
 */
export const uniqArray = array =>
  array.reduce((uniq, item) => {
    return uniq.includes(item) ? [...uniq] : [...uniq, item];
  }, []);

/**
 * Returns a partial copy of an object omitting the keys specified.
 *
 * @param {Array} keys an array of String property names to omit from the new object
 * @param {Object} obj The object to copy from
 * @return {Object} A new object with properties from `keys` not on it.
 * @example
 *
 *      omit(['a', 'd'], {a: 1, b: 2, c: 3, d: 4}); //=> {b: 2, c: 3}
 */
export function omit(keys, obj) {
  if (arguments.length === 1) return _obj => omit(keys, _obj);

  if (obj === null || obj === undefined) {
    return undefined;
  }

  const keysValue = typeof keys === 'string' ? keys.split(',') : keys;

  const willReturn = {};

  for (const key in obj) {
    if (!keysValue.includes(key)) {
      willReturn[key] = obj[key];
    }
  }

  return willReturn;
}

/**
 * Returns a partial copy of an object containing only the keys specified. If
 * the key does not exist, the property is ignored.
 *
 * @param {Array} keys an array of String property names to copy onto a new object
 * @param {Object} obj The object to copy from
 * @return {Object} A new object with only properties from `keys` on it.
 * @example
 *
 *      pick(['a', 'd'], {a: 1, b: 2, c: 3, d: 4}); //=> {a: 1, d: 4}
 *      pick(['a', 'e', 'f'], {a: 1, b: 2, c: 3, d: 4}); //=> {a: 1}
 */
export function pick(keys, obj) {
  if (arguments.length === 1) return _obj => pick(keys, _obj);

  if (obj === null || obj === undefined) {
    return undefined;
  }
  const keysValue = typeof keys === 'string' ? keys.split(',') : keys;

  const willReturn = {};
  let counter = 0;

  while (counter < keysValue.length) {
    if (keysValue[counter] in obj) {
      willReturn[keysValue[counter]] = obj[keysValue[counter]];
    }
    counter += 1;
  }

  return willReturn;
}

/**
 * Returns `true` if the given value is its type's empty value; `false`
 * otherwise.
 *
 * @param {*} x
 * @return {Boolean}
 * @example
 *
 *     isEmpty([1, 2, 3]);   //=> false
 *     isEmpty([]);          //=> true
 *     isEmpty('');          //=> true
 *     isEmpty(null);        //=> false
 *     isEmpty({});          //=> true
 *     isEmpty({length: 0}); //=> false
 */
export function isEmpty(x) {
  if (Number.isFinite(x) || !x) {
    return true;
  }

  if (Array.isArray(x)) {
    return x.length === 0;
  }

  return Object.keys(x).length === 0;
}

/**
 * Checks if the input value is `null` or `undefined`.
 *
 * @param {*} x The value to test.
 * @return {Boolean} `true` if `x` is `undefined` or `null`, otherwise `false`.
 * @example
 *
 *     isNil(null); //=> true
 *     isNil(undefined); //=> true
 *     isNil(0); //=> false
 *     isNil([]); //=> false
 */
export function isNil(x) {
  return x === undefined || x === null;
}

/**
 * Compares two arrays with given comparator
 * @param {Array} left
 * @param {Array} right
 * @param {Function} comparator
 */
export function diffArrays(left, right, comparator = () => true) {
  return left.reduce(
    (acc, item) => (comparator(item, right) ? acc.concat([item]) : acc),
    [],
  );
}

/**
 * Maps over the given object
 * @param {Function} fn
 * @param {Object} obj
 */
export function mapObject(fn, obj) {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: fn(value, key, obj),
    }),
    {},
  );
}

/**
 * Filters given object
 * @param {Function} fn
 * @param {Object} obj
 */
export function filterObject(fn, obj) {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(fn(value, key, obj) ? { [key]: value } : {}),
    }),
    {},
  );
}

/**
 * Composes single-argument functions from right to left. The rightmost
 * function can take multiple arguments as it provides the signature for
 * the resulting composite function.
 *
 * @param {...Function} funcs The functions to compose.
 * @returns {Function} A function obtained by composing the argument functions
 * from right to left. For example, compose(f, g, h) is identical to doing
 * (...args) => f(g(h(...args))).
 */

export function compose(...funcs) {
  if (funcs.length === 0) {
    return arg => arg;
  }

  if (funcs.length === 1) {
    return funcs[0];
  }

  return funcs.reduce((a, b) => (...args) => a(b(...args)));
}

/**
 * Resolves promise in given time(ms)
 * @param {number} ms
 */
export function sleep(ms = 300) {
  return new Promise(res => setTimeout(res, ms));
}

/**
 * Resolves rejected promise with rejection payload or with null if no rejection was happened
 * @param {Promise} promise
 */
export function resolveRejectedPromise(promise) {
  return new Promise(resolve =>
    promise
      .catch(err => {
        resolve(err);
        return Promise.reject(err);
      })
      .finally(() => resolve(null)),
  );
}

/**
 *
 * @param {import('axios').AxiosError} error Axios error
 */
export function getAxiosErrorResponse(error) {
  if (error.response) {
    return error.response;
  }
  if (error.request) {
    return { data: 'No response from server' };
  }
  return error.message;
}
