import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box as BaseBox } from 'rebass';
import { useDropzone } from 'react-dropzone';

import { Avatar } from '@oca/ui';
import { Person, Upload } from '@oca/icons';
import { createTransition } from '@oca/ui/theme';

import { useFileUploading } from '../hooks/use-file-uploading';

/**
 * @param {{ src: string, size: number, onUpload: Function }}
 */
export function AvatarUploader({ src, size, onUploaded }) {
  const { result, onUpload } = useFileUploading();
  const { getInputProps, getRootProps } = useDropzone({
    accept: 'image/*',
    onDrop: accepted => onUpload(accepted),
    noDrag: true,
    multiple: false,
  });
  const [preview, setPreview] = React.useState(null);

  React.useEffect(() => {
    if (result.length > 0) {
      const { file, fileUrl } = result[0];

      setPreview(fileUrl);

      if (onUploaded) {
        onUploaded(file);
      }
    }
  }, [onUploaded, result]);

  return (
    <Box {...getRootProps()} color="primary">
      <input {...getInputProps()} />
      <Overlay>
        <Upload color="white" />
      </Overlay>
      <Avatar src={preview || src} size={size}>
        <Person />
      </Avatar>
    </Box>
  );
}

AvatarUploader.propTypes = {
  // eslint-disable-next-line react/require-default-props
  src: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  onUploaded: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  size: PropTypes.number,
};

const Overlay = styled(BaseBox)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: ${createTransition(['opacity'])};
`;

const Box = styled(BaseBox)`
  position: relative;
  cursor: pointer;
  outline: none;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 0 0px currentColor;
  &:focus {
    box-shadow: 0 0 0 2px currentColor;
  }
  &:hover ${Overlay} {
    opacity: 1;
  }
  transition: ${createTransition(['box-shadow'])};
`;
