import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { createComponent } from 'effector-react';

import { history } from '@lib/routing';
import { createStoreObject } from 'effector';
import { Typography } from '@oca/ui';
import { Trans } from '@lingui/macro';
import {
  $session,
  $sessionFetching,
  sessionFetchRequesting,
} from '../model/auth';
import { $accessToken } from '../model/token';
import { SplashScreen } from '../molecules';

/**
 * Fetches user information if token exists and prevent children to render
 */
const $store = createStoreObject({
  accessToken: $accessToken,
  session: $session,
  fetching: $sessionFetching,
});

export const AccountLoader = createComponent($store, ({ children }, state) => (
  <AccountLoaderView {...state}>{children}</AccountLoaderView>
));

function AccountLoaderView({ children, accessToken, fetching, session }) {
  const { isFail, isLoading } = fetching;
  useEffect(() => {
    // Try to fetch user session, redirect to login otherwise
    if (accessToken && !session && !isLoading && !isFail) {
      sessionFetchRequesting(accessToken);
    } else if ((!accessToken && !session) || isFail) {
      history.replace('/login', { referrer: history.location.pathname });
    }
  }, [accessToken, isFail, isLoading, session]);

  if (accessToken && isLoading) {
    return (
      <SplashScreen>
        <Typography variant="h1">
          <Trans>Olympic Council of Asia</Trans>
        </Typography>
      </SplashScreen>
    );
  }

  return children;
}

AccountLoaderView.propTypes = {
  children: PropTypes.node,
  accessToken: PropTypes.string,
  session: PropTypes.objectOf(PropTypes.any),
};
