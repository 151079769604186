import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';
import { Trans } from '@lingui/macro';
import { useStore } from 'effector-react';

import { Avatar, Radio, Label, Typography, Checkbox } from '@oca/ui';
import { DefaultAvatar } from '@oca/icons';

import {
  TextFieldWrapper,
  SelectWrapper,
  DayPickerInputWrapper,
} from '@lib/final-form-oca-ui';
import { Row, Col } from '@lib/grid';

import { $departmentList, $employeeList } from '@features/common';

import { format } from 'date-fns';
import { unique } from '@lib/help-fns';
import { AvatarUploader } from '@features/common/organisms/avatar-uploader';
import { MARITAL_STATUSES, GENDERS, SHIRT_SIZES } from '../model/static';

/**
 *
 * @param {{ initialValues: import('../model/profile').Employee }}
 */
export function FormBasic({
  initialValues,
  maritalStatuses,
  genders,
  shirtSizes,
}) {
  const departments = useStore($departmentList);
  const employeeList = useStore($employeeList);

  return (
    <Row flexDirection="column">
      <Col>
        <Typography variant="h1" paragraph>
          Basic information
        </Typography>
        <Field
          name="avatar"
          render={({ input }) => {
            return (
              <Row alignItems="center" mb={3}>
                <AvatarUploader
                  size={96}
                  src={input.value}
                  onUploaded={input.onChange}
                />
              </Row>
            );
          }}
        />
      </Col>
      <Col>
        <Label>Type</Label>
        <Row>
          <Col>
            <Field
              name="isResident"
              render={({ input }) => (
                <Radio
                  checked={input.value}
                  value="resident"
                  onChange={event => input.onChange(event.target.checked)}
                  label={<Trans>Resident</Trans>}
                />
              )}
            />
          </Col>
          <Col>
            <Field
              name="isResident"
              render={({ input }) => (
                <Radio
                  checked={input.value === false}
                  value="non_resident"
                  onChange={event => input.onChange(!event.target.checked)}
                  label={<Trans>Non Resident</Trans>}
                />
              )}
            />
          </Col>
        </Row>
      </Col>
      <Row spacing="10px">
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="username"
            component={TextFieldWrapper}
            label={<Trans>Username</Trans>}
            validate={val => {
              return initialValues.username !== val
                ? unique({
                    list: employeeList,
                    value: val,
                    propGetter: ({ username }) => username,
                    message: <Trans>Username is already exists</Trans>,
                  })
                : undefined;
            }}
            required
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="rfidCode"
            component={TextFieldWrapper}
            label={<Trans>RFID</Trans>}
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="birthday"
            label={<Trans>Birthday</Trans>}
            parse={val => (val ? format(val, 'yyyy-MM-dd') : '')}
            format={val => (val ? new Date(val) : null)}
            component={DayPickerInputWrapper}
            required
          />
        </Col>
      </Row>
      <Row spacing="10px">
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="firstName"
            component={TextFieldWrapper}
            label={<Trans>First name</Trans>}
            required
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="middleName"
            label={<Trans>Middle name</Trans>}
            component={TextFieldWrapper}
            required
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="lastName"
            component={TextFieldWrapper}
            label={<Trans>Last name</Trans>}
            required
          />
        </Col>
      </Row>
      <Row spacing="10px">
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="gender"
            component={SelectWrapper}
            label={<Trans>Gender</Trans>}
            parse={val => (val ? val.value : '')}
            format={val =>
              val ? genders.find(({ value }) => val === value) : ''
            }
            options={genders}
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="maritalStatus"
            component={SelectWrapper}
            parse={val => (val ? val.value : '')}
            format={val =>
              val ? maritalStatuses.find(({ value }) => val === value) : ''
            }
            label={<Trans>Marital status</Trans>}
            options={maritalStatuses}
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="nationality"
            component={TextFieldWrapper}
            label={<Trans>Nationality</Trans>}
          />
        </Col>
      </Row>
      <Row spacing="10px">
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="department"
            component={SelectWrapper}
            label={<Trans>Department</Trans>}
            options={departments}
            parse={val => (val ? val.id : '')}
            format={val =>
              val ? departments.find(({ id }) => (val.id || val) === id) : ''
            }
            required
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="jobTitle"
            component={TextFieldWrapper}
            label={<Trans>Job title</Trans>}
            required
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="shirtSize"
            component={SelectWrapper}
            parse={val => (val ? val.value : '')}
            format={val =>
              val ? shirtSizes.find(({ value }) => val === value) : ''
            }
            label={<Trans>Shirt size</Trans>}
            options={shirtSizes}
          />
        </Col>
      </Row>
      <Row mb={3}>
        <Field
          name="position"
          render={({ input }) => {
            return (
              <Checkbox
                onChange={e =>
                  input.onChange(
                    input.value === 'manager' ? 'expert' : 'manager',
                  )
                }
                value="manager"
                checked={input.value === 'manager'}
                label={<Trans>Set user as department manager</Trans>}
              />
            );
          }}
        />
      </Row>
      <Row spacing="10px">
        <Col width={[1 / 3, 1 / 4]}>
          <Label>Employment status</Label>
          <Field
            type="radio"
            name="employmentStatus"
            value="full_time"
            render={({ input }) => (
              <Radio {...input} label={<Trans>Full time</Trans>} />
            )}
          />
          <Field
            type="radio"
            name="employmentStatus"
            value="part_time"
            render={({ input }) => (
              <Radio {...input} label={<Trans>Part time</Trans>} />
            )}
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="hiredDate"
            label={<Trans>Hired date</Trans>}
            parse={val => (val ? format(val, 'yyyy-MM-dd') : '')}
            format={val => (val ? new Date(val) : null)}
            component={DayPickerInputWrapper}
          />
        </Col>
      </Row>
    </Row>
  );
}

FormBasic.propTypes = {
  initialValues: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  maritalStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  genders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  shirtSizes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
};

FormBasic.defaultProps = {
  maritalStatuses: [
    { value: MARITAL_STATUSES.MARRIED, label: <Trans>Married</Trans> },
    { value: MARITAL_STATUSES.SINGLE, label: <Trans>Single</Trans> },
  ],
  genders: [
    { value: GENDERS.MALE, label: <Trans>Male</Trans> },
    { value: GENDERS.FEMALE, label: <Trans>Female</Trans> },
  ],
  shirtSizes: Object.values(SHIRT_SIZES).map(value => ({
    value,
    label: value,
  })),
};
