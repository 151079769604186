import React from 'react';
import PropTypes from 'prop-types';

export const ConditionalList = ({ list, renderExists, renderEmpty }) => (
  <>
    {list && list.filter(Boolean).length > 0
      ? renderExists(list)
      : renderEmpty()}
  </>
);

ConditionalList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  list: PropTypes.array.isRequired,
  renderExists: PropTypes.func.isRequired,
  renderEmpty: PropTypes.func,
};

ConditionalList.defaultProps = {
  renderEmpty: () => <p>Not Found</p>,
};
