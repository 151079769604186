import React from 'react';
import PropTypes from 'prop-types';

import { Box } from 'rebass';

/**
 * @typedef {Object} ListProps
 * @property {Array} items
 * @property {Function} renderItem
 * @property {Function} renderEmpty
 */

export const List = React.forwardRef(
  /**
   *
   * @param {ListProps} props
   * @param {React.Ref} ref
   */
  function List(props, ref) {
    const { items, renderEmpty, renderItem, ...rest } = props;

    return (
      <Box as="ul" py={2} px={0} m={0} {...rest} ref={ref}>
        {items && items.length > 0 && items.map(item => renderItem(item))}
        {!items || (items.length === 0 && renderEmpty())}
      </Box>
    );
  },
);

List.propTypes = {
  ...Box.propTypes,
  /**
   * Data array each element of which will be passed to render function
   */
  items: PropTypes.arrayOf(PropTypes.any),
  /**
   * Render function that will be called if there is no data
   */
  renderEmpty: PropTypes.func,
  /**
   * Render function for each item in passed data list
   */
  renderItem: PropTypes.func,
};

List.defaultProps = {
  items: [],
  renderEmpty: () => null,
  renderItem: i => i,
};
