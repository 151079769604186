import { lazy } from 'react';

const AttendanceMainPage = lazy(() =>
  import('./pages').then(module => ({
    default: module.AttendanceMainPage,
  })),
);

export const routes = [
  {
    path: '/hr/attendance',
    component: AttendanceMainPage,
    exact: true,
    navigation: {
      name: 'Attendance',
      shortName: 'AT',
    },
  },
];
