import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import './data-table.css';
import { ReactTablePagination } from '@lib/react-table-pagination';

/**
 *
 * @param {import('react-table').TableProps} props
 */
export function DataTable(props) {
  return <ReactTable {...props} />;
}

DataTable.propTypes = { ...ReactTable.propTypes };

DataTable.defaultProps = {
  PaginationComponent: ReactTablePagination,
};
