import React from 'react';
import { SvgIcon } from '../svg-icon';

export const AddDocument = props => (
  <SvgIcon {...props} width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 3H8.5C7.11925 3 6 4.11925 6 5.5V26.5C6 27.8808 7.11925 29 8.5 29H23.5C24.8808 29 26 27.8808 26 26.5V11L18 3ZM17 12V5L24 12H17ZM12 18H14V21H17V23H14V26H12V23H9V21H12V18Z"
      fill="#0076F4"
    />
  </SvgIcon>
);
