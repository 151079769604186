import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Svg = props => (
  <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#F7B84E"
    />
    <path
      d="M4.5382 14.7495C4.6762 13.5862 6.4327 13.377 7.2607 14.013C7.66871 14.3422 7.2367 14.8642 6.8767 14.6002C6.4327 14.3182 5.42545 14.1862 5.29945 14.8095C5.13745 15.7935 7.74596 15.2302 7.70471 16.8247C7.66271 18.348 5.45545 18.384 4.62745 17.7C4.42945 17.5387 4.43545 17.2755 4.54345 17.1127C4.69945 16.9575 4.87345 16.9035 5.07745 17.0707C5.5687 17.4067 6.8347 17.6587 6.92395 16.806C6.8467 15.9195 4.3522 16.4535 4.5382 14.7495Z"
      fill="white"
    />
    <path
      d="M9.80245 17.7307L8.2192 14.4142C7.9852 13.9402 8.6932 13.551 8.95645 14.0482L9.49045 15.2535L10.1797 16.8367L10.857 15.2535L11.391 14.0482C11.631 13.593 12.3262 13.8802 12.123 14.3782L10.5937 17.7307C10.4557 18.1087 10.0305 18.198 9.80245 17.7307Z"
      fill="white"
    />
    <path
      d="M16.2075 17.5387C15.7815 17.8919 15.2594 18.0427 14.7322 18.0427C13.4722 18.0427 12.5789 17.3227 12.5789 15.8954C12.5789 14.6842 13.521 13.7429 14.7855 13.7429C15.2595 13.7429 15.7807 13.9049 16.1527 14.2709C16.518 14.6302 15.9907 15.1642 15.6314 14.8469C15.4094 14.6302 15.0975 14.4689 14.7855 14.4689C14.0602 14.4689 13.3589 15.0502 13.3589 15.8954C13.3589 16.7834 13.9462 17.3227 14.7322 17.3227C15.0974 17.3227 15.4094 17.2147 15.6314 17.0527V16.3162H14.7322C14.1982 16.3162 14.2522 15.5834 14.7322 15.5834H15.9315C16.1535 15.5834 16.3567 15.7522 16.3567 15.9382V17.2154C16.3575 17.3227 16.3095 17.4239 16.2075 17.5387Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
