import React from 'react';
import { Meeting } from '@oca/icons';

const Meetings = React.lazy(() =>
  import('./pages').then(module => ({
    default: module.Meetings,
  })),
);

const CreateMeeting = React.lazy(() =>
  import('./pages/create').then(module => ({
    default: module.CreateMeeting,
  })),
);

const MeetingsList = React.lazy(() =>
  import('./pages/list').then(module => ({
    default: module.MeetingsList,
  })),
);

const MeetingView = React.lazy(() =>
  import('./pages/view').then(module => ({
    default: module.MeetingView,
  })),
);

const MeetingEdit = React.lazy(() =>
  import('./pages/edit').then(module => ({
    default: module.MeetingEdit,
  })),
);

export const routes = [
  {
    path: '/meetings/create',
    component: CreateMeeting,
    name: 'Create Meeting',
    exact: true,
  },
  {
    path: '/meetings/:id/edit',
    component: MeetingEdit,
    name: 'Edit Meeting',
    exact: true,
  },
  {
    path: '/meetings',
    component: Meetings,
    navigation: {
      name: 'Meetings',
      icon: <Meeting />,
      onlyTopLevelLink: true,
    },
    routes: [
      {
        path: '/meetings',
        component: MeetingsList,
        name: 'Meetings',
        exact: true,
      },
      {
        path: '/meetings/:date',
        component: MeetingsList,
        name: 'Meetings',
        exact: true,
      },
      {
        path: '/meetings/view/:id',
        component: MeetingView,
        name: 'Meeting View',
        exact: true,
      },
    ],
  },
];
