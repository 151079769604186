import React from 'react';
import PropTypes from 'prop-types';

import { Trans } from '@lingui/macro';
import { Box, Card, Text } from 'rebass';
import styled, { createGlobalStyle } from 'styled-components';
import {
  display,
  flexDirection,
  justifyContent,
  alignItems,
  height,
  overflow,
} from 'styled-system';
import { useStore } from 'effector-react';
import { Redirect } from 'react-router-dom';

import { CenterContentTemplate, Typography } from '@oca/ui';
import { OCALogo } from '@oca/icons';

import { $isAuthenticated } from '../../model/auth';
import { LoginForm } from '../../organisms';
import cardImage from './hero.jpg';

export function LoginPage({ location }) {
  const isAuthenticated = useStore($isAuthenticated);

  if (isAuthenticated) {
    const referrer = location.state ? location.state.referrer : '/';

    return <Redirect to={referrer} />;
  }

  return (
    <CenterContentTemplate>
      <GlobalStyles />
      <FlexCard
        width="800px"
        boxShadow={1}
        bg="white"
        borderRadius={8}
        overflow="hidden"
      >
        <Box width={2 / 5} px={4} py={5}>
          <Typography variant="h1" fontWeight={400} paragraph>
            <Text as="span" color="secondary">
              OCA
            </Text>{' '}
            Digital Platform
          </Typography>
          <Typography color="textSecondary" paragraph>
            <Trans>Welcome back, please login to your account</Trans>
          </Typography>
          <LoginForm />
        </Box>
        <HeroBlock width={3 / 5}>
          <FlexCard
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            backgroundColor="rgba(0, 0, 0, 0.5)"
            height="100%"
          >
            <OCALogo fontSize={250} color="white" />
            <Typography variant="h1" fontWeight={300} color="white">
              Olympic Council of Asia
            </Typography>
          </FlexCard>
        </HeroBlock>
      </FlexCard>
    </CenterContentTemplate>
  );
}

LoginPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.any,
  }).isRequired,
};

const GlobalStyles = createGlobalStyle`
  #root { 
    background: linear-gradient(
      45deg,
      rgba(0, 117, 244, 1) 0%,
      rgba(0, 179, 255, 1) 100%
    )
  }
`;

const FlexCard = styled(Card)`
  ${display};
  ${flexDirection}
  ${justifyContent};
  ${alignItems};
  ${height};
  ${overflow};
`;

FlexCard.defaultProps = {
  display: 'flex',
};

// eslint-disable-next-line react/prop-types
const HeroBlock = ({ width, children }) => (
  <FlexCard
    display="block"
    width={width}
    backgroundImage={`url(${cardImage})`}
    backgroundRepeat="no-repeat"
    backgroundSize="cover"
  >
    {children}
  </FlexCard>
);
