import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Switch = React.forwardRef(
  ({ checked, onClick, disabled, name, tabIndex }, ref) => {
    return (
      <Label disabled={disabled}>
        <Input
          type="checkbox"
          onClick={onClick}
          checked={checked}
          disabled={disabled}
          ref={ref}
          name={name}
          tabIndex={tabIndex}
        />
        <Span checked={checked} disabled={disabled} />
      </Label>
    );
  },
);

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Switch.defaultProps = {
  disabled: false,
  name: '',
  tabIndex: 0,
};

const Label = styled.label`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;
const Span = styled.span`
  position: absolute;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 36px;
  background-color: ${({ checked }) => (checked ? '#0075f4' : '#888A97')};
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
  ::before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    bottom: 1px;
    background-color: #fff;
    border-radius: 50%;
    transform: ${({ checked }) =>
      checked ? 'translateX(13px)' : 'translateX(1px)'};
    -webkit-transition: transform 0.5s ease-out;
    -moz-transition: transform 0.5s ease-out;
    -o-transition: transform 0.5s ease-out;
    transition: transform 0.5s ease-out;
  }
`;
