import React from 'react';
import { FileEdit } from '@oca/icons';
import { MainPage } from './pages/main';
import { routes as assetsRoutes } from './features/assets';
import { routes as attendanceRoutes } from './features/attendance';
import { routes as leavesRoutes } from './features/leaves';
import { routes as employeeRoutes } from './features/employee';

export const routes = [
  {
    path: '/hr',
    component: MainPage,
    routes: [
      ...employeeRoutes,
      ...attendanceRoutes,
      ...leavesRoutes,
      ...assetsRoutes,
    ],
    navigation: {
      icon: <FileEdit />,
      name: 'HR',
    },
  },
];
