import { fetcher } from './lib/fetcher';

export function loginUser(credentials) {
  return fetcher.post('/api/v1/login/', credentials);
}

export function logoutUser() {
  return fetcher.post('/api/v1/logout/', {});
}

export function fetchUserInfo(token) {
  return fetcher.get('/api/v1/whoami/', {
    headers: { Authorization: `Token ${token}` },
  });
}

export function fetchDepartmentList() {
  return fetcher.get('/api/v1/departments/');
}

/**
 *
 * @param {FormData} file
 * @param {import('axios').AxiosRequestConfig} config
 */
export function uploadFile(file, config) {
  return fetcher.post('/api/v1/upload-file/', file, {
    headers: { 'Content-Type': 'multipart/form-data' },
    ...config,
  });
}

export function fetchEmployeeList() {
  return fetcher.get('/api/v1/employees/');
}

export function fetchMeetingList() {
  return fetcher.get('/api/v1/meetings/');
}

export function fetchProjectList(params) {
  return fetcher.get('/api/v1/projects/', { params });
}

// Sendging firebase token to server
export function employeeDeviceToken(token) {
  return fetcher.post('/api/v1/employee-device-token/', {
    device_type: 'desktop',
    token,
  });
}
