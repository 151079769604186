export const DOCUMENTS = {
  PASSPORT: 'passport',
  CIVIL_ID: 'civil_id',
  VISA: 'visa',
  WORK_PERM: 'work_perm',
  CONTRACT: 'contract',
  NDA: 'nda',
};

export const MARITAL_STATUSES = {
  MARRIED: 'married',
  SINGLE: 'single',
};

export const GENDERS = {
  MALE: 'male',
  FEMALE: 'female',
};

export const SHIRT_SIZES = {
  XS: 'XS',
  S: 'S',
  M: 'M',
  L: 'L',
  XL: 'XL',
  XXL: 'XXL',
};

export const EDUCATION_LEVELS = {
  MASTER: 'master',
  BACHELOR: 'bachelor',
  CERTIFICATE: 'certificate',
};

export const EMPLOYEE_LIST_ENTITIES = [
  'contacts',
  'documents',
  'contracts',
  'properties',
  'educations',
];

export function createContactModel() {
  return {
    relationship: null,
    mobilePhone: null,
    homePhone: null,
    address: null,
  };
}

/**
 *
 * @param {'passport'|'civil_id'|'visa'|'work_perm'} type Document type
 * @param {string} date
 */
export function createDocumentModel(type, date) {
  return {
    docType: type || null,
    expDate: date || null,
    files: [],
  };
}

/**
 *
 * @param {'master'|'bachelor'|'certificate'} degree
 */
export function createEducationModel(degree) {
  return {
    degree: degree || null,
    institution: null,
    files: [],
  };
}

export function createPropertyModel() {
  return {
    stateProperty: null,
    description: null,
    returnDate: null,
    assignDate: null,
    files: [],
  };
}
