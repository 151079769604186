import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TooltipTrigger from 'react-popper-tooltip';
import Popper from 'popper.js';
import { boxShadow } from 'styled-system';
import { Box } from 'rebass';

export function Menu(props) {
  const { children, anchor, placement: placementProp } = props;

  return (
    <TooltipTrigger
      trigger="click"
      placement={placementProp}
      tooltip={({ getTooltipProps, placement, tooltipRef }) => (
        <MenuContainer
          {...getTooltipProps({ ref: tooltipRef })}
          data-placement={placement}
        >
          {children}
        </MenuContainer>
      )}
    >
      {({ getTriggerProps, triggerRef }) => (
        <span {...getTriggerProps({ ref: triggerRef })}>{anchor}</span>
      )}
    </TooltipTrigger>
  );
}

Menu.propTypes = {
  anchor: PropTypes.node.isRequired,
  children: PropTypes.node,
  placement: PropTypes.oneOf(Popper.placements),
};

Menu.defaultProps = {
  children: null,
  placement: 'bottom',
};

const MenuItem = React.forwardRef((props, ref) => {
  const { children, icon, onClick } = props;

  return (
    <MenuItemContainer ref={ref} onClick={onClick}>
      {icon && <MenuItemIcon>{icon}</MenuItemIcon>}
      {children}
    </MenuItemContainer>
  );
});

MenuItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  icon: PropTypes.node,
};

MenuItem.defaultProps = {
  children: null,
};

const MenuContainer = styled(Box)`
  z-index: 3;
  border-radius: 4px;
  overflow: hidden;
  transition: opacity 0.2s;
  ${boxShadow};
`;

MenuContainer.defaultProps = {
  boxShadow: 1,
  bg: 'white',
};

const MenuItemContainer = styled(Box)`
  display: flex;
  align-items: center;
  width: auto;
  background-color: transparent;
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 158, 255, 0.125);
  }
`;

MenuItemContainer.defaultProps = {
  color: 'text',
  px: 3,
  py: 10,
};

const MenuItemIcon = styled.div`
  margin-right: 12px;
  & svg {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.textSecondary};
    vertical-align: middle;
  }
`;

Menu.Item = MenuItem;
