import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Trans } from '@lingui/macro';
import { TextFieldWrapper } from '@lib/final-form-oca-ui';

export function FieldsetContact({ name }) {
  return (
    <React.Fragment>
      <Field
        name={`${name}.relationship`}
        component={TextFieldWrapper}
        label={<Trans>Relationship</Trans>}
        helperText={<Trans>Your close relative or friend</Trans>}
        spaceAfter
        required
      />
      <Field
        name={`${name}.mobilePhone`}
        component={TextFieldWrapper}
        label={<Trans>Mobile phone</Trans>}
        helperText=" "
        spaceAfter
        required
      />
      <Field
        name={`${name}.homePhone`}
        component={TextFieldWrapper}
        label={<Trans>Home phone</Trans>}
        helperText=" "
        spaceAfter
      />
      <Field
        name={`${name}.address`}
        component={TextFieldWrapper}
        label={<Trans>Address</Trans>}
        helperText=" "
        spaceAfter
      />
    </React.Fragment>
  );
}

FieldsetContact.propTypes = {
  name: PropTypes.string.isRequired,
};
