import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';
import { Redirect } from 'react-router-dom';

import { Progress, ContentBlock } from '@oca/ui';
import { CommonPageTemplate } from '@features/common';
import { useStore } from 'effector-react';

import { pageMounted, $fetching } from '../model/root';

export function MainPage({ route, match }) {
  const { isDone } = useStore($fetching);

  useEffect(() => {
    pageMounted();
  }, []);

  const firstPage =
    route.routes && route.routes.length > 1 ? route.routes[0] : null;
  const redirectTo = firstPage ? firstPage.path : '/';

  if (!isDone) {
    return <LoadingScreen />;
  }

  return (
    <React.Fragment>
      {match.isExact && <Redirect to={redirectTo} />}
      <React.Suspense fallback={<LoadingScreen />}>
        {renderRoutes(route.routes)}
      </React.Suspense>
    </React.Fragment>
  );
}

MainPage.propTypes = {
  route: PropTypes.shape({
    routes: PropTypes.array,
  }).isRequired,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
  }).isRequired,
};

function LoadingScreen() {
  return (
    <CommonPageTemplate>
      <ContentBlock width={1} style={{ height: '100vh' }}>
        <Progress />
      </ContentBlock>
    </CommonPageTemplate>
  );
}
