import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';
import { Typography, Label } from '@oca/ui';

import { FileUploader } from './file-uploader';

/**
 *
 * @typedef {import('react-final-form').FieldProps} FieldProps
 * @typedef {import('./file-uploader').FileUploaderProps} FileUploaderProps
 */

/**
 *
 * @param {FieldProps & FileUploaderProps} props
 */
export const FileUploaderField = props => {
  return <Field {...props} component={FileUploaderWrapper} />;
};

/**
 *
 * @param {import('react-final-form').FieldRenderProps & { label: string, helperText: string }}
 */
function FileUploaderWrapper({
  input,
  meta,
  label,
  helperText: helperTextProp,
  ...componentProps
}) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const helperText = showError ? meta.error : helperText;
  const color = showError ? 'error' : 'text';

  return (
    <div>
      {label && <Label color={color}>{label}</Label>}
      <FileUploader error={showError} {...componentProps} {...input} />
      {helperText && (
        <Typography variant="caption" color={color}>
          {helperText}
        </Typography>
      )}
    </div>
  );
}

FileUploaderWrapper.propTypes = {
  ...Field.propTypes,
  // eslint-disable-next-line react/require-default-props
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // eslint-disable-next-line react/require-default-props
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
