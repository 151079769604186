import { EmployeeMainPage } from './pages/main';
import { EmployeeCreatePage } from './pages/create';
import { EmployeeListPage } from './pages/list';
import { EmployeeProfilePage } from './pages/profile';
import { EmployeeEditPage } from './pages/edit';

export const routes = [
  {
    path: '/hr/employees',
    component: EmployeeMainPage,
    navigation: {
      name: 'Employees',
      onlyTopLevelLink: true,
    },
    routes: [
      {
        path: '/hr/employees/create',
        component: EmployeeCreatePage,
        exact: true,
      },
      {
        path: '/hr/employees',
        component: EmployeeListPage,
        exact: true,
      },
      {
        path: '/hr/employees/:username',
        component: EmployeeProfilePage,
        exact: true,
      },
      {
        path: '/hr/employees/:username/edit',
        component: EmployeeEditPage,
        exact: true,
      },
    ],
  },
];
