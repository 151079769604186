/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Row, Col } from '@lib/grid';
import { Avatar, Typography, IconButton, Tooltip } from '@oca/ui';
import { Person, Edit, PersonDelete } from '@oca/icons';
import { Trans } from '@lingui/macro';
import { history } from '@lib/routing';
import { AccessControl } from '@features/common';

/**
 *
 * @param {import('../model/profile').Employee}
 */
export function ProfileInfo({
  avatar,
  fullName,
  department,
  jobTitle,
  mobilePhone,
  email,
  profilePath,
}) {
  return (
    <RelativeRow
      flexWrap="wrap"
      alignItems="center"
      spacing={2}
      marginTop={-2}
      flexDirection="row"
    >
      <Col>
        <Avatar src={avatar}>
          <Person />
        </Avatar>
      </Col>
      <Col>
        <Row spacing={2} alignItems="center">
          <Col>
            <Typography variant="h3" color="primary">
              {fullName}
            </Typography>
          </Col>
          <AccessControl allowed={['Admin & HR:manager']}>
            <Col>
              <Tooltip title={<Trans>Edit</Trans>} placement="top">
                <IconButton
                  padding={2}
                  onClick={() => history.push(`${profilePath}/edit`)}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              {/* TODO: Connect with API to be able to terminate user */}
              {/* <Tooltip title={<Trans>Terminate</Trans>} placement="top">
              <IconButton padding={2} color="error">
                <PersonDelete />
              </IconButton>
            </Tooltip> */}
            </Col>
          </AccessControl>
        </Row>
        <Row spacing={2} marginTop={-12}>
          <Col>
            <Typography>
              {department.name} / {jobTitle || '---'}
            </Typography>
          </Col>
          <Col>
            <Typography>{email}</Typography>
          </Col>
          <Col>
            <Typography>{mobilePhone}</Typography>
          </Col>
        </Row>
      </Col>
    </RelativeRow>
  );
}

ProfileInfo.propTypes = {
  avatar: PropTypes.string,
  fullName: PropTypes.string,
  department: PropTypes.shape({ name: PropTypes.string }),
  jobTitle: PropTypes.string,
  mobilePhone: PropTypes.string,
  email: PropTypes.string,
  profilePath: PropTypes.string.isRequired,
};

const RelativeRow = styled(Row)`
  position: relative;
`;
