import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Pdf = props => (
  <SvgIcon {...props} width="24" height="30" viewBox="0 0 24 30">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#F15642"
    />
    <path
      d="M4.76924 14.2103C4.76924 14.0123 4.92524 13.7963 5.17649 13.7963H6.56174C7.34174 13.7963 8.04374 14.3183 8.04374 15.3188C8.04374 16.2668 7.34174 16.7948 6.56174 16.7948H5.56049V17.5868C5.56049 17.8508 5.39249 18 5.17649 18C4.97849 18 4.76924 17.8508 4.76924 17.5868V14.2103ZM5.56049 14.5515V16.0455H6.56174C6.96374 16.0455 7.28174 15.6908 7.28174 15.3188C7.28174 14.8995 6.96374 14.5515 6.56174 14.5515H5.56049Z"
      fill="white"
    />
    <path
      d="M9.21827 18C9.02027 18 8.80427 17.892 8.80427 17.6287V14.2222C8.80427 14.007 9.02027 13.8502 9.21827 13.8502H10.5915C13.332 13.8502 13.272 18 10.6455 18H9.21827ZM9.59627 14.5822V17.2687H10.5915C12.2108 17.2687 12.2828 14.5822 10.5915 14.5822H9.59627Z"
      fill="white"
    />
    <path
      d="M14.244 14.6302V15.5835H15.7732C15.9892 15.5835 16.2052 15.7995 16.2052 16.0087C16.2052 16.2067 15.9892 16.3687 15.7732 16.3687H14.244V17.628C14.244 17.838 14.0947 17.9992 13.8847 17.9992C13.6207 17.9992 13.4595 17.838 13.4595 17.628V14.2215C13.4595 14.0062 13.6215 13.8495 13.8847 13.8495H15.99C16.254 13.8495 16.41 14.0062 16.41 14.2215C16.41 14.4135 16.254 14.6295 15.99 14.6295H14.244V14.6302Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
