import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import styled, { keyframes } from 'styled-components';
import { OCALogoColored } from '@oca/icons';
import { CenterContentTemplate } from '@oca/ui';

export function SplashScreen({ children }) {
  return (
    <CenterContentTemplate>
      <Flex flexDirection="column" alignItems="center">
        <LogoWrapper>
          <OCALogoColored fontSize={192} />
        </LogoWrapper>
        {children}
      </Flex>
    </CenterContentTemplate>
  );
}

SplashScreen.propTypes = {
  children: PropTypes.node,
};

SplashScreen.defaultProps = {
  children: null,
};

const fadeInOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
`;

const LogoWrapper = styled.div`
  animation: ${fadeInOut} 1s alternate infinite;
`;
