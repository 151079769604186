/**
 * Creates FormData object from mapping
 * @param {Object<string, any>} mapping
 * @returns {FormData} FormData object
 */
export function createFormData(mapping = {}) {
  const data = new FormData();

  Object.keys(mapping).forEach(key => {
    data.append(key, mapping[key]);
  });

  return data;
}
