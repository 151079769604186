import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Mxf = props => (
  <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#50BEE8"
    />
    <path
      d="M11.2614 15.2895L12.3054 13.9822C12.6166 13.575 13.2226 14.0962 12.8814 14.4862C12.5221 14.9122 12.1441 15.3742 11.7834 15.8467L13.0074 17.37C13.3366 17.82 12.6774 18.252 12.3534 17.808L11.2501 16.3927L10.1649 17.838C9.85888 18.2752 9.18088 17.784 9.52363 17.376L10.7289 15.8467C10.3509 15.3735 9.98488 14.9122 9.61963 14.4862C9.24163 14.0362 9.94363 13.575 10.2189 13.995L11.2614 15.2895Z"
      fill="white"
    />
    <path
      d="M5.11274 15.3675V17.586C5.11274 17.8508 4.89674 18 4.68149 18C4.48949 18 4.32149 17.8508 4.32149 17.586V14.2103C4.32149 13.8983 4.58624 13.7955 4.68149 13.7955C4.85474 13.7955 4.95674 13.8983 5.05874 14.0123L6.37874 15.7928L7.74524 13.9455C7.94399 13.7003 8.42924 13.7955 8.42924 14.2103V17.586C8.42924 17.8508 8.26124 18 8.06999 18C7.85399 18 7.68599 17.8508 7.68599 17.586V15.3675L6.68999 16.6268C6.47474 16.8915 6.20474 16.8915 6.00674 16.6268L5.11274 15.3675Z"
      fill="white"
    />
    <path
      d="M14.7471 14.6295V15.5827H16.2035C16.4092 15.5827 16.615 15.7987 16.615 16.0087C16.615 16.2067 16.4092 16.368 16.2035 16.368H14.7471V17.6272C14.7471 17.8372 14.605 17.9992 14.405 17.9992C14.1535 17.9992 14 17.8372 14 17.6272V14.2215C14 14.0055 14.1542 13.8495 14.405 13.8495H16.41C16.6614 13.8495 16.81 14.0055 16.81 14.2215C16.81 14.4135 16.6614 14.6287 16.41 14.6287H14.7471V14.6295Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
