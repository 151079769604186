import React from 'react';
import { SvgIcon } from '../svg-icon';

export const UnwrapFolder = props => (
  <SvgIcon
    {...props}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3336 6H4.66663C3.19981 6 2 7.19988 2 8.66669V23.3333C2 24.8001 3.19981 26 4.66663 26H27.3334C28.8002 26 30 24.8001 30 23.3333V11.6667C30 10.1999 28.8002 9 27.3334 9H16.0004L13.3336 6Z"
      fill="#FFCC72"
    />
    <path
      d="M13.0001 16L16.3334 19.3333L19.6667 16L13.0001 16Z"
      fill="#AE6F00"
    />
  </SvgIcon>
);
