import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Zip = props => (
  <SvgIcon {...props} width="24" height="30" viewBox="0 0 24 30">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#84BD5A"
    />
    <path
      d="M6.2175 18C5.8395 18 5.6895 17.6347 5.9055 17.3767L8.04075 14.5402H6.27075C5.7495 14.5402 5.7915 13.8037 6.27075 13.8037H8.688C9.14325 13.8037 9.27525 14.2342 8.952 14.583L6.90675 17.2387H8.856C9.336 17.2387 9.396 18.0007 8.80875 18.0007H6.2175V18Z"
      fill="white"
    />
    <path
      d="M9.93976 14.2103C9.93976 13.7183 10.7318 13.7003 10.7318 14.2103V17.634C10.7318 18.1313 9.93976 18.144 9.93976 17.634V14.2103Z"
      fill="white"
    />
    <path
      d="M11.7945 14.2103C11.7945 14.0123 11.9505 13.7963 12.2025 13.7963H13.5878C14.3678 13.7963 15.0698 14.3183 15.0698 15.3188C15.0698 16.2668 14.3678 16.7948 13.5878 16.7948H12.5865V17.5868C12.5865 17.8508 12.4185 18 12.2025 18C12.0045 18 11.7945 17.8508 11.7945 17.5868V14.2103ZM12.5858 14.5515V16.0455H13.587C13.989 16.0455 14.307 15.6908 14.307 15.3188C14.307 14.8995 13.989 14.5515 13.587 14.5515H12.5858Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
