import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { transparentize, darken } from 'polished';
import { FilledClose as DeleteIcon } from '@oca/icons';
import { createTransition, theme as globalTheme } from '../theme';

export const Chip = React.forwardRef((props, ref) => {
  const {
    avatar,
    clickable: clickableProp,
    color: colorProp,
    label,
    onClick,
    onDelete,
    onKeyDown,
    onKeyUp,
  } = props;
  const chipRef = React.useRef(null);
  const color = colorProp === 'default' ? 'disabled' : colorProp;
  const textColor = getTextColor(colorProp);
  const backgroundColor = globalTheme.colors[color];
  const clickable = clickableProp !== false && onClick ? true : clickableProp;

  const handleDeleteIconClick = event => {
    // Stop the event from bubbling up to the `Chip`
    event.stopPropagation();
    if (onDelete) {
      onDelete(event);
    }
  };
  const handleKeyDown = event => {
    if (onKeyDown) {
      onKeyDown(event);
    }

    // Ignore events from children of `Chip`.
    if (event.currentTarget !== event.target) {
      return;
    }

    // eslint-disable-next-line prefer-destructuring
    const key = event.key;
    if (
      key === ' ' ||
      key === 'Enter' ||
      key === 'Backspace' ||
      key === 'Delete' ||
      key === 'Escape'
    ) {
      event.preventDefault();
    }
  };

  const handleKeyUp = event => {
    if (onKeyUp) {
      onKeyUp(event);
    }

    // Ignore events from children of `Chip`.
    if (event.currentTarget !== event.target) {
      return;
    }

    // eslint-disable-next-line prefer-destructuring
    const key = event.key;
    if (onClick && (key === ' ' || key === 'Enter')) {
      onClick(event);
    } else if (onDelete && (key === 'Backspace' || key === 'Delete')) {
      onDelete(event);
    } else if (key === 'Escape' && chipRef.current) {
      chipRef.current.blur();
    }
  };
  return (
    <ChipWrapper
      role={clickable || onDelete ? 'button' : undefined}
      tabIndex={clickable || onDelete ? 0 : undefined}
      bg={backgroundColor}
      color={textColor}
      clickable={clickable}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      ref={node => {
        chipRef.current = node;
        if (ref) {
          // eslint-disable-next-line no-param-reassign
          ref.current = node;
        }
      }}
    >
      {avatar && React.isValidElement(avatar) && (
        <ChipAvatarWrapper>
          {React.cloneElement(avatar, {
            size: 24,
            background: darken(0.15, backgroundColor),
            color: textColor,
          })}
        </ChipAvatarWrapper>
      )}
      <ChipText px={2}>{label}</ChipText>
      {onDelete && (
        <ChipDelete color={textColor} onClick={handleDeleteIconClick} />
      )}
    </ChipWrapper>
  );
});

Chip.propTypes = {
  avatar: PropTypes.node,
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'error', 'grey']),
  // eslint-disable-next-line react/require-default-props
  clickable: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onDelete: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onKeyDown: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onKeyUp: PropTypes.func,
};

Chip.defaultProps = {
  avatar: null,
  color: 'default',
  label: null,
};

const ChipAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
`;

const ChipWrapper = styled.div`
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  white-space: nowrap;
  color: ${({ color }) => color};
  background-color: ${({ bg }) => bg};
  transition: ${createTransition(['background-color'])};
  outline: none;
  text-decoration: none;
  border: none;
  padding: 0;
  vertical-align: middle;
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
      :hover,
      :focus {
        background-color: ${({ bg }) => darken(0.08, bg)};
      }
    `}
`;

const ChipText = styled.span`
  display: flex;
  padding: 12px;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  cursor: inherit;
`;

const ChipDelete = styled(DeleteIcon)`
  cursor: pointer;
  fill: currentColor;
  margin: 0 4px 0 -8px;
  color: ${({ color }) => transparentize(0.5, color)};
  :hover {
    color: ${({ color }) => transparentize(0.4, color)};
  }
`;

const contrast = {
  default: globalTheme.colors.text,
  primary: globalTheme.colors.white,
  secondary: globalTheme.colors.white,
  error: globalTheme.colors.white,
};

function getTextColor(bg) {
  return contrast[bg] || '#333';
}
