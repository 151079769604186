import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';
import { I18nProvider } from '@lingui/react';
import { renderRoutes } from 'react-router-config';
import { ToastContainer, toast } from 'react-toastify';

import { i18n } from '@lib/i18n';

import { NotificationsPanel } from '@features/notifications';
import { AccountLoader, Account, Navigation } from './features/common';
import { TopBarProgress } from './ui';
import { GlobalStyles } from './global-styles';
import { routes } from './routes';
import { theme } from './ui/theme';

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <I18nProvider i18n={i18n}>
        <React.Fragment>
          <GlobalStyles />
          <Normalize />
          <React.Suspense fallback={<TopBarProgress />}>
            <AppWrapper>
              <ToastContainer
                position={toast.POSITION.TOP_RIGHT}
                autoClose={4000}
              />
              <AccountLoader>
                <Account
                  render={() => (
                    <React.Fragment>
                      <NotificationsPanel />
                      <Navigation routes={routes} />
                    </React.Fragment>
                  )}
                />
                {renderRoutes(routes)}
              </AccountLoader>
            </AppWrapper>
          </React.Suspense>
        </React.Fragment>
      </I18nProvider>
    </ThemeProvider>
  );
}

const AppWrapper = styled.div`
  display: flex;
  height: 100%;
`;
