import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const SvgBase = styled.svg`
  ${props => {
    const { color, fontSize } = props;
    return css`
      width: 1em;
      height: 1em;
      font-size: ${fontSize}px;
      fill: ${color};
      user-select: none;
      display: inline-block;
      transition: fill 400ms;
      outline: none;
    `;
  }}
`;

export const SvgIcon = ({ children, viewBox, color, fontSize, ...other }) => {
  return (
    <SvgBase
      viewBox={viewBox}
      color={color}
      fontSize={fontSize}
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      {...other}
    >
      {children}
    </SvgBase>
  );
};

SvgIcon.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  viewBox: PropTypes.string,
  fontSize: PropTypes.number,
  color: PropTypes.string,
};

SvgIcon.defaultProps = {
  color: 'currentColor',
  fontSize: 24,
  viewBox: '0 0 24 24',
};
