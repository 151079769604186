import React from 'react';
import { SvgIcon } from '../svg-icon';

export const Rar = props => (
  <SvgIcon {...props} width="24" height="30" viewBox="0 0 24 30">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#576D7E"
    />
    <path
      d="M5.298 17.6101C5.298 18.1313 4.5 18.1493 4.5 17.6221V14.2163C4.5 14.0063 4.66275 13.8503 4.86 13.8503H6.45525C7.97775 13.8503 8.28975 15.8896 7.0185 16.3988L7.81575 17.3701C8.12775 17.8321 7.5045 18.2753 7.14375 17.8201L6.2385 16.5248H5.298V17.6101ZM5.298 15.8348H6.45525C7.2345 15.8348 7.28325 14.5756 6.45525 14.5756H5.298V15.8348Z"
      fill="white"
    />
    <path
      d="M13.647 17.6101C13.647 18.1313 12.849 18.1493 12.849 17.6221V14.2163C12.849 14.0063 13.0117 13.8503 13.209 13.8503H14.8042C16.3268 13.8503 16.6388 15.8896 15.3675 16.3988L16.1647 17.3701C16.4767 17.8321 15.8535 18.2753 15.4927 17.8201L14.5875 16.5248H13.647V17.6101ZM13.647 15.8348H14.8042C15.5835 15.8348 15.6322 14.5756 14.8042 14.5756H13.647V15.8348Z"
      fill="white"
    />
    <path
      d="M8.47203 18C8.28003 17.8913 8.16003 17.676 8.28003 17.424L10.0005 14.0603C10.1625 13.7423 10.5945 13.7303 10.7445 14.0603L12.435 17.424C12.681 17.8913 11.955 18.2633 11.7398 17.796L11.475 17.268H9.26253L9.00453 17.796C8.90928 18 8.68728 18.0428 8.47203 18ZM11.0505 16.4768L10.3733 14.9955L9.63603 16.4768H11.0505Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
