import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { List, UploadItem } from '@oca/ui';
import { bytesToSize } from '@lib/filesize';

export const RejectedFileList = React.memo(function RejectedFileList({
  items,
  onDelete,
  validation,
  ...rest
}) {
  return (
    <List
      {...rest}
      items={items}
      renderItem={file => {
        let statusText = <Trans>Rejected</Trans>;
        if (validation.accept && !file.type.startsWith(validation.accept)) {
          statusText = (
            <Trans>This file type {file.type} is not supported</Trans>
          );
        }
        if (validation.maxSize && file.size > validation.maxSize) {
          statusText = (
            <Trans>
              File is {bytesToSize(file.size)}. Max size is{' '}
              {bytesToSize(validation.maxSize)}
            </Trans>
          );
        }

        return (
          <UploadItem
            key={file.name}
            id={file.name}
            title={file.name}
            statusText={statusText}
            onDelete={onDelete}
            error
          />
        );
      }}
    />
  );
});

RejectedFileList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string.isRequired,
      filename: PropTypes.string,
      temp: PropTypes.bool,
    }),
  ).isRequired,
  // eslint-disable-next-line react/require-default-props
  onDelete: PropTypes.func,
  validation: PropTypes.shape({
    size: PropTypes.number,
    accept: PropTypes.string,
  }).isRequired,
};
