import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';

import { BlockHead, Typography, Tabs, WizardContext } from '@oca/ui';
import { Trans } from '@lingui/macro';

export function Header({ steps, title }) {
  const { activeStepIndex, maxVisitedStepIndex, moveToStep } = React.useContext(
    WizardContext,
  );
  const tabs = steps.map((step, index) => ({
    ...step,
    filled: index < maxVisitedStepIndex,
  }));

  return (
    <BlockHead justifyContent="flex-start" mx={-4} marginBottom={4}>
      <Box mr={2}>{title}</Box>
      <Tabs
        tabs={tabs}
        currentValue={activeStepIndex}
        onChange={value =>
          value <= maxVisitedStepIndex ? moveToStep(value) : null
        }
        compact
      />
    </BlockHead>
  );
}

Header.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.func,
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }),
  ).isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

Header.defaultProps = {
  title: (
    <Typography variant="h1">
      <Trans>Add employee</Trans>
    </Typography>
  ),
};
