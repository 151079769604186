import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TabsWrapper = styled.div`
  display: flex;
  justify-content: ${({ stretched }) =>
    stretched ? 'space-around' : 'flex-start'};
  user-select: none;
`;

const TabWrapper = styled.div`
  color: ${({ disabled, filled, isActive, currentValueColor }) =>
    determineColor(disabled, filled, isActive, currentValueColor)};
  padding: 0 25px;
  border-right: 2px solid ${props => props.theme.colors.disabled};
  &:last-child {
    border-right: none;
  }
  height: 29px;
  display: flex;
  flex-grow: ${({ stretched }) => (stretched ? 1 : 0)};
  align-items: center;
  justify-content: center;
  & > svg {
    margin-right: ${({ compact, isActive }) =>
      !compact || (compact && isActive) ? '10px' : '0px'};
  }
  & span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: currentColor;
  }
  cursor: ${({ disabled }) => !disabled && 'pointer'};
`;

const Tab = React.forwardRef(
  (
    {
      currentValue,
      currentValueColor,
      icon: Icon,
      label,
      filled,
      disabled,
      compact,
      value,
      onChange,
      stretched,
    },
    ref,
  ) => {
    const isActive = currentValue === value;
    const handleTabClick = event => onChange(value, event);

    return (
      <TabWrapper
        onClick={!disabled && !isActive ? handleTabClick : undefined}
        isActive={isActive}
        compact={compact}
        disabled={disabled}
        filled={filled}
        currentValueColor={currentValueColor}
        stretched={stretched}
        ref={ref}
      >
        {Icon && <Icon />}
        {(!compact || (compact && isActive)) && <span>{label}</span>}
      </TabWrapper>
    );
  },
);

Tab.propTypes = {
  compact: PropTypes.bool.isRequired,
  icon: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  currentValueColor: PropTypes.string.isRequired,
  filled: PropTypes.bool,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  stretched: PropTypes.bool,
  icon: isValidIcon,
};

Tab.defaultProps = {
  filled: false,
  disabled: false,
  icon: null,
  stretched: false,
};

export function Tabs({
  tabs,
  compact,
  currentValue,
  currentValueColor,
  onChange,
  stretched,
}) {
  return (
    <TabsWrapper stretched={stretched}>
      {tabs.map(({ value, ...tab }, index) => (
        <Tab
          key={value || index}
          currentValue={currentValue}
          currentValueColor={currentValueColor}
          compact={compact}
          onChange={onChange}
          value={value || index}
          stretched={stretched}
          {...tab}
        />
      ))}
    </TabsWrapper>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.func,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  compact: PropTypes.bool,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  currentValueColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  stretched: PropTypes.bool,
};

Tabs.defaultProps = {
  compact: false,
  stretched: false,
  currentValueColor: '#424B68',
};

function determineColor(disabled, filled, active, currentValueColor) {
  if (disabled) return '#D9D9D9';
  if (filled) return '#009EFF';
  if (active) return currentValueColor;
  return '#88909D';
}

// eslint-disable-next-line
function isValidIcon(props, propName) {
  if (
    props.compact === true &&
    (props[propName] === undefined || typeof props[propName] !== 'function')
  ) {
    return new Error('Icon is required for compact Tabs ');
  }
}
