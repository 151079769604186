import React from 'react';
import { SvgIcon } from '../svg-icon';

export const FileEdit = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.05 17.85C13.1 17.875 13.15 17.9 13.2 17.9L15.4 17.8C15.4414 17.8 15.4829 17.7829 15.5314 17.7628C15.6 17.7343 15.6829 17.7 15.8 17.7L16.7 16.9V19.4C16.7 20.1 16.1 20.7 15.4 20.7H5.00001C4.30001 20.7 3.70001 20.1 3.70001 19.4V4.70002C3.70001 4.00002 4.20001 3.40002 4.90001 3.40002H11.9V5.80002C11.9 7.00002 12.8 7.90002 14 7.90002H16.5V12.3L13.3 15.1C13.3 15.1 13.2 15.2 13.2 15.3L12.8 17.4C12.8 17.6 12.8 17.7 12.9 17.8C12.95 17.8 13 17.825 13.05 17.85ZM10.4 9.00002H6.40001C6.20001 9.00002 6.00001 9.20002 6.00001 9.40002C6.00001 9.60002 6.20001 9.80002 6.40001 9.80002H10.4C10.6 9.80002 10.8 9.60002 10.8 9.40002C10.8 9.20002 10.6 9.00002 10.4 9.00002ZM14.3 11.8H6.40001C6.20001 11.8 6.00001 12 6.00001 12.2C6.00001 12.4 6.20001 12.6 6.40001 12.6H14.3C14.5 12.6 14.7 12.4 14.7 12.2C14.7 12 14.5 11.8 14.3 11.8ZM6.40001 18.1H11.9C12.2 18.1 12.4 18 12.3 17.7C12.3 17.5 12.1 17.3 11.9 17.3H6.40001C6.20001 17.3 6.00001 17.5 6.00001 17.7C6.00001 18 6.20001 18.1 6.40001 18.1ZM6.40001 15.4H11.9C12.2 15.4 12.4 15.2 12.3 15C12.3 14.8 12.1 14.6 11.9 14.6H6.40001C6.20001 14.6 6.00001 14.8 6.00001 15C6.00001 15.2 6.20001 15.4 6.40001 15.4ZM14.2 7.1H16L12.9 4V5.9C12.9 6.5 13.5 7.1 14.2 7.1ZM13.8 17L15.3 16.9L20.4 12.4L19.2 11.1L14.1 15.6L13.8 17Z"
    />
  </SvgIcon>
);
