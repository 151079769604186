import { createEffect, createStore } from 'effector';

import { getFullName } from '@lib/help-fns';
import {
  fetchEmployeeList,
  fetchDepartmentList,
  fetchMeetingList,
  fetchProjectList,
} from '../api';

// Events
export const employeeListFetchRequesting = createEffect(
  'employee list fetch requested',
);
export const departmentListFetchRequesting = createEffect(
  'department list fetch requested',
);
export const meetingListFetchRequesting = createEffect(
  'meeting list fetch requested',
);
export const projectListFetchRequesting = createEffect(
  'project list fetch requested',
);

// Stores
export const $employeeList = createStore([]);
export const $departmentList = createStore([]);
export const $meetingList = createStore([]);
export const $projectList = createStore([]);

// Thunks
employeeListFetchRequesting.use(fetchEmployeeList);
departmentListFetchRequesting.use(fetchDepartmentList);
meetingListFetchRequesting.use(fetchMeetingList);
projectListFetchRequesting.use(params => fetchProjectList(params));

// Reducers
$employeeList.on(
  employeeListFetchRequesting.done.map(mapResultData),
  (_, list) => list.map(item => ({ ...item, fullName: getFullName(item) })),
);

$departmentList.on(
  departmentListFetchRequesting.done.map(mapResultData),
  (_, list) => list,
);

$meetingList.on(
  meetingListFetchRequesting.done.map(mapResultData),
  (_, list) => list,
);

$projectList.on(
  projectListFetchRequesting.done.map(mapResultData),
  (_, list) => list,
);

function mapResultData({ result }) {
  return result ? result.data : result;
}
