import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { saveAs } from 'file-saver';

import { notify } from '@lib/notifier/notify';
import { fetcher } from '@features/common';
import { Typography } from '../atoms';
import { File } from '../atoms/icons';

export const DownloadFiles = ({ files }) => {
  return (
    <Flex flexWrap="wrap">
      {files.map(({ fileName, file }) => (
        <FileWrapper
          key={Math.random()}
          onClick={() => {
            downloadFile(fileName, file);
          }}
        >
          <File color="#0076F4" />
          <Typography fontSize={14}>{labelSlice(fileName)}</Typography>
        </FileWrapper>
      ))}
    </Flex>
  );
};

const labelSlice = label => {
  const fileName = label.slice(0, label.lastIndexOf('.'));
  const fileExt = label.slice(label.lastIndexOf('.'));

  if (fileName.length <= 15) {
    return label;
  }

  const newFileName = `${fileName.slice(0, 15)}[...].${fileExt}`;
  return newFileName;
};

const downloadFile = (fileName, file) => {
  fetcher
    .get(file, { responseType: 'blob' })
    .then(response => {
      const fileBlob = new Blob([response.data], { type: 'text/plain' });

      saveAs(fileBlob, fileName);
    })
    .catch(error => {
      notify.error('Oops! Something went wrong.');
    });
};

const FileWrapper = styled(Flex)`
  /* display: flex; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #dadada;
  padding: 10px 20px;
  text-decoration: none;
  margin-right: 20px;
  margin-bottom: 20px;
  &:last-child {
    margin-right: 0;
  }
  & p {
    color: '0076F4';
    margin-top: 5px;
  }
`;

DownloadFiles.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string,
      file: PropTypes.string,
    }),
  ).isRequired,
};
