import { createStore, createEvent, sample } from 'effector';

import { createFetching } from '@lib/effector-utils';

import {
  $employeeList as $employeeRawList,
  employeeListFetchRequesting,
} from '@features/common';
// Events
export const changeEmployeeSearch = createEvent('employee search changed');
export const changeDepartmentFilter = createEvent('department filter changed');

// Stores
export const $departmentFilter = createStore('');
export const $search = createStore('');
export const $employeeFilteredList = $employeeRawList.map(list => list);
export const $employeeList = $employeeFilteredList.map(i => i);
export const $employeeListFetching = createFetching(
  employeeListFetchRequesting,
);

const onDepartmentChange = sample(
  $employeeRawList,
  changeDepartmentFilter,
  (raw, filter) => ({ raw, filter }),
);

const onSearchChange = sample(
  $employeeFilteredList,
  changeEmployeeSearch,
  (list, search) => ({
    list,
    search,
  }),
);

// Reducers
$search
  .on(changeEmployeeSearch, (_, searchStr) => searchStr)
  .reset(changeDepartmentFilter);

$departmentFilter.on(changeDepartmentFilter, (_, filter) => filter);

$employeeFilteredList.on(onDepartmentChange, (_, { raw, filter }) =>
  filter === '' ? raw : raw.filter(item => item.department.id === filter),
);

$employeeList.on(onSearchChange, (_, { list, search }) =>
  search === ''
    ? list
    : list.filter(item =>
        item.fullName.toLowerCase().includes(search.toLowerCase()),
      ),
);
