import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'rebass';

import { signUrl } from '../lib/sign-url';

export const SecuredImage = React.forwardRef(function SecuredImage(props, ref) {
  const { src: srcProp, ...rest } = props;
  const src = srcProp ? signUrl(srcProp) : '';

  return <Image {...rest} src={src} ref={ref} />;
});

SecuredImage.propTypes = {
  src: PropTypes.string,
};

SecuredImage.defaultProps = {
  src: '',
};
