import React from 'react';
import PropTypes from 'prop-types';

import styled, { keyframes } from 'styled-components';

export function Spinner({ color, size }) {
  return (
    <SpinnerElement
      style={{ width: size, height: size, fontSize: size / 6 }}
      color={color}
    />
  );
}

Spinner.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
  size: PropTypes.number,
};

Spinner.defaultProps = {
  color: 'primary',
  size: 24,
};

const load = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerElement = styled.div`
  color: ${props =>
    props.color === 'inherit' ? 'inherit' : props.theme.colors[props.color]};
  font-size: inherit;
  border-radius: 50%;
  text-indent: -9999em;
  border-top: 1em solid transparent;
  border-right: 1em solid currentColor;
  border-bottom: 1em solid currentColor;
  border-left: 1em solid currentColor;
  transform: translateZ(0);
  animation: ${load} 1.1s infinite linear;
`;
