import React from 'react';
import { SvgIcon } from '../svg-icon';

export const AddFolder = props => (
  <SvgIcon {...props} width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3336 6H4.66663C3.19981 6 2 7.19988 2 8.66669V23.3333C2 24.8001 3.19981 26 4.66663 26H27.3334C28.8002 26 30 24.8001 30 23.3333V11.6667C30 10.1999 28.8002 9 27.3334 9H16.0004L13.3336 6ZM8 15H10V18H13V20H10V23H8V20H5V18H8V15Z"
      fill="#0076F4"
    />
  </SvgIcon>
);
