import { useState } from 'react';

export function useWizard() {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [maxVisitedStepIndex, setMaxVisitedStepIndex] = useState(0);

  let stepCheckIndex = 0;

  const getStep = () => {
    const stepIndex = stepCheckIndex;

    const stepState = {
      index: stepIndex,
      isActive: activeStepIndex === stepCheckIndex,
      hasBeenActive: maxVisitedStepIndex >= stepCheckIndex,
      nextStep: () => goToStep(stepIndex + 1),
      previousStep: () => goToStep(Math.max(stepIndex - 1, 0)),
      resetToStep: () => goToStep(stepIndex, { resetMaxStepIndex: true }),
      moveToStep: () => goToStep(stepIndex),
    };
    stepCheckIndex += 1;

    return stepState;
  };

  const goToStep = (index, { resetMaxStepIndex = false } = {}) => {
    if (activeStepIndex !== index) {
      setActiveStepIndex(index);
      setMaxVisitedStepIndex(
        resetMaxStepIndex ? index : Math.max(index, maxVisitedStepIndex),
      );
    }
  };

  const goForward = () => goToStep(activeStepIndex + 1);

  const goBackward = () => goToStep(Math.max(activeStepIndex - 1, 0));

  const moveToStep = index => {
    goToStep(index);
  };

  const resetToStep = index => {
    goToStep(index, { resetMaxStepIndex: true });
  };

  return {
    activeStepIndex,
    maxVisitedStepIndex,
    goToStep,
    goForward,
    goBackward,
    getStep,
    moveToStep,
    resetToStep,
  };
}
