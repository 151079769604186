import { Link as BaseLink } from 'rebass';
import styled, { css } from 'styled-components';

export const Link = styled(BaseLink)`
  cursor: pointer;
  text-decoration: none;
  line-height: 1;
  &:hover {
    text-decoration: underline;
  }
  ${props =>
    props.as === 'button' &&
    css({
      position: 'relative',
      // Remove grey highlight
      WebkitTapHighlightColor: 'transparent',
      backgroundColor: 'transparent', // Reset default value
      // We disable the focus ring for mouse, touch and keyboard users.
      outline: 'none',
      border: 0,
      margin: 0, // Remove the margin in Safari
      borderRadius: 0,
      padding: 0, // Remove the padding in Firefox
      userSelect: 'none',
      verticalAlign: 'middle',
      '-moz-appearance': 'none', // Reset
      '-webkit-appearance': 'none', // Reset
      '&::-moz-focus-inner': {
        borderStyle: 'none', // Remove Firefox dotted outline.
      },
    })}
`;

Link.propTypes = {
  ...BaseLink.propTypes,
};

Link.defaultProps = {
  as: 'a',
  color: 'primary',
  fontSize: 1,
};
