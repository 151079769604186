/**
 * Generates device id
 * @returns {string} Unique browser id
 */
export function getDeviceId() {
  const { navigator, screen } = window || {};
  let guid = navigator.mimeTypes.length;
  guid += navigator.userAgent.replace(/\D+/g, '');
  guid += navigator.plugins.length;
  guid += screen.height || '';
  guid += screen.width || '';
  guid += screen.pixelDepth || '';

  return guid;
}
