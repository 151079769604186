import React from 'react';
import { toast } from 'react-toastify';
import './notify.css';
import styled from 'styled-components';
import { LightClose, Check, Info, Error } from '../../ui/atoms/icons';
import { IconButton } from '../../ui/atoms/icon-button';

const SUCCESS = 'success';
const ERROR = 'error';
const INFO = 'info';
const WARN = 'warn';

const CenteredFlexContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
  }
`;

const generateToastContent = (type, children) => {
  const Icons = {
    [SUCCESS]: Check,
    [WARN]: Error,
    [ERROR]: Error,
    [INFO]: Info,
  };
  const Icon = Icons[type];

  return (
    <CenteredFlexContainer>
      <CenteredFlexContainer>
        <Icon />
      </CenteredFlexContainer>
      {children}
    </CenteredFlexContainer>
  );
};

const generateDefaultOptions = type => {
  return {
    hideProgressBar: true,
    closeButton: (
      <IconButton color="#424B68" size="small">
        <LightClose />
      </IconButton>
    ),
  };
};

const generateNotifier = (type, children, options) => {
  const defaultOptions = generateDefaultOptions(type);
  const toastContent = generateToastContent(type, children);

  return toast[type](toastContent, {
    ...defaultOptions,
    ...options,
  });
};

export const notify = {
  [SUCCESS]: (children, options = {}) => {
    generateNotifier(SUCCESS, children, options);
  },
  [ERROR]: (children, options) => generateNotifier(ERROR, children, options),
  [WARN]: (children, options) => generateNotifier(WARN, children, options),
  [INFO]: (children, options) => generateNotifier(INFO, children, options),
  POSITION: {
    TOP_LEFT: toast.POSITION.TOP_LEFT,
    TOP_RIGHT: toast.POSITION.TOP_RIGHT,
    TOP_CENTER: toast.POSITION.TOP_CENTER,
    BOTTOM_LEFT: toast.POSITION.BOTTOM_LEFT,
    BOTTOM_RIGHT: toast.POSITION.BOTTOM_RIGHT,
    BOTTOM_CENTER: toast.POSITION.BOTTOM_CENTER,
  },
};
